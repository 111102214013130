import { Coach } from '@/models/Coach';
import API from '@/api/base';

interface FetchCoachesParams {
  organisationId: string;
  name?: string;
  page?: number;
  pageSize?: number;
}

export const fetchCoachesRequest = async ({
  organisationId,
  name,
  page = 1,
  pageSize = 100,
}: FetchCoachesParams): Promise<PaginatedResponse<Coach>> => {
  let url = `/coaches/?organisation=${organisationId}`;

  if (name) {
    url += `&name=${name}`;
  }

  if (page) {
    url += `&page=${page}`;
  }

  if (pageSize) {
    url += `&page_size=${pageSize}`;
  }

  const { data } = await API.get<PaginatedResponse<Coach>>(url);

  return data;
};
