import API from '@/api/base';

interface MarkMembershipPaymentRequestBody {
  memberId: string;
  month: string;
  year: string;
  amount: number;
}

export const markMembershipPaymentRequest = async ({
  memberId,
  month,
  year,
  amount,
}: MarkMembershipPaymentRequestBody): Promise<Record<string, string>> => {
  const requestBody = { year, month, amount };

  const response = await API.post(`/memberships/mark-payment/${memberId}/`, requestBody);
  return response.data;
};
