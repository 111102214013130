import { Button, PasswordInput, Stack, Title } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateMePassword } from '@/api/hooks/auth/useUpdateMePassword';
import notifications from '@/utils/notifications';

interface UserPasswordInputs {
  current_password: string;
  new_password: string;
}
export default function UserUpdatePasswordForm() {
  const { mutateAsync: updatePassword } = useUpdateMePassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UserPasswordInputs>();

  const updateUserPassword: SubmitHandler<UserPasswordInputs> = async (data) => {
    try {
      await updatePassword(data);

      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili lozinku',
        message: 'Čestitamo, uspešno ste izmenili lozinku',
      });

      reset(data);
    } catch (error) {
      console.error('Oops, something went wrong', error);
    }
  };
  return (
    <Stack
      mt={20}
      p="xl"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
      }}
      component="form"
      onSubmit={handleSubmit(updateUserPassword)}
    >
      <Title size={20}>Privatnost</Title>
      <PasswordInput
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        label="Trenutna lozinka"
        placeholder="*******"
        error={errors.current_password?.message}
        {...register('current_password')}
      />
      <PasswordInput
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        label="Nova lozinka"
        placeholder="*******"
        error={errors.new_password?.message}
        {...register('new_password')}
      />
      <Button
        type="submit"
        name="userPassword"
        w={{ base: '100%', xs: '40%', sm: '15%' }}
        my={10}
        disabled={!isDirty}
      >
        Promeni lozinku
      </Button>
    </Stack>
  );
}
