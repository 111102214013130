import API from '@/api/base';
import { Attendance } from '@/models/Training';

export interface MemberTrainingsAttendanceHistory extends PaginationQueryParams {
  memberId: string;
  period: string; // YYYY-MM format
}

export const fetchMemberTrainingsAttendance = async ({
  memberId,
  period,
  page,
  pageSize,
}: MemberTrainingsAttendanceHistory): Promise<PaginatedResponse<Attendance>> => {
  let url = `/trainings/attendance/history/${memberId}/?period=${period}`;

  if (page) {
    url += `&page=${page}`;
  }

  if (pageSize) {
    url += `&page_size=${pageSize}`;
  }

  const { data } = await API.get<PaginatedResponse<Attendance>>(url);

  return data;
};
