import API from '@/api/base';

interface DeleteOrganisationLocationRequestBody {
  organisationId: string;
  locationId: string;
}

export const deleteOrganisationLocationRequest = async ({
  locationId,
  organisationId,
}: DeleteOrganisationLocationRequestBody) => {
  const response = await API.delete(`organisations/${organisationId}/locations/${locationId}`);

  return response.data;
};
