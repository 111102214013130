import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useAccountStore } from '@/stores/accountStore';

export default function SubscriptionBanner() {
  const { organisation } = useAccountStore();

  const endDay = organisation?.license?.end_date;
  const today = dayjs();
  const endDayLicense = dayjs(endDay);
  const daysToExpire = endDayLicense.diff(today, 'day');

  let bannerTitle: string = '';
  let bannerColor: 'yellow' | 'red' = 'red';

  if (daysToExpire === 0) {
    bannerTitle = 'Licenca ističe danas. Produžite je kako biste nastavili s korišćenjem!';
    bannerColor = 'red';
  } else if (daysToExpire >= 1 && daysToExpire <= 7) {
    bannerTitle = `Licenca ističe za ${Math.abs(daysToExpire)} dana. Produžite je kako biste nastavili s korišćenjem!`;
    bannerColor = 'yellow';
  } else if (daysToExpire < 0) {
    const daysExpired = Math.abs(daysToExpire);
    bannerTitle = `Vaša licenca je istekla pre ${Math.abs(daysExpired)} dana. Obnovite je kako biste izbegli prekid usluge!`;
    bannerColor = 'red';
  }

  const icon = <IconInfoCircle />;

  return (
    <>
      {bannerTitle && (
        <Alert icon={icon} color={bannerColor} radius="xs" style={{ textAlign: 'center' }}>
          {bannerTitle}
        </Alert>
      )}
    </>
  );
}
