import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editMemberAdditionalOptions } from '@/api/requests/members/editMemberAdditionalOptions.request';

export const useEditMemberAdditionalOptions = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editMemberAdditionalOptions,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
