import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const SENTRY_FEEDBACK_CONFIG = {
  autoInject: false,
  colorScheme: 'light',
  showBranding: false,
  isEmailRequired: true,
  enableScreenshot: false,
  triggerLabel: 'Prijavite grešku',
  cancelButtonLabel: 'Odustani',
  submitButtonLabel: 'Prijavi problem',
  formTitle: 'Prijavite grešku',
  emailLabel: 'Email adresa',
  emailPlaceholder: 'Vaša email adresa',
  messageLabel: 'Opis',
  messagePlaceholder: 'Šta je greška? Šta ste očekivali?',
  nameLabel: 'Ime',
  namePlaceholder: 'Vaše ime',
  successMessageText: 'Hvala Vam na poslatom izveštaju',
  isRequiredLabel: '(obavezno)',
  submitBackground: '#FFFFFF',
};

Sentry.init({
  environment: 'production',
  dsn: 'https://067c21a6216a0a06b22d32f5c1240d91@o4507543022141440.ingest.de.sentry.io/4507543023845456',
  integrations: [
    Sentry.feedbackIntegration(SENTRY_FEEDBACK_CONFIG),

    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllInputs: true,
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing
  tracesSampleRate: 1.0,

  tracePropagationTargets: ['https://app.datasport.app'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
