import dayjs from 'dayjs';
import { Card, SimpleGrid, Text } from '@mantine/core';
import { Training } from '@/models/Training';

export default function TrainingBasicInfo({ training }: { training: Training }) {
  const basicInfo: { label: string; value: string }[] = [
    { label: 'Grupa', value: training.group.name },
    {
      label: 'Trener',
      value: training.coaches.map((el) => el.full_name).join(', '),
    },
    { label: 'Datum i vreme treninga', value: dayjs(training.date).format('DD/MM/YYYY HH:mm') },
    { label: 'Tema', value: training.topic || 'Nije navedeno' },
    { label: 'Lokacija', value: training.location?.name || 'Nije navedeno' },
  ];

  return (
    <SimpleGrid
      mt={50}
      cols={{
        base: 1,
        sm: 1,
        md: 2,
        lg: 4,
        xl: 5,
      }}
      spacing="md"
    >
      {basicInfo.map((item) => (
        <Card key={item.label} p="md">
          <Text ff="heading" fw={600} c="gray.9">
            {item.label}
          </Text>
          <Text size="sm" c="gray.8" ff="monospace" mt={10}>
            {item.value}
          </Text>
        </Card>
      ))}
    </SimpleGrid>
  );
}
