import { Anchor, Table } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Member } from '@/models/Member';

export function UnpaidMembershipsTableItem({ member }: { member: Partial<Member> }) {
  const navigate = useNavigate();

  return (
    <Table.Tr key={member.id}>
      <Table.Td
        style={{ cursor: 'pointer', width: '100%' }}
        onClick={() => navigate(`/members/${member.id}`)}
      >
        <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
          {member.full_name}
        </Anchor>
      </Table.Td>
    </Table.Tr>
  );
}
