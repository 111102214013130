import { Button, Divider, Flex, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { useDeleteGroup } from '@/api/hooks/groups/useDeleteGroup';
import notifications from '@/utils/notifications';

const DeleteGroup = ({ groupId }: { groupId: string }) => {
  const { mutateAsync: deleteGroup } = useDeleteGroup();

  const handleDeleteGroup = async () => {
    try {
      const response = await deleteGroup({ groupId });

      if (response.status === 204) {
        notifications.showSuccessNotification({
          title: 'Uspesno',
          message: 'Uspesno ste izbrisali grupu',
        });
        modals.closeAll();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack px="md">
      <Divider />
      <Text>Da li ste sigurni da zelite da izbrisete grupu ?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button leftSection={<IconTrash size={18} />} color="red" onClick={handleDeleteGroup}>
          Izbrisi
        </Button>
      </Flex>
    </Stack>
  );
};

export default DeleteGroup;
