import { Box, Card, Divider, Drawer, Flex, ScrollArea } from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { useAccountStore } from '@/stores/accountStore';
import { useGroups } from '@/api/hooks/groups/useGroups';
import SearchTextField from '@/components/Common/SearchTextField';
import GroupDetails from './GroupDetails';
import { Group } from '@/models/Group';
import GroupsMobileTable from '@/components/Groups/GroupsMobileTable';
import GroupsPopoverDataFilter from '@/components/Groups/GroupsPopoverDataFilter';
import useIsMobile from '@/hooks/useIsMobile';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import CustomTable from '@/components/Common/Table/CustomTable';
import GroupsTableItem from '@/components/Groups/GroupsTableItem';
import { COLUMNS } from '@/constants';

const GroupsContainer = () => {
  const isMobile = useIsMobile();
  const { organisation } = useAccountStore();
  const [search, setSearch] = useState<string>('');
  const [coachFilter, setCoachFilter] = useState<string>('');
  const [debouncedSearch] = useDebouncedValue(search, 500);

  const [drawerOpened, { open, close }] = useDisclosure(false);
  const [groupDetails, setGroupDetails] = useState<Group>();

  const {
    data: groups,
    isLoading,
    error,
  } = useGroups({
    organisationId: organisation?.id as string,
    name: debouncedSearch,
    coachId: coachFilter,
  });

  function handleNameClick(group: Group) {
    setGroupDetails(group);
    open();
  }

  if (error) return <div>Something went wrong..</div>;

  return (
    <Box>
      <Flex gap={10}>
        <SearchTextField
          placeholder="Pretraži grupe.."
          value={search}
          onClear={() => setSearch('')}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
        <GroupsPopoverDataFilter coachFilter={coachFilter} setCoachFilter={setCoachFilter} />
      </Flex>

      <Card mt={20} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
        {isLoading ? (
          <TableSkeletonLoading />
        ) : (
          groups &&
          (!isMobile ? (
            <CustomTable
              data={groups.results}
              columns={COLUMNS.GroupsColumns}
              renderRow={(group) => (
                <GroupsTableItem
                  group={group}
                  onNameClick={() => handleNameClick(group)}
                  key={group.id}
                />
              )}
            />
          ) : (
            <GroupsMobileTable
              data={groups.results}
              onRowClick={open}
              setGroupDetails={setGroupDetails}
            />
          ))
        )}
      </Card>

      <Drawer
        size="xl"
        opened={drawerOpened}
        onClose={close}
        title="Detalji grupe"
        position="right"
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Divider />
        <GroupDetails group={groupDetails as Group} onCancel={close} />
      </Drawer>
    </Box>
  );
};

export default GroupsContainer;
