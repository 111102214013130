import { Card, SimpleGrid, Text } from '@mantine/core';
import { useParentChildDetails } from '@/api/hooks/parents/useParentChildDetails';
import { displayMemberGroups } from '@/models/Member';

interface ParentChild {
  id: string;
  full_name: string;
  currency: string;
  organisation: string;
  join_date: string;
}

export default function ChildBaseInformation({
  parent,
  child,
}: {
  parent: string;
  child?: ParentChild;
}) {
  const { data: member } = useParentChildDetails({ parent, child: child?.id as string });

  function getMemberPreviousDebt(debt: string) {
    return Number(debt) > 0 ? `${debt} ${child?.currency}` : '-';
  }

  const basicInfo: { label: string; value: string }[] = [
    {
      label: 'Grupe sa kojim trenira',
      value: (member && displayMemberGroups(member?.groups)) || 'Nema dodeljenu grupu',
    },
    {
      label: 'Cena članarine',
      value: `${member?.membership_price} ${child?.currency}` || 'Nije navedeno',
    },
    {
      label: 'Prethodna dugovanja',
      value: getMemberPreviousDebt(member?.previous_debt as string),
    },
    { label: 'Lekarsko uverenje', value: member?.has_medical_certificate ? 'Ima' : 'Nema' },
  ];

  return (
    <div>
      {member && (
        <SimpleGrid
          mt={50}
          cols={{
            base: 1,
            sm: 1,
            md: 2,
            lg: 4,
            xl: 5,
          }}
          spacing="md"
        >
          {basicInfo.map((item) => (
            <ChildInfoItem key={item.label} item={item} />
          ))}
        </SimpleGrid>
      )}
    </div>
  );
}

function ChildInfoItem({ item }: { item: { label: string; value: string } }) {
  return (
    <Card p="md">
      <Text ff="heading" fw={600} c="gray.9">
        {item.label}
      </Text>
      <Text size="sm" c="gray.8" ff="monospace" mt={10}>
        {item.value}
      </Text>
    </Card>
  );
}
