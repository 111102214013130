import { Button, Flex, LoadingOverlay, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import notifications from '@/utils/notifications';
import { useRemoveMemberFromGroup } from '@/api/hooks/groups/useRemoveMemberFromGroup';

export default function RemoveMembersFromGroup({
  memberIds,
  groupId,
}: {
  memberIds: string[];
  groupId: string;
}) {
  const { mutateAsync: removeMemberFromGroup, isPending } = useRemoveMemberFromGroup();

  const handleRemoveMember = async () => {
    try {
      await Promise.all(memberIds.map((memberId) => removeMemberFromGroup({ memberId, groupId })));

      notifications.showSuccessNotification({
        title: 'Uspešno',
        message: 'Uspešno ste uklonili polaznika/e iz grupe',
      });
      modals.closeAll();
    } catch (error) {
      console.error(error);
      notifications.showErrorNotification({
        title: 'Greška',
        message: 'Nije moguće ukloniti neke polaznike.',
      });
    }
  };

  return (
    <Stack px="md" pos="relative">
      <LoadingOverlay visible={isPending} />
      <Text>Da li ste sigurni da želite da uklonite polaznika/e iz grupe?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button leftSection={<IconTrash size={18} />} color="red" onClick={handleRemoveMember}>
          Ukloni
        </Button>
      </Flex>
    </Stack>
  );
}
