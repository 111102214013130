import { useMutation } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { updateOrganisation } from '@/api/requests/organisations/updateOrganisation';

export const useUpdateOrganisation = () => {
  const { setOrganisation } = useAccountStore();

  return useMutation({
    mutationFn: updateOrganisation,
    onSuccess: async (data) => {
      setOrganisation(data);
    },
    onError: (error) => {
      console.error('Something went wrong', error);
    },
    retry: 1,
  });
};
