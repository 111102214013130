import { Anchor, Table, ThemeIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';
import { Attendance } from '@/models/Training';
import useIsMobile from '@/hooks/useIsMobile';

export default function TrainingAttendanceTableItem({ attendance }: { attendance: Attendance }) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { isGranted } = useAuthorization();

  function handleMainColumClick(memberId: string) {
    return isGranted(Permission.MEMBER_DETAILS_VIEW) && navigate(`/members/${memberId}`);
  }

  return (
    <Table.Tr key={attendance.id}>
      <Table.Td onClick={() => handleMainColumClick(attendance.member.id)}>
        <Anchor
          c="gray.9"
          fw={500}
          size="sm"
          underline={isGranted(Permission.MEMBER_DETAILS_VIEW) ? 'hover' : 'never'}
          ff="heading"
        >
          {attendance.member.full_name}
        </Anchor>
      </Table.Td>

      <Table.Td ta={isMobile ? 'right' : 'left'}>
        <AttendanceStatus isPresent={attendance.is_present} />
      </Table.Td>

      <Table.Td />
    </Table.Tr>
  );
}

const AttendanceStatus = ({ isPresent }: { isPresent: boolean }) =>
  isPresent ? (
    <ThemeIcon radius="xl" size="sm" color="teal">
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  ) : (
    <ThemeIcon radius="xl" size="sm" color="red">
      <IconX style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  );
