import { IconLogout } from '@tabler/icons-react';
import { Avatar, Flex, Group, Stack, Text, Tooltip, UnstyledButton } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { getUserFullName, getUserInitials, UserRole } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';

export default function LoggedUserInfo() {
  const { user, forget } = useAccountStore();
  const navigate = useNavigate();

  function displayUserRole(role: UserRole) {
    let roleText = '';

    switch (role) {
      case UserRole.OWNER:
        roleText = 'Menadzer';
        break;

      case UserRole.COACH:
        roleText = 'Trener';
        break;

      case UserRole.PARENT:
        roleText = 'Roditelj';
        break;
    }

    return roleText;
  }

  function onLogout() {
    navigate('/login', { replace: true });
    forget();
    notifications.showSuccessNotification({
      title: 'Vidimo se kasnije!',
      message: 'Dobrodošli nazad u bilo koje vreme',
    });
  }
  return (
    <div>
      <Flex justify="space-between" align="center">
        <Group align="center">
          <Avatar size="md">{user && getUserInitials(user)}</Avatar>
          <Stack>
            <Text fz="sm" fw={600}>
              {user && getUserFullName(user)}
            </Text>
            <Text fz="xs" fw={500} mt={-15}>
              {displayUserRole(user?.role as UserRole)}
            </Text>
          </Stack>
        </Group>
        <Tooltip multiline withArrow label="Odjavi se">
          <UnstyledButton onClick={onLogout}>
            <IconLogout size={25} />
          </UnstyledButton>
        </Tooltip>
      </Flex>
    </div>
  );
}
