import { useQuery } from '@tanstack/react-query';

import {
  MemberMembershipsRequest,
  fetchMemberMembershipsRequest,
} from '@/api/requests/members/getMemberMemberships.request';
import { Constants } from '@/constants';

export const useMemberMemberships = ({
  memberId,
  page,
  pageSize = Constants.PAGE_SIZE,
}: MemberMembershipsRequest) =>
  useQuery({
    queryKey: ['memberships', 'member', { memberId, page, pageSize }],
    queryFn: () => fetchMemberMembershipsRequest({ memberId, page, pageSize }),
  });
