import { Card, Grid, Skeleton } from '@mantine/core';

export default function DashboardLoading() {
  return (
    <div>
      <Grid>
        {/* Statistics Cards */}
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid.Col span={3} key={index}>
            <Card padding="lg">
              <Skeleton height={20} width="70%" />
              <Skeleton height={40} width="90%" mt={6} />
            </Card>
          </Grid.Col>
        ))}
      </Grid>

      <Grid>
        {/* Line Chart and Pie Chart */}
        <Grid.Col span={8}>
          <Card padding="lg" style={{ marginTop: 20 }}>
            <Skeleton height={250} />
          </Card>
        </Grid.Col>
        <Grid.Col span={4}>
          <Card padding="lg" style={{ marginTop: 20 }}>
            <Skeleton height={250} />
          </Card>
        </Grid.Col>
      </Grid>

      {/* Training History Table */}
      <Card padding="lg" style={{ marginTop: 20 }}>
        <Skeleton height={20} width="30%" />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
        <Skeleton height={30} width="100%" mt={10} />
      </Card>
    </div>
  );
}
