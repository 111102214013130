import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editMember } from '@/api/requests/members/editMember.request';

export const useEditMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editMember,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
