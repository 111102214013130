import { Box, Button, Flex, Title, Text, Stack } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';

import { useParams } from 'react-router-dom';
import useIsMobile from '@/hooks/useIsMobile';
import GroupAttendanceBarChart from '@/components/Groups/Details/GroupAttendanceBarChart';
import { useGroupDetails } from '@/api/hooks/groups/useGroupDetails';
import PageLoading from '@/components/Common/PageLoading';
import GroupDetails from '@/containers/Groups/Details/GroupDetails';

export function GroupDetailsPage() {
  const { id } = useParams<{ id?: string }>();
  const isMobile = useIsMobile();

  const { data: groupInfo, isLoading: isGroupInfoLoading } = useGroupDetails(id as string);

  if (isGroupInfoLoading) return <PageLoading />;

  return (
    <>
      <Box w="100%" bg="teal.7" pt={isMobile ? 40 : 0}>
        <Box py={50} px={40}>
          <Flex justify="space-between" align="center">
            <Stack gap={5}>
              <Title fz={24} c="white">
                {groupInfo?.name}
              </Title>
              <Text ff="monospace" fz="xs" c="green.1">
                Broj polaznika: {groupInfo?.members.length}
              </Text>
            </Stack>

            <Button
              leftSection={<IconEdit size={20} />}
              variant="outline"
              c="white"
              style={{ border: '1px solid white' }}
            >
              Izmeni grupu
            </Button>
          </Flex>

          {!isMobile ? (
            <Box mt={30}>
              <GroupAttendanceBarChart />
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box p={40}>
        <GroupDetails />
      </Box>
    </>
  );
}
