import { DonutChart } from '@mantine/charts';

interface PieChartProps {
  data: {
    name: string;
    total_sessions: number;
  }[];
}

export default function TrainingsByCoachChart({ data }: PieChartProps) {
  return (
    <DonutChart
      size={150}
      withLabelsLine={false}
      withLabels
      withTooltip
      tooltipDataSource="segment"
      strokeWidth={1}
      data={data.map((el, index) => ({
        name: el.name,
        value: el.total_sessions,
        color: PIE_CHART_COLORS[index],
      }))}
    />
  );
}

const PIE_CHART_COLORS = [
  '#A1D99B', // Light Green
  '#36A2EB', // Soft Blue
  '#FFCE56', // Soft Yellow
  '#4BC0C0', // Soft Teal
  '#9966FF', // Soft Purple
  '#FF9F40', // Soft Orange
  '#FFCD94', // Light Peach
  '#9ECAE1', // Light Blue
  '#FDD49E', // Light Orange
  '#BCBDDC', // Light Lavender
  '#F2F2F2', // Light Gray
  '#8C564B', // Brown
  '#E377C2', // Soft Pink
  '#7F7F7F', // Gray
  '#B5EAD7', // Mint Green
  '#FFDAC1', // Peach
  '#FFABAB', // Coral
  '#FF6384', // Soft Red
  '#D4A5A5', // Dusty Rose
  '#5D6D7E', // Steel Blue
];
