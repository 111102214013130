import { Button, Flex, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import notifications from '@/utils/notifications';
import { useDeleteTraining } from '@/api/hooks/trainings/useDeleteTraining';

const DeleteTraining = ({ trainingId }: { trainingId: string }) => {
  const { mutateAsync: deleteTraining } = useDeleteTraining();
  const location = useLocation();
  const navigate = useNavigate();

  const deletingFromTrainingDetailsPage = location.pathname.includes(trainingId);

  const handleDeleteTraining = async () => {
    try {
      const response = await deleteTraining(trainingId);

      if (response.status === 204) {
        notifications.showSuccessNotification({
          title: 'Uspešno',
          message: 'Uspešno ste izbrisali trening',
        });
        modals.closeAll();

        if (deletingFromTrainingDetailsPage) navigate('/trainings', { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack px="md">
      <Text>Da li ste sigurni da želite da izbrišete trening ?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button leftSection={<IconTrash size={18} />} color="red" onClick={handleDeleteTraining}>
          Izbriši
        </Button>
      </Flex>
    </Stack>
  );
};

export default DeleteTraining;
