import { Paper, Text, rem, Flex, Stack, Center, Skeleton } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import TrainingsByCoachChart from './Charts/TrainingsByCoachChart';
import { useAccountStore } from '@/stores/accountStore';
import { useTrainingsStatsByCoaches } from '@/api/hooks/trainings/useTrainingsStatsByCoaches';

const calendarIcon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

export default function TrainingsCountAnalytics() {
  const { organisation } = useAccountStore();
  const [month, setMonth] = useState<Date | null>(new Date());

  const { data: trainingsStats, isLoading } = useTrainingsStatsByCoaches(
    dayjs(month).format('YYYY-MM-DD')
  );

  function renderContent() {
    if (isLoading) return <Skeleton height={150} circle mb="xl" />;

    if (trainingsStats?.stats_by_coach.length === 0) {
      return (
        <Center h="100%">
          <Text fz="sm" mt={{ base: 0, sm: 70 }}>
            Nema treninga za odabrani mesec.
          </Text>
        </Center>
      );
    }

    if (trainingsStats!.stats_by_coach.length > 0) {
      return <TrainingsByCoachChart data={trainingsStats!.stats_by_coach} />;
    }

    return null;
  }

  return (
    <Paper p="md">
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        align={{ base: 'center', sm: 'flex-start' }}
        justify="space-between"
        pr={{ base: 0, sm: 50 }}
      >
        <Stack justify="apart">
          <div>
            <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
              Analitika treninga
            </Text>
            <Text c="dimmed" fz="sm" mt="md">
              Izaberite mesec i godinu da vidite statistiku <br />
              koliko treninga je imao svaki trener za <br />
              odabrani mesec
            </Text>
          </div>

          <MonthPickerInput
            maxDate={new Date()}
            minDate={organisation?.created_at}
            leftSection={calendarIcon}
            leftSectionPointerEvents="none"
            mt="md"
            popoverProps={{ withinPortal: false }}
            value={month}
            onChange={(value) => setMonth(value)}
          />
        </Stack>

        {renderContent()}
      </Flex>
    </Paper>
  );
}
