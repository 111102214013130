import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { deleteGroupRequest } from '@/api/requests/groups/deleteGroup.request';

export const useDeleteGroup = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteGroupRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', { organisationId: organisation?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: ['members', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
