import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import notifications from '@/utils/notifications';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 10, // 10 min
      staleTime: 1000 * 60 * 5, // 5 min
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
    mutations: {
      onError: (error) => {
        console.error('Mutation error:', error);
        notifications.showErrorNotification({
          title: 'Došlo je do greške',
          message: 'Molimo vas pokušajte kasnije..',
        });
      },
    },
  },
});

export type QueryOptionsParams<T> = Omit<UseQueryOptions<T, Error>, 'queryKey' | 'queryFn'>;

export default queryClient;
