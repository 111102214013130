import API from '@/api/base';

interface FetchTrainingStatsByCoachesRespone {
  period: string;
  stats_by_coach: {
    name: string;
    total_sessions: number;
  }[];
}

export const getTrainingStatsByCoaches = async (
  date?: string
): Promise<FetchTrainingStatsByCoachesRespone> => {
  const { data } = await API.get<FetchTrainingStatsByCoachesRespone>(
    `/trainings/stats/by-coaches/?date=${date}`
  );

  return data;
};
