import { useState } from 'react';
import { AppShell, ScrollArea, Stack, Title, Text, Image, UnstyledButton } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from '@/stores/accountStore';
import routes, { Route } from './sidebarMenuLinks';
import logo from '@/assets/images/full-logo-dark.png';
import LoggedUserInfo from './LoggedUserInfo';
import { SidebarLinks } from './SidebarLinks';
import { useAuthorization } from '@/hooks/useAuthorization';

const Sidebar = ({ toggle }: { toggle: () => void }) => {
  const navigate = useNavigate();
  const { isGranted } = useAuthorization();
  const { organisation, user } = useAccountStore();
  const [openedGroup, setOpenedGroup] = useState<string | null>(null);

  const filteredRoutes = (user && routes.filter((route) => isGranted(route.permission))) as Route[];

  const links = filteredRoutes?.map((item) => (
    <SidebarLinks
      {...item}
      toggle={toggle}
      isOpen={openedGroup === item.label}
      onToggle={() => handleToggle(item.label)}
      key={item.label}
    />
  ));

  function handleToggle(label: string) {
    setOpenedGroup((prev) => (prev === label ? null : label));
  }

  return (
    <>
      <Stack mb={30} pt={30} px={15}>
        <UnstyledButton onClick={() => navigate('/')}>
          <Image src={logo} w={110} />
        </UnstyledButton>

        <Text mt={10} fz={16} fw={600} c="gray.9">
          {organisation?.name}
        </Text>
        <Title c="black" order={5} />
      </Stack>

      {links}

      <AppShell.Section grow component={ScrollArea} />

      <AppShell.Section pb={40} px={10} visibleFrom="sm">
        <LoggedUserInfo />
      </AppShell.Section>
    </>
  );
};

export default Sidebar;
