import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createParentAccount } from '@/api/requests/parents/createParentAccount.request';

export const useCreateParentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createParentAccount,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      });
    },

    onError: (error) => {
      console.error('Error while creating a new parent account', error);
    },
  });
};
