import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { updateMe } from '@/api/requests/auth/updateMe';

export const useUpdateMe = () => {
  const { setUser } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMe,
    onSuccess: async (data) => {
      setUser(data);
      queryClient.invalidateQueries({
        queryKey: ['me'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong', error);
    },
    retry: 1,
  });
};
