import { Paper, ThemeIcon, Text, Flex, Box } from '@mantine/core';
import classes from './DashboardActionCardButton.module.css';

interface Props {
  icon: React.ReactNode;
  title: string;
  description: string;
  ctaButton: React.ReactNode;
}

export default function DashboardActionCardButton({ icon, title, description, ctaButton }: Props) {
  return (
    <Paper w={350} h={250} className={classes.card} mt={20}>
      <Flex direction="column" align="center" mt="md">
        <ThemeIcon className={classes.icon} size={60} radius={60}>
          {icon}
        </ThemeIcon>

        <Text ta="center" fw={700} className={classes.title}>
          {title}
        </Text>
        <Text c="dimmed" ta="center" fz="sm" mt={10}>
          {description}
        </Text>

        <Box mt={30}>{ctaButton}</Box>
      </Flex>
    </Paper>
  );
}
