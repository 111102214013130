import '@mantine/core/styles.css';
import './global.module.css';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { Router } from './Router';
import { theme } from './theme';
import { useInitializeAccountStore } from '@/hooks/useInitializeAccountStore';
import AppLoading from './components/Common/AppLoading/AppLoading';
import queryClient from './api/queryClient';
import SentryFeedbackWidget from './components/Layout/SentryFeedbackWidget';
import 'dayjs/locale/sr';

dayjs.locale('sr');

export default function App() {
  const initializing = useInitializeAccountStore();

  return (
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <DatesProvider settings={{ locale: 'sr' }}>
          <QueryClientProvider client={queryClient}>
            <Notifications position="top-center" />
            <ModalsProvider>
              {!initializing ? <Router /> : <AppLoading />}
              <SentryFeedbackWidget />
              <ReactQueryDevtools initialIsOpen={false} />
            </ModalsProvider>
          </QueryClientProvider>
        </DatesProvider>
      </BrowserRouter>
    </MantineProvider>
  );
}
