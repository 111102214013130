import API from '@/api/base';
import { Group } from '@/models/Group';

interface CreateGroupRequestBody {
  name: string;
}

export const createGroupRequest = async ({ name }: CreateGroupRequestBody): Promise<Group> => {
  const { data } = await API.post<Group>('/groups/', {
    name,
  });

  return data;
};
