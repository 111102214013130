import { Box, Loader } from '@mantine/core';

interface TableLoadingProps {
  width?: string;
  height?: string;
}
const TableLoading = ({ width = '100%', height = '60vh' }: TableLoadingProps) => (
  <Box
    w={width}
    h={height}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loader color="teal" size="xl" />
  </Box>
);

export default TableLoading;
