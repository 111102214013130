import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrganisationLocation } from '@/api/requests/organisations/updateOrganisationLocations';

export const useUpdateOrganisationLocations = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOrganisationLocation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['organisation'],
      }),
    onError: (error) => {
      console.error('There is error while editing location', error);
    },
    retry: 1,
  });
};
