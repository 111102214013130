import { Flex, Loader } from '@mantine/core';
import React from 'react';

export default function PageLoading() {
  return (
    <div>
      <Flex style={{ height: '100vh' }} align="center" justify="center">
        <Loader color="teal" size="xl" />
      </Flex>
    </div>
  );
}
