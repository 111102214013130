import { BarChart } from '@mantine/charts';
import { Box, Text } from '@mantine/core';
import React from 'react';

export default function GroupAttendanceBarChart() {
  return (
    <Box>
      <Text c="white" fz="sm" mb={30}>
        Prisutnost na treninzima poslednjih mesec dana
      </Text>
      <BarChart
        h={140}
        data={data}
        dataKey="date"
        series={[{ name: 'attendances', color: 'teal.4', label: 'Prisutno polaznika' }]}
        gridAxis="none"
        textColor="white"
        xAxisProps={{ padding: { left: 30, right: 30 } }}
      />
    </Box>
  );
}

export const data = [
  { date: '10/06/2024 - 19:34', attendances: 30 },
  { date: '11/06/2024 - 07:43', attendances: 17 },
  { date: '12/06/2024 - 14:28', attendances: 10 },
  { date: '13/06/2024 - 07:41', attendances: 18 },
  { date: '14/06/2024 - 12:51', attendances: 15 },
  { date: '15/06/2024 - 10:47', attendances: 29 },
  { date: '16/06/2024 - 09:40', attendances: 10 },
  { date: '17/06/2024 - 16:29', attendances: 19 },
  { date: '18/06/2024 - 19:05', attendances: 26 },
  { date: '19/06/2024 - 13:25', attendances: 19 },
  { date: '20/06/2024 - 11:29', attendances: 24 },
  { date: '21/06/2024 - 18:44', attendances: 14 },
  { date: '22/06/2024 - 13:57', attendances: 30 },
  { date: '23/06/2024 - 17:15', attendances: 17 },
  { date: '24/06/2024 - 11:27', attendances: 14 },
  { date: '25/06/2024 - 08:10', attendances: 16 },
  { date: '26/06/2024 - 10:27', attendances: 24 },
  { date: '27/06/2024 - 20:46', attendances: 24 },
  { date: '28/06/2024 - 13:27', attendances: 24 },
  { date: '29/06/2024 - 13:39', attendances: 18 },
  { date: '30/06/2024 - 17:00', attendances: 26 },
  { date: '01/07/2024 - 06:12', attendances: 21 },
  { date: '02/07/2024 - 11:03', attendances: 25 },
  { date: '03/07/2024 - 12:15', attendances: 10 },
  { date: '04/07/2024 - 19:00', attendances: 11 },
  { date: '05/07/2024 - 16:05', attendances: 17 },
  { date: '06/07/2024 - 10:41', attendances: 25 },
  { date: '07/07/2024 - 15:14', attendances: 21 },
  { date: '08/07/2024 - 20:59', attendances: 24 },
  { date: '09/07/2024 - 15:53', attendances: 15 },
];
