import { forwardRef } from 'react';
import { IconChevronUp, IconLogout2 } from '@tabler/icons-react';
import { Group, Avatar, Text, Menu, UnstyledButton, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from '@/stores/accountStore';
import { User, getUserFullName, getUserInitials } from '@/models/User';
import notifications from '@/utils/notifications';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  name: string;
  image?: string;
  email?: string;
  initials?: string;
}

export default function HeaderUserButton() {
  const { user, forget } = useAccountStore();
  const navigate = useNavigate();

  function onLogout() {
    navigate('/login', { replace: true });
    forget();

    notifications.showSuccessNotification({
      title: 'Vidimo se kasnije!',
      message: 'Dobrodošli nazad u bilo koje vreme',
    });
  }

  return (
    <Menu withArrow>
      <Menu.Target>
        <CustomUserButtonDropwdown
          name={getUserFullName(user as User)}
          initials={getUserInitials(user as User)}
          email={user?.email}
        />
      </Menu.Target>

      <Menu.Dropdown w={150}>
        <Menu.Label>Opcije</Menu.Label>

        <Menu.Divider />

        <Menu.Item
          color="red"
          leftSection={<IconLogout2 style={{ width: rem(14), height: rem(14) }} />}
          onClick={onLogout}
        >
          Odjavi me
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const CustomUserButtonDropwdown = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, initials, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      style={{
        padding: 'var(--mantine-spacing-sm)',
        color: 'var(--mantine-color-text)',
        borderRadius: 'var(--mantine-radius-lg)',
      }}
      {...others}
    >
      <Group>
        {image ? <Avatar src={image} radius="xl" /> : <Avatar>{initials}</Avatar>}

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {name}
          </Text>

          <Text c="dimmed" size="xs">
            {email}
          </Text>
        </div>

        <IconChevronUp size="1rem" />
      </Group>
    </UnstyledButton>
  )
);
