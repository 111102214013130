import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import useShowModal from '@/components/Modals/showModal';
import CreateMember from '@/containers/Members/CreateMember';
import MembersContainer from '@/containers/Members/MembersContainer';
import PageWrapper from '@/components/Common/PageWrapper';

export const Members = () => {
  const showModal = useShowModal();

  const openModal = () =>
    showModal({
      id: 'create-member',
      title: 'Dodajte polaznika',
      description: 'Unesite neophodne informacije o polazniku kluba',
      children: <CreateMember />,
    });

  return (
    <PageWrapper>
      <PageInfoHeader
        title="👥 Polaznici"
        subtitle="Sveobuhvatan pregled i upravljanje polaznicima kluba"
        actionButton={
          <Button
            onClick={openModal}
            size="md"
            leftSection={<IconPlus size={20} color="white" />}
            fz="sm"
          >
            Dodaj polaznika
          </Button>
        }
      />
      <MembersContainer />
    </PageWrapper>
  );
};
