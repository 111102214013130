import { useState } from 'react';
import { Button, Divider, Flex, LoadingOverlay, Paper, Select, Stack, Pill } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { differenceBy } from 'lodash';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { Group } from '@/models/Group';
import { useAddCoachesToGroup } from '@/api/hooks/groups/useAddCoachesToGroup';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import { getUserFullName } from '@/models/User';

const AddCoachesToGroup = ({ group }: { group: Group }) => {
  const { organisation } = useAccountStore();
  const { data: coaches, isLoading: isCoachesLoading } = useCoaches(organisation?.id as string);

  const [selectedCoaches, setSelectedCoaches] = useState<{ id: string; full_name: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { mutateAsync: addCoachesToGroup, isPending: isAddingCoachesToGroup } =
    useAddCoachesToGroup();

  // Finds coaches not in the group by comparing their 'id'.
  const coachesNotInGroup = differenceBy(coaches?.results, group?.coaches, 'id');

  // Filters out coaches from 'coachesNotInGroup' who are already in 'selectedCoaches' by comparing their 'id'.
  const filteredCoachesNotInGroup = differenceBy(coachesNotInGroup, selectedCoaches, 'id');

  const handleCoachSelect = (value: string | null) => {
    if (value) {
      const coach = coachesNotInGroup?.find((m) => m.id === value);

      if (coach) {
        setSelectedCoaches((prev) => [
          ...prev,
          { id: coach.id, full_name: getUserFullName(coach.user) },
        ]);

        // Reset the select value
        setSelectedValue(null);
      }
    }
  };

  const handleRemoveCoach = (id: string) => {
    setSelectedCoaches((prev) => prev.filter((coach) => coach.id !== id));
  };

  const onAddCoachesToGroup = async () => {
    if (selectedCoaches.length > 0) {
      await addCoachesToGroup({
        groupId: group.id,
        coacheIds: selectedCoaches.map((member) => member.id),
      });
    }
    notifications.showSuccessNotification({
      title: 'Uspešno',
      message: 'Uspešno ste dodali trenere u grupu',
    });
    modals.closeAll();
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={isAddingCoachesToGroup}
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Divider />

      <Paper>
        <Stack w={{ base: '100%' }} mt={0}>
          <Select
            mb={10}
            searchable
            label="Treneri"
            placeholder="Pretraži trenere"
            data={filteredCoachesNotInGroup?.map((coach) => ({
              value: coach.id,
              label: getUserFullName(coach.user),
            }))}
            onChange={handleCoachSelect}
            value={selectedValue}
            nothingFoundMessage="Nema trenera za vašu pretragu..."
            disabled={isCoachesLoading}
          />

          <Divider />

          <Pill.Group>
            {selectedCoaches.map((coach) => (
              <Pill key={coach.id} withRemoveButton onRemove={() => handleRemoveCoach(coach.id)}>
                {coach.full_name}
              </Pill>
            ))}
          </Pill.Group>
        </Stack>
      </Paper>

      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button
          onClick={onAddCoachesToGroup}
          disabled={selectedCoaches.length === 0}
          type="submit"
          leftSection={<IconPlus size={18} />}
        >
          Dodaj trenere
        </Button>
      </Flex>
    </Stack>
  );
};

export default AddCoachesToGroup;
