import { Training } from '@/models/Training';
import API from '@/api/base';

interface editTrainingRequestBody {
  trainingId: string;
  coachIds: string[];
  locationId: string;
}

export const editTrainingRequest = async ({
  trainingId,
  coachIds,
  locationId,
}: editTrainingRequestBody): Promise<Training> => {
  const { data } = await API.patch<Training>(`/trainings/${trainingId}/`, {
    coach_ids: coachIds,
    location_id: locationId,
  });

  return data;
};
