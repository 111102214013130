import API from '@/api/base';
import { Group } from '@/models/Group';

interface AddMembersToGroupRequestBody {
  groupId: string;
  memberIds: string[];
}

export const addMembersToGroupRequest = async ({
  groupId,
  memberIds,
}: AddMembersToGroupRequestBody): Promise<Group> => {
  const requestBody = { member_ids: memberIds };

  const { data } = await API.post<Group>(`/groups/${groupId}/add-members/`, requestBody);

  return data;
};
