import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { deletePaidMembershipRequest } from '@/api/requests/memberships/deletePaidMembership.request';

export const useDeletePaidMembership = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePaidMembershipRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['memberships', { organisationId: organisation?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: ['memberships', 'member'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
