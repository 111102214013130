import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTrainingRequest } from '@/api/requests/trainings/createTraining.request';
import { useAccountStore } from '@/stores/accountStore';

export const useCreateTraining = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createTrainingRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['trainings', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
