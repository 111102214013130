import { Badge, NumberFormatter, Table, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useAccountStore } from '@/stores/accountStore';
import { Membership } from '@/models/Membership';

export default function MemberMembershipsTableItem({ membership }: { membership: Membership }) {
  const { organisation } = useAccountStore();
  return (
    <Table.Tr key={membership.id}>
      <Table.Td>
        <Text c="gray.7" fw={500} size="md" ff="heading">
          {dayjs(membership.month).format('YYYY/MMMM')}
        </Text>
      </Table.Td>

      <Table.Td>
        <PaidStatus isPaid={membership.is_paid} shouldPay={membership.should_pay} />
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={500} size="md">
          {membership.is_paid && membership.amount_paid ? (
            <NumberFormatter
              suffix={` ${organisation?.currency}`}
              value={membership.amount_paid}
              thousandSeparator
            />
          ) : (
            '-'
          )}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {membership.collected_by ? membership.collected_by.full_name : '-'}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {membership.created_at ? dayjs(membership.created_at).format('dddd, MMMM D, YYYY') : '-'}
        </Text>
      </Table.Td>

      <Table.Td />
    </Table.Tr>
  );
}

export const PaidStatus = ({ isPaid, shouldPay }: { isPaid: boolean; shouldPay: boolean }) => {
  if (!shouldPay) {
    return (
      <Badge color="yellow" size="md">
        nije trenirao/la
      </Badge>
    );
  }

  // Default behavior based on the `isPaid` prop
  return isPaid ? (
    <Badge color="teal" size="md">
      plaćeno
    </Badge>
  ) : (
    <Badge color="red" size="md">
      neplaćeno
    </Badge>
  );
};
