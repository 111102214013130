import { createTheme } from '@mantine/core';

export const theme = createTheme({
  fontFamily: 'Montserrat, sans-serif',
  fontFamilyMonospace: 'Menlo, Monaco, monospace',
  headings: {
    fontFamily: 'Montserrat, sans-serif',
  },
  primaryColor: 'teal',
  defaultRadius: 'md',
});
