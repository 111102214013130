import API from '@/api/base';
import { Member } from '@/models/Member';

export interface FetchMembersParams extends PaginationQueryParams {
  organisationId: string;
  name?: string;
  gender?: string;
  year?: string;
}

export const fetchMembersRequest = async ({
  organisationId,
  name,
  gender,
  year,
  page,
  pageSize,
}: FetchMembersParams): Promise<PaginatedResponse<Member>> => {
  const url = buildQueryURL(organisationId, { name, gender, year, page, pageSize });

  const { data } = await API.get<PaginatedResponse<Member>>(url);

  return data;
};

function buildQueryURL(
  organisationId: string,
  params: {
    name?: string;
    gender?: string;
    year?: string;
    page?: number;
    pageSize?: number;
  }
): string {
  const queryParams = new URLSearchParams();
  queryParams.set('organisation', organisationId);

  if (params.page) queryParams.set('page', params.page.toString());
  if (params.name) {
    queryParams.set('name', params.name);
  }
  if (params.gender) {
    queryParams.set('gender', params.gender);
  }
  if (params.year) {
    queryParams.set('birth_date', params.year);
  }
  if (params.pageSize) queryParams.set('page_size', params.pageSize.toString());

  return `/members/?${queryParams.toString()}`;
}
