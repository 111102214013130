import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { updateMePassword } from '@/api/requests/auth/updateMePassword';
import notifications from '@/utils/notifications';

export const useUpdateMePassword = () =>
  useMutation({
    mutationFn: updateMePassword,
    onError: (error) => {
      if ((error as AxiosError)?.response?.status === 400) {
        notifications.showErrorNotification({
          title: 'Loznika nije uspešno promenjena',
          message: 'Uneli ste netačnu trenutnu lozinku',
        });
      }
      console.error('Something went wrong with changning user password', error);
    },
  });
