import { useQuery } from '@tanstack/react-query';
import {
  FetchTrainingsParams,
  fetchTrainingsRequest,
} from '@/api/requests/trainings/getTrainings.request';
import { Constants } from '@/constants';

export const useTrainings = ({
  organisationId,
  location,
  date,
  coachId,
  groupId,
  page,
  pageSize = Constants.PAGE_SIZE,
}: FetchTrainingsParams) =>
  useQuery({
    queryKey: ['trainings', { organisationId, location, date, coachId, groupId, page }],
    queryFn: () =>
      fetchTrainingsRequest({ organisationId, location, date, coachId, groupId, page, pageSize }),
  });
