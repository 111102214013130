import { Button, Card, Flex, rem, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import GroupMembersTable from '../../../components/Groups/Details/GroupMembersTable';
import { useGroupDetails } from '@/api/hooks/groups/useGroupDetails';
import { Group } from '@/models/Group';
import useShowModal from '@/components/Modals/showModal';
import AddMembersToGroup from './AddMembersToGroup';

export default function GroupMembers() {
  const showModal = useShowModal();
  const { id } = useParams<{ id: string }>();
  const { data: group, isLoading, error } = useGroupDetails(id as string);

  if (error) {
    return <div>Došlo je do greške</div>;
  }

  const openModal = () =>
    showModal({
      id: 'add-members-to-group',
      title: 'Dodajte polaznike u grupu',
      children: <AddMembersToGroup group={group as Group} />,
      closeOnClickOutside: false,
    });

  return (
    <Card mt={20} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Flex
        p={16}
        gap={20}
        justify="space-between"
        align="center"
        direction={{ base: 'column', sm: 'row' }}
      >
        <Title fw={600} size={20} c="gray.9">
          Polaznici
        </Title>

        <Button
          onClick={openModal}
          leftSection={<IconPlus style={{ width: rem(14), height: rem(14) }} />}
          variant="outline"
          disabled={!group}
        >
          Dodaj polaznike
        </Button>
      </Flex>

      {isLoading ? <TableSkeletonLoading /> : <GroupMembersTable group={group as Group} />}
    </Card>
  );
}
