import { Training } from '@/models/Training';
import API from '@/api/base';

interface CreateTrainingRequestBody {
  group_id: string;
  coach_ids: string[];
  location_id: string;
  topic?: string;
}

export const createTrainingRequest = async ({
  group_id,
  coach_ids,
  topic,
  location_id,
}: CreateTrainingRequestBody): Promise<Training> => {
  const { data } = await API.post<Training>('/trainings/', {
    group_id,
    coach_ids,
    location_id,
    topic,
  });

  return data;
};
