import { Membership } from '@/models/Membership';
import API from '@/api/base';

interface FetchMembershipsParams extends PaginationQueryParams {
  organisationId: string;
  coach?: string; // ID
  year?: string;
  month?: string;
  paymentDate?: string;
}

export const fetchMembershipsRequest = async ({
  organisationId,
  coach,
  month,
  year,
  paymentDate,
  page,
  pageSize,
}: FetchMembershipsParams): Promise<PaginatedResponse<Membership>> => {
  const url = buildQueryURL(organisationId, { coach, month, year, paymentDate, page, pageSize });

  const { data } = await API.get<PaginatedResponse<Membership>>(url);

  return data;
};

function buildQueryURL(
  organisationId: string,
  params: {
    coach?: string;
    month?: string;
    year?: string;
    paymentDate?: string;
    page?: number;
    pageSize?: number;
  }
): string {
  const queryParams = new URLSearchParams();
  queryParams.set('organisation', organisationId);

  if (params.page) queryParams.set('page', params.page.toString());
  if (params.pageSize) queryParams.set('page_size', params.pageSize.toString());
  if (params.coach) {
    queryParams.set('collected_by', params.coach);
  }
  if (params.month) {
    queryParams.set('month', params.month);
  }
  if (params.year) {
    queryParams.set('year', params.year);
  }
  if (params.paymentDate) {
    queryParams.set('payment_date', params.paymentDate);
  }

  return `/memberships/?${queryParams.toString()}`;
}
