import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchCoachByUser } from '@/api/requests/coaches/getCoachUser.request';
import { Coach } from '@/models/Coach';

export const useCoachByUser = (
  userId: string,
  options?: Omit<UseQueryOptions<Coach, Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['coachByUser', { userId }],
    queryFn: () => fetchCoachByUser(userId),
    ...options,
  });
