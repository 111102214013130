import { Table, Title } from '@mantine/core';
import React from 'react';
import NoDataTable from './NoDataTable';

interface CustomTableProps<T> {
  columns: string[];
  data: T[];
  noDataText?: string;
  renderRow: (el: T) => React.ReactNode;
}

export default function CustomTable<T>({
  columns,
  data,
  noDataText,
  renderRow,
}: CustomTableProps<T>) {
  const handleRowsRender = () => {
    if (data?.length === 0 || !data) return <NoDataTable text={noDataText} />;

    return data?.map(renderRow);
  };

  return (
    <Table verticalSpacing="sm" highlightOnHover>
      <Table.Thead style={{ backgroundColor: '#F6F8FA' }}>
        <Table.Tr style={{ borderBottom: 'none' }}>
          {columns.map((column) => (
            <Table.Th key={column}>
              <Title fw={400} size={16} c="gray.7">
                {column}
              </Title>
            </Table.Th>
          ))}
          <Table.Th />
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>{handleRowsRender()}</Table.Tbody>
    </Table>
  );
}
