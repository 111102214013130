export const Constants = {
  PAGE_SIZE: 15,
};

export const COLUMNS = {
  CoachTrainingColumns: ['Trening', 'Grupa', 'Trener', 'Lokacija', 'Prisustvo'],
  GroupsColumns: ['Ime grupe', 'Broj članova', 'Treneri'],
  MemberColumns: ['Ime', 'Godina rođenja', 'Pol', 'Grupa', 'Roditeljski nalog'],
  MemberMembershipsColumns: ['Mesec', 'Status', 'Iznos', 'Naplatio', 'Naplaćeno datuma'],
  MemberTrainingAttendanceColumns: ['Trening', 'Prisustvo', 'Grupa', 'Trener', 'Lokacija'],
  PaidMembershipsColums: ['Polaznik', 'Iznos', 'Mesec', 'Naplatio', 'Naplaćeno datuma'],
  TrainingColumns: ['Trening', 'Grupa', 'Trener', 'Lokacija', 'Prisustvo'],
  TrainingAttendanceColumns: ['Ime i Prezime', 'Prisustvo'],
};
