import { useQuery } from '@tanstack/react-query';
import { fetchMembershipsRequest } from '@/api/requests/memberships/getMemberships.request';
import { Constants } from '@/constants';

interface UseMembershipsParams extends PaginationQueryParams {
  organisationId: string;
  coach?: string;
  year?: string;
  month?: string;
  paymentDate?: string;
}

export const useMemberships = ({
  organisationId,
  coach,
  year,
  month,
  paymentDate,
  page,
  pageSize = Constants.PAGE_SIZE,
}: UseMembershipsParams) =>
  useQuery({
    queryKey: ['memberships', { organisationId, coach, year, month, paymentDate, page }],
    queryFn: () =>
      fetchMembershipsRequest({ organisationId, coach, year, month, paymentDate, page, pageSize }),
    enabled: !!organisationId,
  });
