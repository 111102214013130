import { useEffect, useState } from 'react';
import { useAccountStore } from '@/stores/accountStore';
import { meRequest } from '@/api/requests/auth/getMe.request';
import { getMyOrganisationRequest } from '@/api/requests/organisations/getMyOrganisation.request';
import { hasAnyRole, UserRole } from '@/models/User';

export const useInitializeAccountStore = () => {
  const [initializing, setInitializing] = useState(true);

  const {
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    setUser,
    setOrganisation,
    forget,
  } = useAccountStore();

  useEffect(() => {
    const initialize = async () => {
      setInitializing(true);
      if (accessToken && refreshToken) {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        try {
          const user = await meRequest();
          setUser(user);

          if (hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF, UserRole.COACH])) {
            const organisationData = await getMyOrganisationRequest(user.organisation);
            setOrganisation(organisationData);
          }
        } catch (error) {
          console.error('Failed to fetch user & organisation data:', error);
          forget();
        }
      }
      setInitializing(false);
    };

    initialize();
  }, [setAccessToken, setRefreshToken, forget, setUser]);

  return initializing;
};
