import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { addMembersToGroupRequest } from '@/api/requests/groups/addMembersToGroup.request';

export const useAddMembersToGroup = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addMembersToGroupRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', { organisationId: organisation?.id }],
      });

      queryClient.invalidateQueries({
        queryKey: ['members'],
      });

      queryClient.invalidateQueries({
        queryKey: ['trainings'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
