import API from '@/api/base';
import { Membership } from '@/models/Membership';

export interface MemberMembershipsRequest extends PaginationQueryParams {
  memberId: string;
}

export const fetchMemberMembershipsRequest = async ({
  memberId,
  page,
  pageSize,
}: MemberMembershipsRequest): Promise<PaginatedResponse<Membership>> => {
  const url = `/memberships/members/${memberId}/?page=${page}&page_size=${pageSize}`;

  const { data } = await API.get<PaginatedResponse<Membership>>(url);

  return data;
};
