import { Card, Flex, Pagination, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import { useTrainings } from '@/api/hooks/trainings/useTrainings';
import useIsMobile from '@/hooks/useIsMobile';
import { COLUMNS, Constants } from '@/constants';
import CustomTable from '@/components/Common/Table/CustomTable';
import { Training } from '@/models/Training';
import TrainingsTableItem from '@/components/Trainings/TrainingsTableItem';
import TrainingsMobileTable from '@/components/Trainings/TrainingsMobileTable';
import { useAccountStore } from '@/stores/accountStore';

export default function GroupTrainings() {
  const { organisation } = useAccountStore();
  const { id } = useParams<{ id: string }>();
  const isMobile = useIsMobile();
  const [activePage, setActivePage] = useState(1);

  const {
    data: trainings,
    isLoading,
    isError,
  } = useTrainings({
    organisationId: organisation?.id as string,
    location: '',
    date: '',
    coachId: '',
    groupId: id as string,
    page: activePage,
  });

  const lastPage = Math.ceil(Number(trainings?.count) / Constants.PAGE_SIZE);

  if (isError) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '25px' }}>
        Došlo je do greške prilikom učitavanja treninga ove grupe
      </div>
    );
  }

  return (
    <Card mt={20} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Flex p={16} mb={15} gap={20} align="center" direction={{ base: 'column', sm: 'row' }}>
        <Title fw={600} size={20} c="gray.9">
          Treninzi
        </Title>
      </Flex>
      {isLoading ? (
        <TableSkeletonLoading />
      ) : !isMobile ? (
        <CustomTable
          columns={COLUMNS.TrainingColumns}
          data={trainings?.results as Training[]}
          renderRow={(el) => <TrainingsTableItem training={el} key={el.id} />}
        />
      ) : (
        <TrainingsMobileTable data={trainings?.results as Training[]} />
      )}

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Card>
  );
}
