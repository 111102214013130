import { IconPlus } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { Button, Flex, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAddOrganisationLocations } from '@/api/hooks/organisations/useAddOrganisationLocation';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';

interface LocationInput {
  name: string;
}

const CreateNewLocation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LocationInput>();
  const { organisation } = useAccountStore();

  const { mutateAsync: addLocation, isPending } = useAddOrganisationLocations();

  const createLocation: SubmitHandler<LocationInput> = async (data) => {
    try {
      await addLocation({
        name: data.name,
        organisationId: organisation?.id as string,
      });

      notifications.showSuccessNotification({
        title: 'Uspešno ste dodali novu lokaciju',
        id: 'create-location',
      });

      modals.closeAll();
    } catch (error) {
      console.error('Oups, something went wrong while creating location', error);
    }
  };

  return (
    <Stack align="flex-end" pl={15} component="form" onSubmit={handleSubmit(createLocation)}>
      <LoadingOverlay visible={isPending} />

      <TextInput
        w="100%"
        placeholder="npr. Pomoćni stadion"
        error={errors.name?.message}
        {...register('name', { required: 'Ime lokacije je obavezna' })}
      />

      <Flex gap={10} justify="flex-end" mt={25}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button type="submit" leftSection={<IconPlus size={18} />}>
          Dodaj
        </Button>
      </Flex>
    </Stack>
  );
};

export default CreateNewLocation;
