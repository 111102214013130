import { MonthlyMembershipSummary } from '@/models/Membership';
import API from '@/api/base';

export const fetchMembershipsHistoryChart = async (
  organisationId: string
): Promise<MonthlyMembershipSummary[]> => {
  const { data } = await API.get<MonthlyMembershipSummary[]>(
    `/memberships/monthly-summary-history/${organisationId}/`
  );

  return data;
};
