import { useState } from 'react';
import { Button, Divider, Flex, LoadingOverlay, Paper, Select, Stack, Pill } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { differenceBy } from 'lodash';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { useAddMembersToGroup } from '@/api/hooks/groups/useAddMembersToGroup';
import { useAutoFetchMembers } from '@/api/hooks/members/useAutoFetchMembers';
import { Group } from '@/models/Group';

const AddMembersToGroup = ({ group }: { group: Group }) => {
  const { organisation } = useAccountStore();
  const { data: members, isLoading: isMembersLoading } = useAutoFetchMembers(
    organisation?.id as string
  );

  const [selectedMembers, setSelectedMembers] = useState<{ id: string; full_name: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { mutateAsync: addMembersToGroup, isPending: isAddingMembersToGroup } =
    useAddMembersToGroup();

  // Finds members that are not in the group by comparing their 'id'.
  const membersNotInGroup = differenceBy(members, group?.members, 'id');

  // Filters out members from 'membersNotInGroup' who are already in 'selectedMembers' by comparing their 'id'.
  const filteredMembersNotInGroup = differenceBy(membersNotInGroup, selectedMembers, 'id');

  const handleMemberSelect = (value: string | null) => {
    if (value) {
      const member = membersNotInGroup?.find((m) => m.id === value);

      if (member) {
        setSelectedMembers((prev) => [...prev, { id: member.id, full_name: member.full_name }]);

        // Reset the select value
        setSelectedValue(null);
      }
    }
  };

  const handleRemoveMember = (id: string) => {
    setSelectedMembers((prev) => prev.filter((member) => member.id !== id));
  };

  const onAddMembersToGroup = async () => {
    if (selectedMembers.length > 0) {
      await addMembersToGroup({
        groupId: group.id,
        memberIds: selectedMembers.map((member) => member.id),
      });

      notifications.showSuccessNotification({
        title: 'Uspešno',
        message: 'Uspešno ste dodali nove polaznike u grupu',
      });
    }

    modals.closeAll();
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay visible={isAddingMembersToGroup} overlayProps={{ radius: 'sm', blur: 2 }} />
      <Divider />

      <Paper>
        <Stack w={{ base: '100%' }} mt={0}>
          <Select
            mb={10}
            searchable
            label="Polaznici"
            placeholder={isMembersLoading ? 'Učitavanje polaznika.. ' : 'Pretraži polaznike'}
            data={filteredMembersNotInGroup?.map((member) => ({
              value: member.id,
              label: member.full_name,
            }))}
            onChange={handleMemberSelect}
            value={selectedValue}
            nothingFoundMessage="Nema članova za vašu pretragu..."
            disabled={isMembersLoading}
          />

          <Divider />

          <Pill.Group>
            {selectedMembers.map((member) => (
              <Pill key={member.id} withRemoveButton onRemove={() => handleRemoveMember(member.id)}>
                {member.full_name}
              </Pill>
            ))}
          </Pill.Group>
        </Stack>
      </Paper>

      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button
          onClick={onAddMembersToGroup}
          disabled={selectedMembers.length === 0}
          type="submit"
          leftSection={<IconPlus size={18} />}
        >
          Dodaj polaznike
        </Button>
      </Flex>
    </Stack>
  );
};

export default AddMembersToGroup;
