import { AreaChart } from '@mantine/charts';
import { Box, Paper, Title } from '@mantine/core';
import dayjs from 'dayjs';
import { MonthlyMembershipSummary } from '@/models/Membership';

interface MonthlyMembershipsHistoryChartProps {
  data: MonthlyMembershipSummary[];
  currency: string;
}

export default function MonthlyMembershipsHistoryChart({
  data,
  currency,
}: MonthlyMembershipsHistoryChartProps) {
  return (
    <Box mt={50}>
      <Title size={18} fw={500} ff="heading">
        Mesečni zbir članarina po mesecima do trenutnog meseca
      </Title>
      <Paper w="100%" mt={20}>
        <AreaChart
          py={20}
          px={50}
          w="100%"
          h={300}
          data={data.map((el) => ({
            ...el,
            total_amount_paid: Number(el.total_amount_paid),
            month: dayjs(el.month).format('MMMM'),
          }))}
          dataKey="month"
          valueFormatter={(val) => new Intl.NumberFormat('sr-RS').format(val)}
          withLegend
          series={[{ name: 'total_amount_paid', label: 'Ukupni naplaćeni iznos', color: 'teal.6' }]}
          xAxisLabel="Mesec u tekućoj godini"
          withGradient={false}
          unit={currency}
          tickLine="x"
        />
      </Paper>
    </Box>
  );
}
