import { Card, Title, Box } from '@mantine/core';
import { Attendance } from '@/models/Training';
import TrainingAttendanceProgressBar from '@/components/Trainings/TrainingAttendanceProgressBar';
import CustomTable from '@/components/Common/Table/CustomTable';
import TrainingAttendanceTableItem from '@/components/Trainings/Details/TrainingAttendanceTableItem';
import { COLUMNS } from '@/constants';

export default function TrainingAttendance({
  trainingAttendances,
}: {
  trainingAttendances: Attendance[];
}) {
  return (
    <Card mt={50} p="lg">
      <Title ml={5} fw={600} size={18} c="gray.9">
        Prisutnost na treningu
      </Title>

      <Box px={5} py={20} w={200}>
        <TrainingAttendanceProgressBar trainingAttendances={trainingAttendances} />
      </Box>

      {trainingAttendances && (
        <CustomTable
          data={trainingAttendances.sort((a, b) => Number(b.is_present) - Number(a.is_present))}
          columns={COLUMNS.TrainingAttendanceColumns}
          renderRow={(attendance) => <TrainingAttendanceTableItem attendance={attendance} />}
        />
      )}
    </Card>
  );
}
