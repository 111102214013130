import API from '@/api/base';

interface TrainingsMoMStatsResponse {
  current_month: number;
  mom_change: number;
  mom_change_percentage: string;
  previous_month: number;
  trend: string;
}

export const getTrainingsMoMStats = async (): Promise<TrainingsMoMStatsResponse> => {
  const response = await API.get<TrainingsMoMStatsResponse>('/trainings/stats');

  return response.data;
};
