import {
  IconHome,
  IconId,
  IconRun,
  IconSettings,
  IconUsers,
  IconUsersGroup,
} from '@tabler/icons-react';
import { Permission } from '@/models/Permission';

export interface Route {
  label: string;
  icon: JSX.Element;
  permission: Permission;
  href?: string;
  links?: Omit<Route, 'icon'>[];
}

export default [
  {
    label: 'Početna',
    href: '/',
    icon: <IconHome size={25} stroke={1.5} />,
    permission: Permission.HOMEPAGE_VIEW,
  },
  {
    label: 'Članovi',
    icon: <IconUsers size={25} stroke={1.5} />,
    permission: Permission.MEMBERS_VIEW && Permission.COACHES_VIEW,
    links: [
      {
        label: 'Polaznici',
        href: '/members',
        permission: Permission.COACHES_VIEW,
      },
      {
        label: 'Treneri',
        href: '/coaches',
        permission: Permission.COACHES_VIEW,
      },
    ],
  },
  {
    label: 'Grupe polaznika',
    href: '/groups',
    icon: <IconUsersGroup size={25} stroke={1.5} />,
    permission: Permission.GROUPS_VIEW,
  },
  {
    label: 'Članarine',
    href: '/memberships',
    icon: <IconId size={25} stroke={1.5} />,
    permission: Permission.MEMBERSHIPS_VIEW,
  },
  {
    label: 'Treninzi',
    href: '/trainings',
    icon: <IconRun size={25} stroke={1.5} />,
    permission: Permission.TRAININGS_VIEW,
  },
  {
    label: 'Podešavanja',
    href: '/settings',
    icon: <IconSettings size={20} stroke={1.5} />,
    permission: Permission.SETTINGS_VIEW,
  },
] as Route[];
