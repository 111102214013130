import { Flex, Title, Text, Divider } from '@mantine/core';
import React from 'react';

interface Props {
  title: string;
  subtitle: string;
  actionButton?: React.ReactNode;
  withBorder?: boolean;
}
export default function PageInfoHeader({
  title,
  subtitle,
  actionButton,
  withBorder = true,
}: Props) {
  return (
    <div>
      <Flex
        justify="space-between"
        w="100%"
        align={{ base: 'flex-start', sm: 'center' }}
        direction={{ base: 'column', xs: 'row' }}
        gap={{ base: 30, sm: 0 }}
      >
        <div>
          <Title size={20}>{title}</Title>
          <Text c="gray.8" mt={5} size="xs" ff="monospace">
            {subtitle}
          </Text>
        </div>
        {actionButton || null}
      </Flex>

      {withBorder ? <Divider my="lg" /> : null}
    </div>
  );
}
