import { Skeleton, Table } from '@mantine/core';

interface SkeletonTableProps {
  rows?: number;
  columns?: number;
}

export default function TableSkeletonLoading({ rows = 15, columns = 4 }: SkeletonTableProps) {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          {Array(columns)
            .fill(0)
            .map((_, index) => (
              <Table.Th key={`header-${index}`}>
                <Skeleton height={30} radius="sm" />
              </Table.Th>
            ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Array(rows)
          .fill(0)
          .map((_, rowIndex) => (
            <Table.Tr key={`row-${rowIndex}`}>
              {Array(columns)
                .fill(0)
                .map((__, colIndex) => (
                  <Table.Td key={`cell-${rowIndex}-${colIndex}`}>
                    <Skeleton height={40} radius="sm" />
                  </Table.Td>
                ))}
            </Table.Tr>
          ))}
      </Table.Tbody>
    </Table>
  );
}
