import { Menu, ActionIcon } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

export default function TableActionsMenu({ optionsHandler }: { optionsHandler: () => void }) {
  return (
    <Menu position="top" offset={10}>
      <Menu.Target>
        <ActionIcon radius="xl" variant="subtle" color="teal">
          <IconDotsVertical size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown px={10} pr={20} py={10}>
        <Menu.Label>Opcije</Menu.Label>
        <Menu.Item onClick={optionsHandler} color="red" leftSection={<IconTrash size={20} />}>
          Izbrisi
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
