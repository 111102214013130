import { Group } from '@/models/Group';
import API from '@/api/base';

interface FetchGroupsRequestParams {
  organisationId: string;
  name?: string;
  coachId?: string;
}

export const fetchGroupsRequest = async ({
  organisationId,
  name,
  coachId,
}: FetchGroupsRequestParams): Promise<PaginatedResponse<Group>> => {
  let url = `/groups/?organisation=${organisationId}&page_size=100`;

  if (name) {
    url += `&name=${name}`;
  }

  if (coachId) {
    url += `&coach=${coachId}`;
  }

  const { data } = await API.get<PaginatedResponse<Group>>(url);

  return data;
};
