import API from '@/api/base';
import { Parent } from '@/models/Parent';
import { User } from '@/models/User';

interface CreateAccountBody {
  user: Partial<User> & { password: string };
  children?: string[];
}

export const createParentAccount = async ({
  user,
  children,
}: CreateAccountBody): Promise<Parent> => {
  const response = await API.post<Parent>('/parents/', {
    user,
    children,
  });

  return response.data;
};
