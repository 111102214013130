import React from 'react';
import { Loader, Text } from '@mantine/core';
import classes from './AppLoading.module.css';

export default function AppLoading() {
  return (
    <div className={classes.container}>
      <Loader color="teal" size="xl" />
      <Text c="black" size="xl" ta="center" mt={20}>
        Osnaživanje sportskih akademija za postizanje izvrsnosti
      </Text>
      <Text c="black" size="lg" ta="center" mt={20}>
        Upravljanje članovima, treninzima i članstvom s lakoćom
      </Text>
    </div>
  );
}
