import { useMutation } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { loginRequest } from '@/api/requests/auth/login.request';

export const useLogin = () => {
  const { setAccessToken, setRefreshToken } = useAccountStore();

  return useMutation({
    mutationFn: loginRequest,
    onSuccess: async (data) => {
      setAccessToken(data.access);
      setRefreshToken(data.refresh);
    },
    onError: (error) => {
      console.error('Login failed', error);
    },
    retry: 1,
  });
};
