// useGetMyOrganisation.ts
import { useQuery } from '@tanstack/react-query';
import { getMyOrganisationRequest } from '@/api/requests/organisations/getMyOrganisation.request';

export const useGetMyOrganisation = ({ organisationId }: { organisationId: string }) =>
  useQuery({
    queryKey: ['organisation', { organisationId }],
    queryFn: () => getMyOrganisationRequest(organisationId),
    enabled: !!organisationId,
  });
