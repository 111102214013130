import { ActionIcon, Tooltip, Button, Flex, Stack, Text, Title } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useAccountStore } from '@/stores/accountStore';
import LocationForm from './LocationForm';
import useShowModal from '@/components/Modals/showModal';
import CreateNewLocation from './CreateNewLocation';
import { useGetMyOrganisation } from '@/api/hooks/organisations/useGetMyOrganisation';

const LOCATION_TOOLTIP_EXPLAINER = `Lokacije predstavljaju fizičke prostore gde se održavaju treninzi,
  takmičenja i drugi događaji vaše akademije ili kluba. Definisanje lokacija omogućava trenerima i osoblju da precizno planiraju i organizuju aktivnosti na pravim mestima. Svaki trening, događaj ili aktivnost treba biti povezan sa odgovarajućom lokacijom kako bi se osigurala tačnost i efikasnost u planiranju.`;

const OrganisationLocations = () => {
  const { user } = useAccountStore();
  const showModal = useShowModal();

  const { data: organisation } = useGetMyOrganisation({
    organisationId: user?.organisation as string,
  });
  const locations = organisation?.locations;

  const createLocationModal = () =>
    showModal({
      id: 'create-location',
      title: 'Dodajte novu lokaciju',
      description: 'Unesite ime za lokaciju',
      children: <CreateNewLocation />,
      size: 'sm',
    });

  const renderLocations = () => {
    if (locations && locations.length > 0) {
      return locations.map((location) => <LocationForm key={location.id} location={location} />);
    }
    return <Text mb={30}>Vaša akademija nema kreiranih lokacija..</Text>;
  };

  return (
    <Stack
      mt={40}
      p="xl"
      gap="md"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
        position: 'relative',
      }}
    >
      <Flex gap={10}>
        <Title size={20}>Lokacije kluba/akademije</Title>
        <Tooltip
          multiline
          w={420}
          withArrow
          transitionProps={{ duration: 200 }}
          label={LOCATION_TOOLTIP_EXPLAINER}
        >
          <ActionIcon variant="light" aria-label="Settings">
            <IconInfoCircle />
          </ActionIcon>
        </Tooltip>
      </Flex>
      <Stack mt={20}>
        {renderLocations()}
        <Button onClick={createLocationModal} w={{ base: '100%', xs: '40%', sm: '15%' }}>
          Dodaj lokaciju
        </Button>
      </Stack>
    </Stack>
  );
};

export default OrganisationLocations;
