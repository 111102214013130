import { useQuery } from '@tanstack/react-query';
import { FetchMembersParams, fetchMembersRequest } from '@/api/requests/members/getMembers.request';
import { Constants } from '@/constants';

export const useMembers = ({
  organisationId,
  page,
  name,
  gender,
  year,
  pageSize = Constants.PAGE_SIZE,
}: FetchMembersParams) =>
  useQuery({
    queryKey: ['members', { organisationId, page, name, gender, year }],
    queryFn: () => fetchMembersRequest({ organisationId, name, gender, year, page, pageSize }),
    enabled: !!organisationId,
  });
