import { Button, Menu, rem } from '@mantine/core';
import { IconBolt, IconEdit, IconFilePlus, IconTrash } from '@tabler/icons-react';

interface MemberActionButtonMenuProps {
  onMemberEdit: () => void;
  onAdditionalOptions: () => void;
  onMemberDelete: () => void;
}

export default function MemberActionButtonMenu({
  onMemberEdit,
  onAdditionalOptions,
  onMemberDelete,
}: MemberActionButtonMenuProps) {
  return (
    <Menu width={200}>
      <Menu.Target>
        <Button mr={50} leftSection={<IconBolt style={{ width: rem(14), height: rem(14) }} />}>
          Akcije
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Opcije</Menu.Label>
        <Menu.Item
          onClick={onMemberEdit}
          leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
        >
          Izmeni polaznika
        </Menu.Item>

        <Menu.Item
          onClick={onAdditionalOptions}
          leftSection={<IconFilePlus style={{ width: rem(14), height: rem(14) }} />}
        >
          Dodatne opcije
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          onClick={onMemberDelete}
          color="red"
          leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
        >
          Izbriši polaznika
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
