import { Anchor, Badge, Card, Flex, Indicator, Progress, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Training } from '@/models/Training';
import { isWithin15Minutes } from '@/pages/Trainings/TrainingDetails.page';

export default function TrainingsMobileTable({ data }: { data: Training[] }) {
  const navigate = useNavigate();

  const isToday = (date: Date) => dayjs(date).isSame(dayjs(), 'day');

  const handleCardRender = () => {
    if (data.length === 0) return <Text>Nema podataka...</Text>;

    return data.map((training) => {
      const totalParticipants = training.attendances.length;
      const attendeesCount = training.attendances.filter(
        (participant) => participant.is_present
      ).length;
      const attendeesPercentage = (attendeesCount / totalParticipants) * 100;

      const progressBarColor = () => {
        switch (true) {
          case attendeesPercentage < 25:
            return 'red';
          case attendeesPercentage < 50:
            return 'orange';
          case attendeesPercentage < 75:
            return 'yellow';
          default:
            return 'teal';
        }
      };
      return (
        <Card key={training.id} onClick={() => navigate(`/trainings/${training.id}`)}>
          <Stack gap={10}>
            <Anchor
              style={{ display: 'flex', alignItems: 'center', gap: 10, alignSelf: 'center' }}
              c="gray.9"
              mb={10}
              fw={600}
              size="lg"
              underline="hover"
              ff="heading"
            >
              {isWithin15Minutes(training.date) ? (
                <Indicator inline processing color="teal" mr={5} />
              ) : null}

              {dayjs(training.date).format('dddd - DD/MM/YYYY - HH:mm')}

              {isToday(training.date) ? (
                <Badge variant="light" color="indigo" size="xs">
                  Danas
                </Badge>
              ) : null}
            </Anchor>
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Grupa
              </Text>
              <Text size="md" c="#777">
                {training.group.name}
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Trener
              </Text>
              <Text size="md" c="#777">
                {training.coaches.map((el) => el.full_name).join(', ')}
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text size="md" fw={600} c="#555">
                Lokacija
              </Text>
              <Text size="md" c="#777">
                {training.location?.name || '-'}
              </Text>
            </Flex>

            <Stack align="center" w="100%" gap={0}>
              <Progress value={attendeesPercentage} w="100%" color={progressBarColor()} />
              <Text size="xs" style={{ color: 'GrayText' }} mt={5}>
                {attendeesCount} / {totalParticipants} prisutno
              </Text>
            </Stack>
          </Stack>
        </Card>
      );
    });
  };

  return (
    <Stack px={0} gap={10}>
      {handleCardRender()}
    </Stack>
  );
}
