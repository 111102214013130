import API from '@/api/base';

interface UpdateMePasswordRequestBody {
  current_password: string;
  new_password: string;
}

export const updateMePassword = async (body: UpdateMePasswordRequestBody): Promise<void> => {
  const response = await API.post('auth/users/set_password/', body);

  return response.data;
};
