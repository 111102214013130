import { useQuery } from '@tanstack/react-query';
import { fetchGroupDetails } from '@/api/requests/groups/getGroupDetails.request';
import { useAccountStore } from '@/stores/accountStore';

export const useGroupDetails = (groupId: string) => {
  const { organisation } = useAccountStore();

  return useQuery({
    queryKey: ['groups', { organisationId: organisation?.id as string, groupId }],
    queryFn: () => fetchGroupDetails(groupId),
  });
};
