import { Member } from '@/models/Member';
import API from '@/api/base';

interface CreateMemberRequestBody {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string; // Date in YYYY-MM-DD format
  phoneNumber: string;
  address: string;
  membershipPrice: string;
}

export const createMemberRequest = async ({
  firstName,
  lastName,
  gender,
  birthDate,
  phoneNumber,
  address,
  membershipPrice,
}: CreateMemberRequestBody): Promise<Member> => {
  const requestBody = {
    first_name: firstName,
    last_name: lastName,
    gender,
    phone_number: phoneNumber,
    birth_date: birthDate,
    membership_price: membershipPrice,
    address,
  };

  const { data } = await API.post<Member>('/members/', requestBody);

  return data;
};
