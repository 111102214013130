import { IconId, IconPlus, IconRun, IconUser } from '@tabler/icons-react';
import { Button, Group } from '@mantine/core';
import DashboardActionCardButton from '@/components/Dashboard/CoachView/ActionCardButton/DashboardActionCardButton';
import useShowModal from '@/components/Modals/showModal';
import CreateMembershipPayment from '@/containers/Memberships/CreateMembershipPayment';
import CreateTraining from '@/containers/Trainings/CreateTraining';
import CreateMember from '@/containers/Members/CreateMember';

export default function DashboardActions() {
  const showModal = useShowModal();

  function openMarkMemberPaymentModal() {
    showModal({
      id: 'create-membership-paymnet',
      title: '💳 Evidentiraj uplatu',
      description:
        'Unesite informacije o plaćenoj članarini. Pažljivo unesite informacije o polazniku, mesecu za uplatu i količini.',
      children: <CreateMembershipPayment />,
    });
  }

  function openStartTrainingModal() {
    showModal({
      id: 'create-training',
      title: 'Započni trening',
      description: 'Detaljno planiranje i vođenje svih trening sesija',
      children: <CreateTraining />,
    });
  }
  function openCreateMemberModal() {
    showModal({
      id: 'create-member',
      title: 'Dodajte polaznika',
      description: 'Unesite neophodne informacije o polazniku kluba',
      children: <CreateMember />,
    });
  }

  return (
    <Group>
      <DashboardActionCardButton
        icon={<IconId size={25} stroke={1.5} />}
        title="Članarina"
        description="Kliknite ispod da evidentirate uplatu članarine"
        ctaButton={
          <Button
            size="md"
            onClick={openMarkMemberPaymentModal}
            leftSection={<IconPlus size={25} color="white" />}
          >
            Evidentiraj uplatu
          </Button>
        }
      />

      <DashboardActionCardButton
        icon={<IconRun size={25} stroke={1.5} />}
        title="Trening"
        description="Kliknite ispod da započnete trening i evidentirate pristunost"
        ctaButton={
          <Button
            onClick={openStartTrainingModal}
            size="md"
            leftSection={<IconPlus size={25} color="white" />}
          >
            Započni trening
          </Button>
        }
      />

      <DashboardActionCardButton
        icon={<IconUser size={25} stroke={1.5} />}
        title="Novi polaznik"
        description="Kliknite ispod da kreirate novog polaznika i dodelite mu grupu"
        ctaButton={
          <Button
            onClick={openCreateMemberModal}
            size="md"
            leftSection={<IconPlus size={25} color="white" />}
          >
            Dodaj polaznika
          </Button>
        }
      />
    </Group>
  );
}
