import { Member } from '@/models/Member';
import API from '@/api/base';
import { EditMemberForm } from '@/containers/Members/EditMember';

interface EditMemberBody extends Omit<EditMemberForm, 'birthDate'> {
  id: string;
  birthDate: string;
}

export const editMember = async (member: EditMemberBody): Promise<Member> => {
  const { data } = await API.patch<Member>(`/members/${member.id}/`, {
    first_name: member.firstName,
    last_name: member.lastName,
    birth_date: member.birthDate,
    phone_number: member.phoneNumber,
    address: member.address,
    gender: member.gender,
    membership_price: member.membershipPrice,
  });

  return data;
};
