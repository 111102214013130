import { Group } from '@/models/Group';
import API from '@/api/base';

interface EditGroupNameBody {
  name: string;
  groupId: string;
}

export const editGroupNameRequest = async ({
  name,
  groupId,
}: EditGroupNameBody): Promise<Group> => {
  const { data } = await API.patch<Group>(`/groups/${groupId}/`, {
    name,
  });

  return data;
};
