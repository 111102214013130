import { Route, Routes, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import ProtectedRoute from './components/Common/ProtectedRoute';
import { Layout } from './components/Layout/Layout';
import {
  Coaches,
  Groups,
  HomePage,
  LoginPage,
  MemberDetails,
  Members,
  Memberships,
  NotFoundPage,
  Settings,
  Trainings,
  TrainingDetails,
  GroupDetailsPage,
} from './pages';
import { Permission } from './models/Permission';

export function Router() {
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<ProtectedRoute permission={Permission.ALLOW_ALL_VIEW} />}>
            <Route index element={<HomePage />} />
            <Route path="/trainings/:id" element={<TrainingDetails />} />
            <Route path="/settings" element={<Settings />} />
          </Route>

          {/* Protected Routes for OWNER and STAFF MEMBERS */}
          <Route element={<ProtectedRoute permission={Permission.OWNERS_VIEW} />}>
            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/:id" element={<GroupDetailsPage />} />
            <Route path="/coaches" element={<Coaches />} />
            <Route path="/members" element={<Members />} />
            <Route path="/members/:id" element={<MemberDetails />} />
            <Route path="/memberships" element={<Memberships />} />
            <Route path="/trainings" element={<Trainings />} />
          </Route>
        </Route>

        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Wrapper>
  );
}

//This is for ReactRouter v6, scroll to top on page change
const Wrapper = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
