import API from '@/api/base';
import { Organisation } from '@/models/Organisation';

interface UpdateOrganisationRequestBody {
  name?: string;
  currency?: string;
  organisationId: string;
  configuration?: {
    min_trainings_required_for_membership_payment: number;
  };
}

export const updateOrganisation = async (
  body: UpdateOrganisationRequestBody
): Promise<Organisation> => {
  const { organisationId, name, currency, configuration } = body;
  const response = await API.patch(`organisations/${organisationId}/`, {
    name,
    currency,
    configuration,
  });

  return response.data;
};
