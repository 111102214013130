import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Select,
  SimpleGrid,
  TextInput,
  rem,
} from '@mantine/core';
import { IconCalendar, IconPlus, IconUser } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { MonthPickerInput } from '@mantine/dates';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useAutoFetchMembers } from '@/api/hooks/members/useAutoFetchMembers';
import { getUserFullName } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { useMarkMembershipPayment } from '@/api/hooks/memberships/useMarkMembershipPayment';
import { getMonthFromDate, getYearFromDate } from '@/utils/dateUtils';
import { Member } from '@/models/Member';

interface PaymentMembershipFormInputs {
  member: string;
  month: Date;
  amount: string;
}

export default function CreateMembershipPayment({ member }: { member?: Member }) {
  const { organisation } = useAccountStore();
  const { data: members, isLoading: isMembersLoading } = useAutoFetchMembers(
    organisation?.id as string,
    { enabled: !member }
  );

  const { mutateAsync: createMembershipPayment, isPending: isCreatingPayment } =
    useMarkMembershipPayment();

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PaymentMembershipFormInputs>({
    defaultValues: {
      member: member?.id,
    },
  });

  const selectedMemberId = watch('member');
  const selectedMember = members.find((el) => el.id === selectedMemberId);

  const onCreateMembershipPayment: SubmitHandler<PaymentMembershipFormInputs> = async (data) => {
    try {
      const formattedDate = dayjs(data.month).format('YYYY-MM-DD');

      await createMembershipPayment({
        memberId: data.member,
        month: getMonthFromDate(formattedDate),
        year: getYearFromDate(formattedDate),
        amount: Number(data.amount),
      });

      modals.closeAll();
      notifications.showSuccessNotification({
        title: 'Članarina uspešno evidentirana',
        message: 'Čestitke, uspešno ste potvrdili uplatu članarine',
      });
    } catch (e) {
      console.error('Error while creating new membership payment', e);
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(onCreateMembershipPayment)}
      px="md"
      component="form"
      mt={20}
      pos="relative"
    >
      <LoadingOverlay
        visible={isCreatingPayment}
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <SimpleGrid w="100%" cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'lg' }}>
        {member ? (
          <Controller
            name="member"
            control={control}
            defaultValue={member.id}
            render={({ field }) => (
              <Select
                leftSection={<IconUser style={{ width: rem(16), height: rem(16) }} />}
                required
                label="Polaznik"
                defaultValue={member.id}
                data={[{ value: member.id, label: getUserFullName(member) }]}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                allowDeselect={false}
              />
            )}
          />
        ) : (
          <Controller
            name="member"
            control={control}
            render={({ field }) => (
              <Select
                leftSection={<IconUser style={{ width: rem(16), height: rem(16) }} />}
                required
                label="Polaznik"
                placeholder="Odaberi polaznika"
                data={members.map((el) => ({ value: el.id, label: getUserFullName(el) }))}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={isMembersLoading}
                error={errors.member?.message}
                searchable
                clearable
                limit={10}
                nothingFoundMessage="Nema polaznika za vasu pretragu..."
              />
            )}
          />
        )}

        <Controller
          name="month"
          control={control}
          render={({ field }) => (
            <MonthPickerInput
              required
              disabled={Boolean(!selectedMemberId)}
              minDate={dayjs(selectedMember?.join_date).toDate()}
              leftSection={<IconCalendar size={15} stroke={1.5} />}
              leftSectionPointerEvents="none"
              label="Članarina za mesec"
              placeholder="Odaberite mesec"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <TextInput
          required
          type="number"
          placeholder="1000"
          label="Iznos"
          leftSection={organisation?.currency}
          leftSectionWidth={52}
          {...register('amount')}
        />
      </SimpleGrid>

      <Flex gap={10} justify="flex-end" mt={25}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button type="submit" leftSection={<IconPlus size={18} />}>
          Evidentiraj uplatu
        </Button>
      </Flex>
    </Box>
  );
}
