import { Center } from '@mantine/core';

import DashboardActions from './DashboardActions';
import CoachTrainings from './CoachTrainings';

export default function CoachDashboardView() {
  return (
    <>
      <Center>
        <DashboardActions />
      </Center>

      <CoachTrainings />
    </>
  );
}
