import { Box, Button, Card, Flex, Pagination, rem, Title } from '@mantine/core';
import { useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { useMemberMemberships } from '@/api/hooks/members/useMemberMemberships';
import TableLoading from '@/components/Common/Table/TableLoading';
import MemberMembershipsMobileTable from '@/components/Members/Details/Financials/MemberMembershipsMobileTable';
import { COLUMNS, Constants } from '@/constants';
import useIsMobile from '@/hooks/useIsMobile';
import useShowModal from '@/components/Modals/showModal';
import CreateMembershipPayment from '@/containers/Memberships/CreateMembershipPayment';
import { Member } from '@/models/Member';
import IsGranted from '@/components/Common/IsGranted';
import { Permission } from '@/models/Permission';
import CustomTable from '@/components/Common/Table/CustomTable';
import MemberMembershipsTableItem from '@/components/Members/Details/Financials/MemberMembershipsTableItem';

interface MemberMembershipsProps {
  memberId?: string;
  member?: Member;
  currency: string;
}

export default function MemberMemberships({ memberId, member, currency }: MemberMembershipsProps) {
  const isMobile = useIsMobile();
  const showModal = useShowModal();
  const [activePage, setActivePage] = useState(1);

  const { data: memberMemberships, isLoading } = useMemberMemberships({
    memberId: memberId || (member?.id as string),
    page: activePage,
  });

  const lastPage = Math.ceil(Number(memberMemberships?.count) / Constants.PAGE_SIZE);

  function openMarkMemberPaymentModal() {
    showModal({
      id: 'member-membership-payment',
      title: '💳 Evidentiraj uplatu',
      description:
        'Unesite informacije o plaćenoj članarini. Pažljivo unesite informacije o polazniku, mesecu za uplatu i iznosu.',
      children: <CreateMembershipPayment member={member} />,
    });
  }

  return (
    <Card mt={30} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Flex pt={10} px={10} justify="space-between" align="center">
        <Title fw={600} size={20} c="gray.9">
          Članarine
        </Title>

        <IsGranted permissions={Permission.MEMBERSHIPS_VIEW}>
          <Button
            onClick={openMarkMemberPaymentModal}
            leftSection={<IconPlus style={{ width: rem(14), height: rem(14) }} />}
            variant="outline"
            hidden
          >
            Naplati članarinu
          </Button>
        </IsGranted>
      </Flex>

      {isLoading && <TableLoading height="40vh" />}

      {memberMemberships &&
        (!isMobile ? (
          <Box mt="xl">
            <CustomTable
              data={memberMemberships.results}
              columns={COLUMNS.MemberMembershipsColumns}
              renderRow={(memberMembership) => (
                <MemberMembershipsTableItem membership={memberMembership} />
              )}
            />
          </Box>
        ) : (
          <MemberMembershipsMobileTable
            memberships={memberMemberships.results}
            currency={currency}
          />
        ))}

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Card>
  );
}
