import { Anchor, Table, Title, Text, Card } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { Group } from '@/models/Group';
import useShowModal from '@/components/Modals/showModal';
import RemoveCoachFromGroup from '@/containers/Groups/Details/RemoveCoachFromGroup';

export default function GroupCoachesTable({ group }: { group: Group }) {
  const showModal = useShowModal();
  const coaches = group?.coaches || [];

  const openDeleteModal = (coachId: string) => {
    const groupId = group.id;
    showModal({
      id: 'delete-coach',
      title: 'Uklonite trenera iz grupe',
      children: <RemoveCoachFromGroup coachId={coachId} groupId={groupId} />,
      size: 'md',
    });
  };

  const handleRowsRender = () => {
    if (coaches.length === 0) {
      return (
        <Text style={{ paddingTop: '20px', textAlign: 'center' }}>
          Ova grupa trenutno nema dodeljenih trenera.
        </Text>
      );
    }

    return coaches.map((coach) => (
      <Table.Tr key={coach.id}>
        <Table.Td
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
            {coach.full_name}
          </Anchor>
          <IconTrash
            onClick={() => openDeleteModal(coach.id)}
            style={{ cursor: 'pointer', height: '18px', color: 'red' }}
          />
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Card px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover>
        <Table.Thead bg="#F6F8FA">
          <Table.Tr style={{ borderBottom: 'none' }}>
            <Table.Th>
              <Title fw={400} size={16} c="gray.7">
                Ime i prezime
              </Title>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{handleRowsRender()}</Table.Tbody>
      </Table>
    </Card>
  );
}
