import {
  Alert,
  Box,
  Button,
  Flex,
  Loader,
  LoadingOverlay,
  PasswordInput,
  SimpleGrid,
  TextInput,
} from '@mantine/core';
import { IconInfoCircle, IconMail, IconPlus } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { useDebouncedValue } from '@mantine/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Member } from '@/models/Member';
import { useParentByUserEmail } from '@/api/hooks/parents/useParentByUserEmail';
import { useCreateParentAccount } from '@/api/hooks/parents/useCreateParentAccount';
import notifications from '@/utils/notifications';
import { useUpdateParentChildren } from '@/api/hooks/parents/useUpdateParentChildren';

interface CreateParentInputs {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  password?: string;
}

function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default function CreateMemberParent({ member }: { member: Member }) {
  const { register, handleSubmit } = useForm<CreateParentInputs>();
  const [emailValue, setEmailValue] = useState('');
  const [debouncedEmail] = useDebouncedValue(emailValue, 500);

  const { data: parentAccount, isLoading: isParentAccountLoading } = useParentByUserEmail(
    debouncedEmail,
    {
      enabled: isValidEmail(debouncedEmail),
      staleTime: 0, // no cache - call everytime
    }
  );

  const { mutateAsync: createParentAccount, isPending: isCreatingAccount } =
    useCreateParentAccount();
  const { mutateAsync: updateParentChildren, isPending: isAddingChildToAccount } =
    useUpdateParentChildren();

  const parentAccountFounded = !isParentAccountLoading && Boolean(parentAccount?.id);

  const handleCreateOrUpdateParent: SubmitHandler<CreateParentInputs> = useCallback(
    async (data) => {
      try {
        const { email, firstName, lastName, phoneNumber, password } = data;

        if (!parentAccountFounded) {
          await createParentAccount({
            user: {
              email,
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber,
              password: password as string,
            },
            children: [member.id],
          });
        } else {
          const childrenIds = parentAccount?.children.map((child) => child.id) as string[];

          await updateParentChildren({
            parentId: parentAccount?.id as string,
            children: [...childrenIds, member?.id],
          });
        }

        modals.closeAll();
        notifications.showSuccessNotification({
          title: 'Roditeljski/starateljski korisnički račun uspešno kreiran',
          message: 'Čestitke, uspešno ste kreirali račun za roditelja/staratelja.',
        });
      } catch (error) {
        console.error('Error handling parent account', error);
      }
    },
    [parentAccount, createParentAccount, updateParentChildren, member.id]
  );

  return (
    <Box
      onSubmit={handleSubmit(handleCreateOrUpdateParent)}
      px="md"
      component="form"
      pos="relative"
    >
      <LoadingOverlay
        visible={isAddingChildToAccount || isCreatingAccount}
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />

      {isValidEmail(debouncedEmail) && (
        <Alert
          mb={20}
          color={parentAccountFounded ? 'teal' : 'yellow'}
          title={
            parentAccountFounded ? 'Korisnički račun pronađen' : 'Korisnički račun nije pronađen'
          }
          icon={<IconInfoCircle size={16} />}
        >
          {parentAccountFounded
            ? 'Već postoji ovaj korisnički račun u našem sistemu, želite li da njemu dodelite ovog polaznika?'
            : 'Korisnički račun nije pronađen u našem sistemu, molimo vas kreirajte račun za ovog roditelja.'}
        </Alert>
      )}

      <TextInput
        required
        type="email"
        placeholder="Email roditelja/staratelja"
        description="Unesite email roditelja/staratelja za ovog polaznika"
        label="Email"
        mb={20}
        leftSection={isParentAccountLoading ? <Loader size="sm" /> : <IconMail size={18} />}
        disabled={isParentAccountLoading}
        {...register('email', {
          onChange: (e) => setEmailValue(e.target.value),
          required: '* Email je obavezan',
        })}
      />

      {isValidEmail(debouncedEmail) && !parentAccountFounded ? (
        <SimpleGrid w="100%" cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'lg' }}>
          <TextInput
            required
            type="text"
            placeholder="Ime roditelja"
            label="Ime"
            {...register('firstName')}
          />

          <TextInput
            required
            type="text"
            placeholder="Prezime roditelja"
            label="Prezime"
            {...register('lastName')}
          />
          <TextInput
            required
            type="text"
            placeholder="+381"
            label="Broj telefona"
            {...register('phoneNumber')}
          />
          <PasswordInput
            required
            type="password"
            placeholder="****"
            label="Lozinka"
            {...register('password')}
          />
        </SimpleGrid>
      ) : null}

      <Flex gap={10} justify="flex-end" mt={25}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button
          type="submit"
          leftSection={<IconPlus size={18} />}
          disabled={isParentAccountLoading || !isValidEmail(debouncedEmail)}
        >
          {!parentAccountFounded
            ? 'Kreiraj roditeljski račun'
            : 'Poveži polaznika sa roditeljskim računom'}
        </Button>
      </Flex>
    </Box>
  );
}
