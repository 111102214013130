import { Avatar, Card, Text, Group } from '@mantine/core';
import { IconAt, IconPhoneCall } from '@tabler/icons-react';
import { Coach } from '@/models/Coach';
import { getUserFullName, getUserInitials } from '@/models/User';

export const CoachCard = ({ coach }: { coach: Coach }) => (
  <Card
    p={20}
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: '5%',
    }}
  >
    <Group wrap="nowrap">
      <Avatar src={null} alt="user-avatar" color="teal" size="100px">
        {getUserInitials(coach.user)}
      </Avatar>
      <div>
        <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
          Trener
        </Text>

        <Text fz="lg" fw={500}>
          {getUserFullName(coach.user)}
        </Text>

        <Group wrap="nowrap" gap={10} mt={3}>
          <IconAt stroke={1.5} size="1rem" />
          <Text fz="xs" c="dimmed">
            {coach.user.email}
          </Text>
        </Group>

        <Group wrap="nowrap" gap={10} mt={5}>
          <IconPhoneCall stroke={1.5} size="1rem" />
          <Text fz="xs" c="dimmed">
            {coach.user.phone_number}
          </Text>
        </Group>
      </div>
    </Group>
  </Card>
);
