import { Box, Card, Pagination, Title } from '@mantine/core';
import { useState } from 'react';
import { useCoachByUser } from '@/api/hooks/coaches/useCoachByUser';
import TableLoading from '@/components/Common/Table/TableLoading';
import { Training } from '@/models/Training';
import { useAccountStore } from '@/stores/accountStore';
import { useTrainings } from '@/api/hooks/trainings/useTrainings';
import TrainingsMobileTable from '@/components/Trainings/TrainingsMobileTable';
import useIsMobile from '@/hooks/useIsMobile';
import { UserRole } from '@/models/User';
import CustomTable from '@/components/Common/Table/CustomTable';
import TrainingsTableItem from '@/components/Trainings/TrainingsTableItem';
import { COLUMNS } from '@/constants';

const COACH_TRAININGS_PAGE_SIZE = 10;

export default function CoachTrainings() {
  const { organisation, user } = useAccountStore();
  const isMobile = useIsMobile();

  const [activePage, setActivePage] = useState(1);

  const { data: currentCoach } = useCoachByUser(user?.id as string, {
    enabled: user?.role === UserRole.COACH,
  });

  const { data: trainings, isLoading } = useTrainings({
    organisationId: organisation?.id as string,
    coachId: currentCoach?.id as string,
    date: '',
    location: '',
    page: activePage,
    pageSize: COACH_TRAININGS_PAGE_SIZE,
  });

  const lastPage = Math.ceil(Number(trainings?.count) / COACH_TRAININGS_PAGE_SIZE);
  const data = trainings?.results as Training[];

  return (
    <Box mt="xl">
      <Card mt={10} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
        <Title mb={20} ml={5} fw={600} size={18} c="gray.9">
          Treninzi koje ste Vi vodili
        </Title>

        {isLoading && <TableLoading />}
        {!isLoading && !isMobile && (
          <CustomTable
            columns={COLUMNS.CoachTrainingColumns}
            data={data}
            renderRow={(el) => <TrainingsTableItem training={el} key={el.id} />}
          />
        )}
        {!isLoading && isMobile && <TrainingsMobileTable data={data} />}
      </Card>

      {lastPage > 1 ? (
        <Pagination mt={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Box>
  );
}
