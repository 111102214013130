import { rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { NavLink } from 'react-router-dom';
import { Route } from '../sidebarMenuLinks';
import styles from './sidebar.module.css';

interface ParentLinkProps extends Omit<Route, 'permission'> {
  isOpen: boolean;
  pathname: string;
  hasLinks: boolean;
}

export default function ParentLink({
  href,
  pathname,
  icon,
  label,
  hasLinks,
  isOpen,
}: ParentLinkProps) {
  return (
    <NavLink
      className={({ isActive }) => `${styles.link} ${isActive && href && styles.active}`}
      to={href ?? pathname}
      style={{ paddingRight: 10 }}
    >
      {icon}
      {label}

      {hasLinks && (
        <IconChevronDown
          stroke={1.5}
          style={{
            width: rem(16),
            height: rem(16),
            transform: isOpen ? 'rotate(-180deg)' : 'none',
            marginLeft: 'auto',
          }}
        />
      )}
    </NavLink>
  );
}
