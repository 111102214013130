import { Training } from '@/models/Training';
import API from '@/api/base';

export interface FetchTrainingsParams extends PaginationQueryParams {
  organisationId: string;
  location: string;
  date: string;
  coachId: string;
  groupId?: string;
}

export const fetchTrainingsRequest = async ({
  organisationId,
  location,
  date,
  coachId,
  groupId,
  page,
  pageSize,
}: FetchTrainingsParams): Promise<PaginatedResponse<Training>> => {
  const { data } = await API.get<PaginatedResponse<Training>>(
    buildQueryURL({ organisationId, location, date, coachId, groupId, page, pageSize })
  );

  return data;
};

function buildQueryURL(params: {
  organisationId: string;
  location?: string;
  date?: string;
  coachId?: string;
  groupId?: string;
  page?: number;
  pageSize?: number;
}): string {
  const queryParams = new URLSearchParams();
  if (params.organisationId) queryParams.set('organisation', params.organisationId);
  if (params.page) queryParams.set('page', params.page.toString());
  if (params.pageSize) queryParams.set('page_size', params.pageSize.toString());
  if (params.location) {
    queryParams.set('location', params.location);
  }
  if (params.date) {
    queryParams.set('date', params.date);
  }
  if (params.coachId) {
    queryParams.set('coach', params.coachId);
  }

  if (params.groupId) {
    queryParams.set('group', params.groupId);
  }

  return `/trainings/?${queryParams.toString()}`;
}
