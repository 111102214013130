import PageInfoHeader from '@/components/Common/PageInfoHeader';
import PageWrapper from '@/components/Common/PageWrapper';
import TrainingsContainer from '@/containers/Trainings/TrainingsContainer';

export const Trainings = () => (
  <PageWrapper>
    <PageInfoHeader
      title="🏋️‍♂️ Treninzi"
      subtitle="Detaljno planiranje i vođenje svih trening sesija"
    />
    <TrainingsContainer />
  </PageWrapper>
);
