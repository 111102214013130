import { Member } from '@/models/Member';
import API from '@/api/base';

interface AdditonalInfoRequest {
  member_id: string;
  has_jersey: boolean;
  has_tracksuit: boolean;
  has_medical_certificate: boolean;
  previous_debt: string;
}

export const editMemberAdditionalOptions = async (info: AdditonalInfoRequest): Promise<Member> => {
  const { data } = await API.patch<Member>(`/members/${info.member_id}/`, {
    has_jersey: info.has_jersey,
    has_tracksuit: info.has_tracksuit,
    has_medical_certificate: info.has_medical_certificate,
    previous_debt: info.previous_debt,
  });

  return data;
};
