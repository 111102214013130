import axios from 'axios';

export interface LoginData {
  email: string;
  password: string;
}

export interface LoginResponse {
  access: string;
  refresh: string;
}
const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;

export const loginRequest = async (loginData: LoginData): Promise<LoginResponse> => {
  const response = await axios.post(`${BASE_API_URL}/auth/jwt/create/`, loginData);
  return response.data;
};
