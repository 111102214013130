import { ActionIcon, Flex, Group, TextInput } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import EditNewLocation from './EditLocation';
import useShowModal from '@/components/Modals/showModal';
import { DeleteLocation } from './DeleteLocation';
import { OrganisationLocation } from '@/models/Organisation';

const LocationForm = ({ location }: { location: OrganisationLocation }) => {
  const showModal = useShowModal();

  const editLocationModal = () =>
    showModal({
      id: 'edit-location',
      title: 'Izmeni lokaciju',
      description: 'Ovde izmenite lokaciju koju ste već uneli',
      children: <EditNewLocation location={location} />,
      size: 'sm',
    });

  const deleteLocationModal = () =>
    showModal({
      id: 'delete-location',
      title: 'Izbriši lokaciju',
      children: <DeleteLocation location={location} />,
      size: 'sm',
    });

  return (
    <Flex
      gap={{ base: 10, sm: 20 }}
      wrap="wrap"
      align="center"
      justify={{ base: 'flex-end', sm: 'normal' }}
    >
      <TextInput
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        placeholder="Nova lokacija"
        value={location.name}
        style={{ pointerEvents: 'none' }}
      />

      <Group gap={5}>
        <ActionIcon variant="transparent" color="black" onClick={editLocationModal}>
          <IconEdit />
        </ActionIcon>

        <ActionIcon variant="transparent" color="red" onClick={deleteLocationModal}>
          <IconTrash />
        </ActionIcon>
      </Group>
    </Flex>
  );
};

export default LocationForm;
