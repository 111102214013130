import axios, { AxiosInstance } from 'axios';
import { useAccountStore } from '@/stores/accountStore';

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;

const API: AxiosInstance = axios.create({
  baseURL: BASE_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

API.interceptors.request.use(
  (config) => {
    const { accessToken } = useAccountStore.getState();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('error', error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken, setAccessToken, forget } = useAccountStore.getState();
      try {
        const response = await axios.post(`${BASE_API_URL}/auth/jwt/refresh/`, {
          refresh: refreshToken,
        });
        const { access } = response.data;
        setAccessToken(access);
        API.defaults.headers.common.Authorization = `Bearer ${access}`;
        originalRequest.headers.Authorization = `Bearer ${access}`;
        return await API(originalRequest);
      } catch (refreshError) {
        forget();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default API;
