import { Stack, Flex, Button, Text, LoadingOverlay } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { Member } from '@/models/Member';
import { useUpdateParentChildren } from '@/api/hooks/parents/useUpdateParentChildren';
import notifications from '@/utils/notifications';

interface RemoveParentFromMemberProps {
  parent: {
    id: string;
    children: string[];
  };
  member: Member;
}

export default function RemoveParentFromMember({ parent, member }: RemoveParentFromMemberProps) {
  const { mutateAsync: removeParentFromMember, isPending } = useUpdateParentChildren();

  console.log('parent', parent);

  async function onRemoveParentFromMember() {
    const updatedChildren = parent.children.filter((child) => child !== member.id);

    try {
      const response = await removeParentFromMember({
        parentId: parent.id,
        children: updatedChildren,
      });

      if (response) {
        notifications.showSuccessNotification({
          title: 'Roditeljski racun uklonjen',
          message: 'Uspešno ste uklonili roditeljski račun za ovog polaznika',
        });
        modals.closeAll();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Stack px="md" pos="relative">
      <LoadingOverlay visible={isPending} zIndex={200} overlayProps={{ radius: 'sm', blur: 2 }} />
      <Text>Da li ste sigurni da želite da uklonite ovaj roditeljski racun?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button
          onClick={onRemoveParentFromMember}
          leftSection={<IconTrash size={18} />}
          color="red"
        >
          Ukloni
        </Button>
      </Flex>
    </Stack>
  );
}
