import { Button, Flex, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { useDeleteOrganisationLocation } from '@/api/hooks/organisations/useDeleteOrganisationLocation';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { OrganisationLocation } from '@/models/Organisation';

export const DeleteLocation = ({ location }: { location: OrganisationLocation }) => {
  const { mutateAsync: deleteLocation } = useDeleteOrganisationLocation();
  const { organisation } = useAccountStore();

  const removeLocation = async () => {
    try {
      await deleteLocation({
        locationId: location?.id as string,
        organisationId: organisation?.id as string,
      });

      notifications.showSuccessNotification({
        title: 'Uspešno ste izbrisali lokaciju',
        id: 'delete-location',
      });
      modals.closeAll();
    } catch (err) {
      console.error('Oups, something went wrong while deleting location', err);
    }
  };

  return (
    <Stack px="md">
      <Text>Da li ste sigurni da želite da izbrišete lokaciju ?</Text>

      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>

        <Button leftSection={<IconTrash size={18} />} color="red" onClick={removeLocation}>
          Izbriši
        </Button>
      </Flex>
    </Stack>
  );
};
