import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

interface useAutoFetchPaginatedDataParams<T> {
  queryKey: (string | object)[];
  fetchFn: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => Promise<PaginatedResponse<T>>;
  pageSize?: number;
  enabled?: boolean;
}

interface useAutoFetchPaginatedDataResult<T> {
  data: T[];
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
}

export const useAutoFetchPaginatedData = <T>({
  queryKey,
  fetchFn,
  pageSize = 100,
  enabled = true,
}: useAutoFetchPaginatedDataParams<T>): useAutoFetchPaginatedDataResult<T> => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError, error } =
    useInfiniteQuery<PaginatedResponse<T>, Error>({
      queryKey,
      queryFn: ({ pageParam }) => fetchFn({ page: Number(pageParam), pageSize }),
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        if (lastPage.next) {
          const urlParams = new URLSearchParams(lastPage.next.split('?')[1]);
          return Number(urlParams.get('page'));
        }
        return undefined;
      },
      enabled,
    });

  // Accumulate all data into a single array
  const accumulatedData: T[] = data?.pages.flatMap((page) => page.results) || [];

  // Fetch the next page automatically until there are no more pages
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  return {
    data: accumulatedData,
    isLoading,
    isError,
    error: error || null,
  };
};
