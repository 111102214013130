import API from '@/api/base';
import { Member } from '@/models/Member';

interface FetchUnpaidMembershipsRequestParams extends PaginationQueryParams {
  organisationId: string;
  year: string;
  month: string;
  search?: string;
}

export const fetchUnpaidMembershipsRequest = async ({
  organisationId,
  year,
  month,
  search,
  page,
  pageSize,
}: FetchUnpaidMembershipsRequestParams): Promise<PaginatedResponse<Partial<Member>>> => {
  const url = buildQueryURL(organisationId, { month, year, search, page, pageSize });

  const { data } = await API.get(url);

  return data;
};

function buildQueryURL(
  organisationId: string,
  params: {
    year: string;
    month: string;
    search?: string;
    page?: number;
    pageSize?: number;
  }
): string {
  const queryParams = new URLSearchParams();
  queryParams.set('organisation', organisationId);

  if (params.search) {
    queryParams.set('search', params.search);
  }

  if (params.page) queryParams.set('page', params.page.toString());
  if (params.pageSize) queryParams.set('page_size', params.pageSize.toString());

  return `/memberships/unpaid/${params.year}/${params.month}/?${queryParams.toString()}`;
}
