import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { editTrainingRequest } from '@/api/requests/trainings/editTraining.request';

export const useEditTraining = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editTrainingRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['trainings', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong ...', error);
    },
  });
};
