import { Collapse, UnstyledButton, Stack } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { Route } from './sidebarMenuLinks';
import ChildLink from './SidebarLinkVariants/ChildLink';
import ParentLink from './SidebarLinkVariants/ParentLink';

interface SidebarLinksProps extends Route {
  isOpen: boolean;
  toggle: () => void;
  onToggle: () => void;
}

export function SidebarLinks({
  icon,
  label,
  links,
  href,
  isOpen,
  onToggle,
  toggle,
}: SidebarLinksProps) {
  const location = useLocation();

  const hasLinks = Array.isArray(links);

  function handleRouteChange() {
    hasLinks && onToggle();
    !hasLinks && toggle();
  }

  return (
    <>
      <UnstyledButton onClick={handleRouteChange}>
        <ParentLink
          icon={icon}
          label={label}
          href={href}
          isOpen={isOpen}
          pathname={location.pathname}
          hasLinks={hasLinks}
        />
      </UnstyledButton>

      {hasLinks && (
        <Collapse in={isOpen}>
          <Stack pl={20} gap={0} py={5}>
            {links?.map((link) => <ChildLink key={link.label} toggle={toggle} link={link} />)}
          </Stack>
        </Collapse>
      )}
    </>
  );
}
