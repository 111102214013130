import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import CoachesContainer from '@/containers/Coaches/CoachesContainer';
import PageInfoHeader from '@/components/Common/PageInfoHeader';
import { CreateCoach } from '@/containers/Coaches/CreateCoach';
import useShowModal from '@/components/Modals/showModal';
import PageWrapper from '@/components/Common/PageWrapper';

export const Coaches = () => {
  const showModal = useShowModal();

  const openModal = () =>
    showModal({
      id: 'create-coach',
      title: '💪 Novi Trener',
      description:
        'Unesite informacije za dodavanje novog trenera. Pazljivo unesite informacije jer su to podaci od korisnickog racuna sa kojim ce trener imati pristup u aplikaciji.',
      children: <CreateCoach />,
    });

  return (
    <PageWrapper>
      <PageInfoHeader
        title="💪 Treneri"
        subtitle="Kompletno upravljanje vašim trenerima na jednom mestu"
        actionButton={
          <Button
            size="md"
            fz="sm"
            leftSection={<IconPlus size={20} color="white" />}
            onClick={openModal}
          >
            Dodaj Trenera
          </Button>
        }
      />

      <CoachesContainer />
    </PageWrapper>
  );
};
