import { Container } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

export default function PageWrapper({ children }: { children: React.ReactNode }) {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const paddingTop = isMobile ? 80 : 40;

  return (
    <Container fluid px={{ base: 'md', sm: 'lg', md: 'xl' }} pb={40} pt={paddingTop} bg="#FAF9F8">
      {children}
    </Container>
  );
}
