import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMemberRequest } from '@/api/requests/members/createMember.request';
import { useAccountStore } from '@/stores/accountStore';

export const useCreateMember = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMemberRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['members', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
