import API from '@/api/base';
import { Parent } from '@/models/Parent';

interface UpdateParentChildrenParams {
  parentId: string;
  children?: string[];
}

export const updateParentChildren = async ({
  parentId,
  children,
}: UpdateParentChildrenParams): Promise<Parent> => {
  const response = await API.patch<Parent>(`/parents/${parentId}/`, {
    children,
  });

  return response.data;
};
