import { useEffect } from 'react';
import { Stack, Text } from '@mantine/core';
import useShowModal from '../Modals/showModal';
import { useAccountStore } from '@/stores/accountStore';
import { UserRole } from '@/models/User';

export default function PaymentRequiredModal() {
  const { user } = useAccountStore();
  const showModal = useShowModal();

  const isOwnerOrStaff = user?.role === UserRole.OWNER || user?.role === UserRole.STAFF;

  useEffect(() => {
    showModal({
      id: 'payment',
      title: 'Pretplata je istekla – pristup privremeno onemogućen',
      children: (
        <Stack px={20}>
          {isOwnerOrStaff ? (
            <OwnerMessage />
          ) : (
            <Text>
              Obaveštavamo Vas da je pretplata kluba/akademije istekla, zbog čega je Vaš pristup
              aplikaciji privremeno onemogućen. Kako biste nastavili da koristite sve alate za
              vođenje treninga i praćenje napretka vaših sportista, potrebno je da se obratite
              glavnom osoblju kluba/akademije kako bi izvršili uplatu na tekući račun i obnovili
              pretplatu.
            </Text>
          )}

          <Text>
            Zahvaljujemo se na razumevanju! <br /> Datasport &copy;
          </Text>
        </Stack>
      ),
      withCloseButton: false,
      closeOnClickOutside: false,
      closeOnEscape: false,
      overlayProps: {
        blur: 4,
      },
    });
  }, []);

  return null;
}

function OwnerMessage() {
  return (
    <>
      <Text>
        Nažalost, Vaša pretplata je istekla.Da biste nastavili korišćenje svih funkcionalnosti koje
        su ključne za vođenje vašeg kluba ili akademije, molimo vas da obnovite pretplatu uplatom na
        naš tekući račun:
        <Text my={10} c="teal" fw={900}>
          115-0038162230883-69
        </Text>
        Nakon što uplata bude primljena, vaš pristup će automatski biti ponovo omogućen.
      </Text>
    </>
  );
}
