import { useQuery } from '@tanstack/react-query';
import { fetchMembershipsHistoryChart } from '@/api/requests/memberships/getMembershipsHistoryChart.request';

export const useMembershipSummaryHistory = (organisationId: string) =>
  useQuery({
    queryKey: ['memberships', 'history'],
    queryFn: () => fetchMembershipsHistoryChart(organisationId),

    enabled: !!organisationId,
  });
