import { useQuery } from '@tanstack/react-query';
import { fetchGroupsRequest } from '@/api/requests/groups/getGroups.request';

interface UseGroupsQueryParams {
  organisationId: string;
  name?: string;
  coachId?: string;
}

// We use page_size 100 in fetchGroupsReqeust to get all groups for now
export const useGroups = ({ organisationId, name, coachId }: UseGroupsQueryParams) =>
  useQuery({
    queryKey: ['groups', { organisationId, name, coachId }],
    queryFn: () => fetchGroupsRequest({ organisationId, name, coachId }),
    enabled: !!organisationId,
  });
