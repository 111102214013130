import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';
import { Route } from '../sidebarMenuLinks';

export default function ChildLink({
  toggle,
  link,
}: {
  toggle: () => void;
  link: Omit<Route, 'icon'>;
}) {
  return (
    <NavLink
      className={({ isActive }) => `${styles.child} ${isActive && styles.activeChild}`}
      to={link.href as string}
      onClick={toggle}
    >
      {link.label}
    </NavLink>
  );
}
