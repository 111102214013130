import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { Button, Flex, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';
import { useUpdateOrganisationLocations } from '@/api/hooks/organisations/useUpdateOrganisationLocations';
import { OrganisationLocation } from '@/models/Organisation';

interface LocationInput {
  name: string;
}

const EditLocation = ({ location }: { location: OrganisationLocation }) => {
  const { register, handleSubmit } = useForm<LocationInput>();
  const { organisation } = useAccountStore();

  const { mutateAsync: updateLocation, isPending } = useUpdateOrganisationLocations();

  const editLocation: SubmitHandler<LocationInput> = async (data) => {
    try {
      await updateLocation({
        id: location.id,
        name: data.name,
        organisationId: organisation?.id as string,
      });

      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili lokaciju',
        id: 'edit-location',
      });

      modals.closeAll();
    } catch (error) {
      console.error('Oups, something went wrong while editing location', error);
    }
  };

  return (
    <Stack px="md" component="form" onSubmit={handleSubmit(editLocation)}>
      <LoadingOverlay visible={isPending} />

      <TextInput
        w="100%"
        placeholder="Nova lokacija"
        {...register('name', { value: location.name })}
      />

      <Flex gap={10} justify="flex-end" mt={25}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>

        <Button type="submit" leftSection={<IconEdit size={18} />}>
          Izmeni
        </Button>
      </Flex>
    </Stack>
  );
};

export default EditLocation;
