import API from '@/api/base';
import { Member } from '@/models/Member';

export interface GetParentChildRequestParams {
  parent: string;
  child: string;
}
export const getParentChild = async ({
  parent,
  child,
}: GetParentChildRequestParams): Promise<Member> => {
  const resposne = await API.get<Member>(`/parents/${parent}/children/${child}/`);

  return resposne.data;
};
