import { Anchor, Table, Title, Text, Button, Flex, Card, Checkbox } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconChevronDown, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Group } from '@/models/Group';
import useShowModal from '@/components/Modals/showModal';
import RemoveMembersFromGroup from '@/containers/Groups/Details/RemoveMemberFromGroup';

export default function GroupMembersTable({ group }: { group: Group }) {
  const showModal = useShowModal();
  const navigate = useNavigate();
  const [visibleMembers, setVisibleMembers] = useState(10);
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const members = group?.members || [];

  const openDeleteModal = (memberIds: string[]) => {
    const groupId = group.id;
    showModal({
      id: 'delete-member',
      title: 'Uklonite polaznike iz grupe',
      children: <RemoveMembersFromGroup memberIds={memberIds} groupId={groupId} />,
      size: 'md',
    });
  };

  const handleLoadMore = () => {
    setVisibleMembers((prev) => Math.min(prev + 10, members.length));
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedMemberIds(members.map((member) => member.id));
    } else {
      setSelectedMemberIds([]);
    }
  };

  const handleMemberSelect = (memberId: string) => {
    setSelectedMemberIds((prevSelected) =>
      prevSelected.includes(memberId)
        ? prevSelected.filter((id) => id !== memberId)
        : [...prevSelected, memberId]
    );
  };

  const handleRemoveSelected = () => {
    if (selectedMemberIds.length > 0) {
      openDeleteModal(selectedMemberIds);
      setSelectedMemberIds([]);
    }
  };

  const handleRowsRender = () => {
    if (members.length === 0) {
      return (
        <Text style={{ paddingTop: '20px', textAlign: 'center' }}>
          Ova grupa trenutno nema dodeljenih polaznika.
        </Text>
      );
    }

    return members.slice(0, visibleMembers).map((member) => (
      <Table.Tr key={member.id}>
        <Table.Td
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Flex align="center" gap="xs" style={{ cursor: 'pointer' }}>
            <Checkbox
              color="teal"
              radius="sm"
              checked={selectedMemberIds.includes(member.id)}
              onChange={() => handleMemberSelect(member.id)}
            />
            <Anchor
              onClick={() => navigate(`/members/${member.id}`)}
              c="gray.9"
              fw={500}
              size="md"
              underline="hover"
              ff="heading"
            >
              {member.full_name}
            </Anchor>
          </Flex>
          <IconTrash
            onClick={() => openDeleteModal([member.id])}
            style={{ cursor: 'pointer', height: '18px', color: 'red' }}
          />
        </Table.Td>
      </Table.Tr>
    ));
  };

  return (
    <Card px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent', sm: '#fff' }}>
      <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover>
        <Table.Thead bg="#F6F8FA">
          <Table.Tr style={{ borderBottom: 'none' }}>
            <Table.Th>
              <Flex align="center" gap="xs" style={{ cursor: 'pointer' }}>
                <Checkbox
                  color="teal"
                  radius="sm"
                  checked={selectedMemberIds.length === members.length && members.length > 0}
                  indeterminate={
                    selectedMemberIds.length > 0 && selectedMemberIds.length < members.length
                  }
                  onChange={(e) => handleSelectAll(e.currentTarget.checked)}
                />
                <Title fw={400} size={16} c="gray.7">
                  Ime i prezime
                </Title>
              </Flex>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{handleRowsRender()}</Table.Tbody>
      </Table>
      {selectedMemberIds.length > 0 || visibleMembers === members.length ? (
        <Flex justify="left" ml={12} w={120} mt="md">
          <Button
            color="red"
            h={40}
            variant="outline"
            onClick={handleRemoveSelected}
            leftSection={<IconTrash size={16} />}
          >
            <Text style={{ lineHeight: '1.2', fontSize: '12px' }}>
              ukloni
              <br />
              izabrane
            </Text>
          </Button>
        </Flex>
      ) : null}

      {visibleMembers < members.length && (
        <Flex justify="center" mt="md">
          <Button variant="subtle" onClick={handleLoadMore} rightSection={<IconChevronDown />}>
            Prikaži više polaznika
          </Button>
        </Flex>
      )}
    </Card>
  );
}
