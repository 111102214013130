import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  MultiSelect,
  Radio,
  SimpleGrid,
  Stack,
  TextInput,
} from '@mantine/core';
import dayjs from 'dayjs';
import { DatePickerInput } from '@mantine/dates';
import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { difference } from 'lodash';
import { useGroups } from '@/api/hooks/groups/useGroups';
import { useAccountStore } from '@/stores/accountStore';
import { Member } from '@/models/Member';
import { useEditMember } from '@/api/hooks/members/useEditMember';
import notifications from '@/utils/notifications';
import { useAddMembersToGroup } from '@/api/hooks/groups/useAddMembersToGroup';
import { useRemoveMemberFromGroup } from '@/api/hooks/groups/useRemoveMemberFromGroup';

export type EditMemberForm = {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: Date;
  phoneNumber: string;
  address: string;
  membershipPrice: string;
};

export default function EditMember({ member }: { member: Member }) {
  const { organisation } = useAccountStore();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<EditMemberForm>();

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGroups({ organisationId: organisation?.id as string });

  const { mutateAsync: editMember, isPending: isEditingMember } = useEditMember();
  const { mutateAsync: removeMemberFromGroup, isPending: isRemovingMember } =
    useRemoveMemberFromGroup();
  const { mutateAsync: addMembersToGroup, isPending: isAddingMember } = useAddMembersToGroup();

  const defaultGroups = member.groups.map((group) => group.id);

  const [selectedGroups, setSelectedGroups] = useState<string[]>(defaultGroups);

  const onUpdateMemberSubmit: SubmitHandler<EditMemberForm> = async (data) => {
    try {
      // Check if any of member data is changed - if changed send request for update
      if (isDirty) {
        await editMember({
          ...data,
          id: member.id,
          birthDate: dayjs(data.birthDate).format('YYYY-MM-DD'),
        });
      }

      const newGroups = difference(selectedGroups, defaultGroups);
      const oldGroups = difference(defaultGroups, selectedGroups);

      if (newGroups.length > 0) {
        await Promise.all(
          newGroups.map((newGroup) =>
            addMembersToGroup({ groupId: newGroup, memberIds: [member.id] })
          )
        );
      }

      if (oldGroups.length > 0) {
        await Promise.all(
          oldGroups.map((oldGroup) =>
            removeMemberFromGroup({ groupId: oldGroup, memberId: member.id })
          )
        );
      }

      modals.closeAll();
      notifications.showSuccessNotification({
        title: 'Izmenili ste polaznika!',
        message: 'Čestitke, uspešno ste izmenili polaznika',
      });
    } catch (e) {
      console.error('Error while editing member', e);
    }
  };

  return (
    <Stack pos="relative">
      <Container w="100%">
        <LoadingOverlay visible={isEditingMember || isAddingMember || isRemovingMember} />
        <Divider />

        <Box component="form" mt={20} onSubmit={handleSubmit(onUpdateMemberSubmit)}>
          <SimpleGrid w="100%" cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'lg' }}>
            <TextInput
              label="Ime"
              placeholder="Ime"
              {...register('firstName', {
                value: member.first_name,
                required: '* Ime polaznika je obavezno',
              })}
              error={errors.firstName?.message}
            />

            <TextInput
              label="Prezime"
              placeholder="Prezime"
              {...register('lastName', {
                value: member.last_name,
                required: '* Prezime polaznika je obavezno',
              })}
              error={errors.lastName?.message}
            />

            <Controller
              name="birthDate"
              control={control}
              rules={{
                required: '* Datum rodjenja polaznika je obavezan',
              }}
              defaultValue={new Date(member.birth_date)}
              render={({ field }) => (
                <DatePickerInput
                  required
                  maxDate={new Date()}
                  label="Datum rodjenja"
                  placeholder="Datum rodjenja"
                  defaultValue={new Date(member.birth_date)}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={errors.birthDate?.message}
                />
              )}
            />

            <Controller
              name="gender"
              control={control}
              rules={{ required: '* Pol polaznika je obavezan' }}
              defaultValue={member.gender}
              render={({ field }) => (
                <Radio.Group
                  required
                  label="Pol"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={errors.gender?.message}
                >
                  <Group mt="xs">
                    <Radio value="MALE" label="Muško" />
                    <Radio value="FEMALE" label="Žensko" />
                  </Group>
                </Radio.Group>
              )}
            />

            <TextInput
              label="Broj telefona"
              placeholder="+381"
              type="number"
              defaultValue={member.phone_number}
              {...register('phoneNumber')}
            />

            <TextInput
              label="Adresa stanovanja"
              placeholder="Adresa stanovanja"
              defaultValue={member.address}
              {...register('address')}
            />

            <TextInput
              required
              type="number"
              placeholder="3000"
              label="Cena članarine"
              description="Iznos mesečne članarine koju ovaj polaznik plaća"
              leftSection={organisation?.currency}
              leftSectionWidth={52}
              {...register('membershipPrice', {
                value: member.membership_price,
                required: '* Cena članarine je obavezno',
              })}
            />
            <MultiSelect
              label="Grupe"
              description="Grupe sa kojom ce ovaj polaznik trenirati"
              placeholder={selectedGroups.length > 0 ? '' : 'Pretraži grupe'}
              data={groups?.results.map((group) => ({
                label: group.name,
                value: group.id,
              }))}
              value={selectedGroups}
              onChange={(value) => setSelectedGroups(value)}
              clearable
              disabled={isGroupsLoading || !!groupsError}
            />
          </SimpleGrid>

          <Flex gap={10} justify="flex-end" mt={25}>
            <Button variant="default" onClick={() => modals.closeAll()}>
              Odustani
            </Button>
            <Button type="submit" leftSection={<IconEdit size={18} />}>
              Izmeni
            </Button>
          </Flex>
        </Box>
      </Container>
    </Stack>
  );
}
