import { Stack, Title, Text, Divider, SimpleGrid, Anchor, Button } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Group } from '@/models/Group';
import IsGranted from '@/components/Common/IsGranted';
import useShowModal from '@/components/Modals/showModal';
import EditGroup from './EditGroup';
import { Permission } from '@/models/Permission';

interface GroupDetailsProps {
  group: Group;
  onCancel: () => void;
}

export default function GroupDetails({ group, onCancel }: GroupDetailsProps) {
  const showModal = useShowModal();
  const navigate = useNavigate();

  const editGroupModal = () => {
    showModal({
      id: 'edit-group',
      title: 'Izmeni grupu',
      description: 'Izmenite postojecu grupu',
      children: <EditGroup group={group} closeDrawer={onCancel} />,
    });
  };

  const hasCoaches = group.coaches.length > 0;
  const hasMembers = group.members.length > 0;

  return (
    <Stack mt={10}>
      <Stack py="sm" gap={5}>
        <Text c="dimmed" size="xs" style={{ textTransform: 'uppercase' }}>
          Ime grupe
        </Text>
        <Title size={18} fw={500} c="gray.8">
          {group.name}
        </Title>

        <Divider mt={10} />
      </Stack>

      <Stack gap={5}>
        <Text c="dimmed" size="xs" style={{ textTransform: 'uppercase' }}>
          Broj clanova
        </Text>

        <Title size={18} fw={500} c="gray.8">
          {group.members.length}
        </Title>

        <Divider mt={10} />
      </Stack>

      <Stack gap={5} mt={10}>
        <Text c="dimmed" size="xs" style={{ textTransform: 'uppercase' }}>
          Treneri
        </Text>
        <SimpleGrid cols={2} spacing="sm" verticalSpacing="xs">
          {!hasCoaches && (
            <Title size={16} fw={500} c="gray.8">
              Ova grupa još uvek nema dodeljene trenere
            </Title>
          )}

          {hasCoaches &&
            group.coaches.map((coach) => (
              <Anchor key={coach.id} fw={500} size="md" c="gray.8" underline="hover">
                {coach.full_name}
              </Anchor>
            ))}
        </SimpleGrid>

        <Divider mt={10} />
      </Stack>

      <Stack gap={5} mt={10} pb={100}>
        <Text c="dimmed" size="xs" style={{ textTransform: 'uppercase' }}>
          Clanovi
        </Text>
        <SimpleGrid cols={2} spacing="sm" verticalSpacing="xs">
          {!hasMembers && (
            <Title size={16} fw={500} c="gray.8">
              Ova grupa još uvek nema dodeljene članove
            </Title>
          )}

          {hasMembers &&
            group.members.map((member) => (
              <Anchor
                key={member.id}
                c="gray.8"
                underline="hover"
                onClick={() => navigate(`/members/${member.id}`)}
              >
                {member.full_name}
              </Anchor>
            ))}
        </SimpleGrid>
      </Stack>

      <Divider py={10} />
      <SimpleGrid cols={2} verticalSpacing="xs">
        <Button variant="default" onClick={onCancel}>
          Zatvori
        </Button>

        <IsGranted permissions={Permission.GROUP_MODIFY_VIEW}>
          <Button leftSection={<IconEdit size={18} />} fullWidth onClick={editGroupModal}>
            Izmeni grupu
          </Button>
        </IsGranted>
      </SimpleGrid>
    </Stack>
  );
}
