import { Stack } from '@mantine/core';
import OrganisationsUpdateForm from '@/components/Settings/OrganisationsUpdateForm';
import OrganisationConfigurationForm from '@/components/Settings/OrganisationConfigurationForm';
import OrganisationLocations from '@/components/Settings/OrganisationLocations/OrganisationLocations';

export default function OrganisationSettings() {
  return (
    <Stack>
      <OrganisationsUpdateForm />
      <OrganisationLocations />
      <OrganisationConfigurationForm />
    </Stack>
  );
}
