import API from '@/api/base';
import { OrganisationLocation } from '@/models/Organisation';

interface AddOrganisationLocationRequestBody {
  organisationId: string;
  name: string;
}

export const addOrganisationLocation = async ({
  name,
  organisationId,
}: AddOrganisationLocationRequestBody): Promise<OrganisationLocation> => {
  const response = await API.post(`organisations/${organisationId}/locations/`, {
    name,
  });

  return response.data;
};
