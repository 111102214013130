import { Button, Flex, Popover, rem } from '@mantine/core';
import { IconCalendar, IconCheck, IconFilter } from '@tabler/icons-react';
import React, { useState } from 'react';
import { MonthPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { MembershipFilters } from '@/containers/Memberships/UnpaidMemberships';
import { useAccountStore } from '@/stores/accountStore';

interface UnpaidMembershipsPopoverDateFiltersProps {
  membershipFilters: MembershipFilters;
  setMembershipFilters: React.Dispatch<React.SetStateAction<MembershipFilters>>;
}

export default function UnpaidMembershipsPopoverFilters({
  membershipFilters,
  setMembershipFilters,
}: UnpaidMembershipsPopoverDateFiltersProps) {
  const { organisation } = useAccountStore();
  const [localFilter, setLocalFilter] = useState({ ...membershipFilters });
  const [opened, setOpened] = useState(false);

  const applyFilters = () => {
    setMembershipFilters(localFilter);
    setOpened(false);
  };

  const clearFilters = () => {
    setMembershipFilters({ date: '' });
    setLocalFilter({ date: '' });
    setOpened(false);
  };

  const calendarIcon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((prev) => !prev)}
          size="sm"
          variant="outline"
          color="gray"
          bg="white"
          style={{ borderColor: '#CED4DA' }}
        >
          <IconFilter style={{ width: rem(18), height: rem(18) }} />

          {membershipFilters.date ? (
            <IconCheck color="lightgreen" style={{ width: rem(20), height: rem(20) }} />
          ) : null}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <MonthPickerInput
          maxDate={new Date()}
          minDate={organisation?.created_at}
          leftSection={calendarIcon}
          leftSectionPointerEvents="none"
          label="Neplaćena članarina za mesec"
          placeholder="Odaberite mesec"
          mt="md"
          popoverProps={{ withinPortal: false }}
          value={localFilter.date ? new Date(localFilter.date) : new Date()}
          onChange={(value) =>
            setLocalFilter({ ...localFilter, date: dayjs(value).format('YYYY-MM-DD') })
          }
        />

        <Flex justify="space-between">
          <Button w="49%" mt={20} px={0} onClick={clearFilters} variant="outline">
            Poništi filtere
          </Button>
          <Button w="49%" mt={20} px={0} onClick={applyFilters} disabled={!localFilter.date}>
            Primeni filtere
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
}
