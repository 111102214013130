import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrganisationLocation } from '@/api/requests/organisations/addOrganisationLocation.request';

export const useAddOrganisationLocations = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addOrganisationLocation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['organisation'],
      }),
    onError: (error) => {
      console.error('There is error while creating location', error);
    },
    retry: 1,
  });
};
