import { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { UserRole } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';
import { useGroups } from '@/api/hooks/groups/useGroups';
import { Group } from '@/models/Group';
import { Member } from '@/models/Member';
import { useCreateTraining } from '@/api/hooks/trainings/useCreateTraining';
import notifications from '@/utils/notifications';
import { useCoachByUser } from '@/api/hooks/coaches/useCoachByUser';
import { useAddTrainingAttendance } from '@/api/hooks/trainings/useTrainingAttendance';
import useIsMobile from '@/hooks/useIsMobile';

type Inputs = {
  topic: string;
  groupId: string;
  locationId: string;
  coachIds: string[];
};

interface SelectedMembers {
  member_id: string;
  is_present: boolean;
}

const CreateTraining = () => {
  const isMobile = useIsMobile();
  const { organisation, user } = useAccountStore();

  const { data: currentCoach } = useCoachByUser(user?.id as string, {
    enabled: user?.role === UserRole.COACH,
  });

  const { data: groups } = useGroups({
    organisationId: organisation?.id as string,
    coachId: currentCoach?.id,
  });

  const { mutateAsync: createTraining, isPending: isTrainingCreation } = useCreateTraining();
  const { mutateAsync: addTrainingAttendeces, isPending: isAddingTrainingAttendances } =
    useAddTrainingAttendance();

  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedMembers, setSelectedMembers] = useState<SelectedMembers[]>([]);
  const [selectedCoaches, setSelectedCoaches] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log('DATA', data);

    try {
      const newTraining = await createTraining({
        group_id: data.groupId,
        coach_ids: data.coachIds,
        topic: data.topic,
        location_id: data.locationId,
      });

      if (newTraining) {
        await addTrainingAttendeces({
          trainingId: newTraining.id,
          members: selectedMembers,
        });

        modals.closeAll();
        notifications.showSuccessNotification({
          title: 'Trening je uspešno započet',
          message: 'Članovi su uspešno dodati na trening',
        });
      }
    } catch (err) {
      console.error('Error while creating new training', err);
    }
  };

  const handleMemberSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    member: Partial<Member>
  ) => {
    const updatedMembers = selectedMembers.filter(
      (selectedMember) => selectedMember.member_id !== member.id
    );
    if (event.currentTarget.checked) {
      updatedMembers.push({ member_id: member.id as string, is_present: true });
    }
    setSelectedMembers(updatedMembers);
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={isTrainingCreation || isAddingTrainingAttendances}
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Divider />
      <TextInput
        label="Tema treninga"
        placeholder="Tema treninga"
        type="text"
        w={{ base: '100%', sm: '45%' }}
        {...register('topic')}
      />
      <Title style={{ fontSize: 18, color: 'gray' }}>
        Odaberi grupu i članove koji će biti deo treninga:
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Flex
            w="100%"
            justify="space-between"
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 20, sm: 10 }}
            wrap="wrap"
          >
            <Stack w={{ base: '100%', sm: '49%' }}>
              <Controller
                name="groupId"
                rules={{ required: 'Molimo Vas odaberite grupu koju trenirate' }}
                control={control}
                render={({ field }) => (
                  <Select
                    label="Grupa"
                    placeholder="Izaberite grupu"
                    data={groups?.results?.map((group) => ({
                      value: group.id,
                      label: group.name,
                    }))}
                    value={field.value}
                    onChange={(groupId) => {
                      field.onChange(groupId);
                      setSelectedGroup(groups?.results?.find((group) => group.id === groupId));
                      setSelectedCoaches([currentCoach?.id as string]);
                    }}
                    onBlur={field.onBlur}
                    error={errors.groupId?.message}
                    required
                  />
                )}
              />
            </Stack>

            <Stack w={{ base: '100%', sm: '49%' }}>
              <Controller
                name="locationId"
                rules={{ required: 'Molimo Vas odaberite lokaciju na kojoj se održava trening.' }}
                control={control}
                render={({ field }) => (
                  <Select
                    label="Lokacija"
                    placeholder="Odaberite lokaciju"
                    data={organisation?.locations?.map((location) => ({
                      value: location.id,
                      label: location.name,
                    }))}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={errors.locationId?.message}
                    required
                  />
                )}
              />
            </Stack>
            {selectedGroup && selectedGroup.coaches.length > 0 && (
              <Stack w={{ base: '100%', sm: '49%' }}>
                <Controller
                  name="coachIds"
                  rules={{ required: 'Molimo vas izaberite trenere za grupu' }}
                  control={control}
                  defaultValue={selectedCoaches}
                  render={({ field }) => (
                    <MultiSelect
                      searchable
                      label="Treneri"
                      placeholder={selectedGroup.coaches.length > 0 ? '' : 'Pretraži trenere'}
                      data={selectedGroup.coaches.map((coach) => ({
                        value: coach.id,
                        label: coach.full_name,
                      }))}
                      value={field.value}
                      onChange={field.onChange}
                      nothingFoundMessage="Nema trenera za vašu pretragu..."
                      hidePickedOptions
                    />
                  )}
                />
              </Stack>
            )}
          </Flex>
          <Paper mt="xl">
            {!selectedGroup ? (
              <Title style={{ fontSize: 14, color: 'gray' }}>
                Prvo izaberite grupu kako biste videli članove
              </Title>
            ) : selectedGroup.members.length === 0 ? (
              <Stack gap={0}>
                <Title style={{ fontSize: 18, color: 'gray' }}>
                  Grupa koju ste izabrali nema članova
                </Title>
                <Text c="red" size="xs">
                  Ne možete započeti trening sa grupom koja nema članova
                </Text>
              </Stack>
            ) : (
              <Stack>
                <Title style={{ fontSize: 18, color: 'gray' }}>
                  Izaberite članove koji počinju trening
                </Title>
                <SimpleGrid cols={{ base: 1, sm: 2 }} my="sm">
                  {selectedGroup.members.map((member) => (
                    <Switch
                      key={member.id}
                      onChange={(event) => handleMemberSelect(event, member)}
                      label={member.full_name}
                      color="teal"
                      size={isMobile ? 'lg' : 'md'}
                    />
                  ))}
                </SimpleGrid>
              </Stack>
            )}
          </Paper>
        </Paper>

        <Flex gap={10} justify="flex-end" mt={15}>
          <Button variant="default" onClick={() => modals.closeAll()}>
            Odustani
          </Button>
          <Button type="submit" leftSection={<IconPlus size={18} />}>
            Započni
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export default CreateTraining;
