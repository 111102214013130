import { Stack } from '@mantine/core';
import { useAccountStore } from '@/stores/accountStore';
import { UserRole } from '@/models/User';
import Greeting from '@/components/Dashboard/Greeting';
import CoachDashboardView from '@/containers/Dashboard/CoachView/CoachDashboardDashboardView';
import OrganisationOwnerDashboardView from '@/containers/Dashboard/OrganisationOwnerView/OrganisationOwnerDashboardView';
import ParentDashboardView from '@/containers/Dashboard/ParentsView/ParentDashboardView';
import PageWrapper from '@/components/Common/PageWrapper';

export function HomePage() {
  const { user } = useAccountStore();

  let content;
  switch (user?.role) {
    case UserRole.OWNER:
      content = <OrganisationOwnerDashboardView />;
      break;

    case UserRole.COACH:
      content = <CoachDashboardView />;
      break;

    case UserRole.PARENT:
      content = <ParentDashboardView />;
      break;

    default:
      content = <OrganisationOwnerDashboardView />;
      break;
  }

  return (
    <PageWrapper>
      <Stack>
        <Greeting />
        {content}
      </Stack>
    </PageWrapper>
  );
}
