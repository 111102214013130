import { Flex, Text } from '@mantine/core';
import React from 'react';

export default function ParentInfoItem({ label, value }: { label: string; value: string }) {
  return (
    <Flex justify="space-between">
      <Text ff="heading" fw={600} c="gray.9">
        {label}
      </Text>
      <Text size="sm" c="gray.8" ff="monospace">
        {value}
      </Text>
    </Flex>
  );
}
