import { User } from '@/models/User';
import API from '@/api/base';

interface UpdateMeRequestBody {
  first_name: string;
  last_name: string;
  phone_number: string;
}

export const updateMe = async (body: UpdateMeRequestBody): Promise<User> => {
  const response = await API.patch('auth/users/me/', body);

  return response.data;
};
