import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Select, Stack, TextInput, Title } from '@mantine/core';
import { useUpdateOrganisation } from '@/api/hooks/organisations/useUpdateOrganisation';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';

interface OrganisationSettingsInputs {
  name: string;
  currency: string;
}

export default function OrganisationsUpdateForm() {
  const { organisation } = useAccountStore();
  const { mutateAsync: updateOrganisation, isPending: loading } = useUpdateOrganisation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm<OrganisationSettingsInputs>();

  const updateUserSettings: SubmitHandler<OrganisationSettingsInputs> = async (data) => {
    try {
      await updateOrganisation({
        ...data,
        organisationId: organisation?.id as string,
      });

      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili podatke',
        message: 'Čestitamo, uspešno ste izmenili podatke organizacije',
      });
      reset(data);
    } catch (error) {
      console.error('Oops, something went wrong', error);
    }
  };
  return (
    <Stack
      mt={40}
      p="xl"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
      }}
      gap="md"
      component="form"
      onSubmit={handleSubmit(updateUserSettings)}
    >
      <Title size={20}>Osnovne informacije</Title>
      <TextInput
        label="Ime"
        placeholder="Ime"
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        error={errors.name?.message}
        {...register('name', {
          value: organisation?.name,
          required: 'Ne možete izbrisati ime organizacije',
        })}
      />

      <Controller
        name="currency"
        control={control}
        defaultValue={organisation?.currency}
        render={({ field }) => (
          <Select
            w={{ base: '100%', xs: '50%', sm: '35%' }}
            label="Valuta"
            description="Valuta u kojoj se naplaćuju članarine"
            placeholder="Izaberite valutu za organizaciju"
            data={['RSD']}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            allowDeselect={false}
            disabled={loading}
          />
        )}
      />
      <Button
        w={{ base: '100%', xs: '40%', sm: '15%' }}
        my={10}
        type="submit"
        name="userSettings"
        disabled={!isDirty || loading}
      >
        Sačuvaj izmene
      </Button>
    </Stack>
  );
}
