import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateParentChildren } from '@/api/requests/parents/updateParentChildren.request';

export const useUpdateParentChildren = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateParentChildren,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      });
    },

    onError: (error) => {
      console.error('Error while updating a children for parent account', error);
    },
  });
};
