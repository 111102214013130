import { Member } from './Member';

export enum UserRole {
  COACH = 'COACH',
  OWNER = 'OWNER',
  STAFF = 'STAFF',
  PARENT = 'PARENT',
}

export type User = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  bio?: string;
  birth_date?: Date;
  role: UserRole;
  organisation: string; // Organisation ID
  phone_number?: string;
  image_url: string;
  last_login: Date;
  created_at: Date;
  modified_at: Date;
};

export function hasRole(user: User, roles: UserRole | UserRole[], forceCheck = false): boolean {
  /* Owner can see eveything */
  if (user.role === UserRole.OWNER && !forceCheck) return true;

  if (Array.isArray(roles)) {
    return roles.includes(user.role);
  }

  return user.role === roles;
}

export const hasAnyRole = (user: User, roles: Array<UserRole>) =>
  roles.some((role) => hasRole(user, role));

export function getUserInitials(user: User): string {
  const firstNameInitial = user?.first_name ? user.first_name.charAt(0).toUpperCase() : '';
  const lastNameInitial = user?.last_name ? user.last_name.charAt(0).toUpperCase() : '';
  return `${firstNameInitial}${lastNameInitial}`;
}

export function getUserFullName(user: User | Member): string {
  return `${user?.first_name} ${user?.last_name}`;
}
