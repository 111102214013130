import { create } from 'zustand';
import { storageService } from '../services/storageService';
import { User } from '@/models/User';
import { Organisation } from '@/models/Organisation';
import queryClient from '@/api/queryClient';

export interface AccountStoreState {
  user: User | null;
  organisation: Organisation | null;
  accessToken: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  setAccessToken: (accessToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  setUser: (user: User) => void;
  setOrganisation: (organisation: Organisation) => void;
  forget: () => void;
}

export const useAccountStore = create<AccountStoreState>((set) => ({
  user: null,
  organisation: null,
  isLoggedIn: false,
  refreshToken: storageService.getRefreshToken(),
  accessToken: storageService.getAccessToken(),

  setAccessToken(accessToken: string) {
    storageService.setAccessToken(accessToken);
    set({ accessToken });
  },

  setRefreshToken(refreshToken: string) {
    storageService.setRefreshToken(refreshToken);
    set({ refreshToken });
  },

  setUser(user: User) {
    set({ user, isLoggedIn: true });
  },

  setOrganisation(organisation: Organisation) {
    set({ organisation });
  },

  forget() {
    storageService.clearTokens();
    queryClient.resetQueries();

    set({
      accessToken: null,
      refreshToken: null,
      isLoggedIn: false,
      user: null,
      organisation: null,
    });
  },
}));
