import { Group, Paper, Text, ThemeIcon } from '@mantine/core';
import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons-react';

interface Props {
  trainingsMoMStats: {
    current_month: number;
    mom_change: number;
    mom_change_percentage: string;
    previous_month: number;
    trend: string;
  };
}

export default function TrainingsMoMAnalytics({ trainingsMoMStats }: Props) {
  const data = trainingsMoMStats;
  const DiffIcon = data.mom_change > 0 ? IconArrowUpRight : IconArrowDownRight;
  return (
    <Paper p="md">
      <Group justify="apart">
        <div>
          <Text c="dimmed" tt="uppercase" fw={700} fz="xs">
            Treninzi
          </Text>
          <Text c="dimmed" fz="sm" mt="md">
            Broj treninga tekućeg meseca
          </Text>
          <Text fw={700} fz="xl">
            {data.current_month}
          </Text>
        </div>

        <ThemeIcon
          ml="auto"
          color="gray"
          variant="light"
          style={{
            color:
              data.mom_change > 0 ? 'var(--mantine-color-teal-6)' : 'var(--mantine-color-red-6)',
          }}
          size={38}
        >
          <DiffIcon size="1.8rem" stroke={1.5} />
        </ThemeIcon>
      </Group>
      <Text c="dimmed" fz="sm" mt="md">
        Ukupan broj treninga prethodnog meseca
      </Text>
      <Text fw={700} fz="xl">
        {data.previous_month}
      </Text>
      <Text c="dimmed" fz="sm" mt="md">
        <Text component="span" c={data.mom_change > 0 ? 'teal' : 'red'} fw={700}>
          {data.mom_change_percentage}
        </Text>
        {data.mom_change > 0 ? ' više' : ' manje'} u poređenju sa prošlim mesecom
      </Text>
    </Paper>
  );
}
