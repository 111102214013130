import { Card, Flex, Stack, Text } from '@mantine/core';
import { Group } from '@/models/Group';
import useShowModal from '../Modals/showModal';
import DeleteGroup from '@/containers/Groups/DeleteGroup';
import TableActionsMenu from '../Common/Table/TableActionMenu';

interface GroupsMobileTableProps {
  data: Group[];
  onRowClick: () => void;
  setGroupDetails: React.Dispatch<React.SetStateAction<Group | undefined>>;
}

export default function GroupsMobileTable({
  data,
  onRowClick,
  setGroupDetails,
}: GroupsMobileTableProps) {
  const showModal = useShowModal();

  function handleRowClick(group: Group) {
    setGroupDetails(group);
    onRowClick();
  }

  const openDeleteModal = (groupId: string) => {
    showModal({
      id: 'delete-group',
      title: 'Izbriši grupu',
      children: <DeleteGroup groupId={groupId} />,
      size: 'md',
    });
  };
  const handleCardRender = () => {
    if (data.length === 0) return <Text>Nema podataka...</Text>;

    return data.map((group) => (
      <Card key={group.id}>
        <Stack gap={10}>
          <Flex justify="flex-end" bg="#f5f5f5" style={{ borderRadius: 10 }}>
            <TableActionsMenu optionsHandler={() => openDeleteModal(group.id)} />
          </Flex>
          <Flex justify="space-between" onClick={() => handleRowClick(group)}>
            <Text size="md" fw={600} c="#555">
              Ime grupe
            </Text>
            <Text size="md" fw={600} c="#777">
              {group.name}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Broj članova
            </Text>
            <Text size="md" c="#777">
              {group.members.length}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="md" fw={600} c="#555">
              Treneri
            </Text>
            <Text size="md" c="#777">
              {group.coaches.map((el) => el.full_name).join(', ')}
            </Text>
          </Flex>
        </Stack>
      </Card>
    ));
  };

  return (
    <Stack px={0} gap={10}>
      {handleCardRender()}
    </Stack>
  );
}
