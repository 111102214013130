import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Member } from '@/models/Member';
import notifications from '@/utils/notifications';
import { useEditMemberAdditionalOptions } from '@/api/hooks/members/useEditMemberAdditionalOptions';
import { useAccountStore } from '@/stores/accountStore';

interface MemberAdditonalOptionsInputs {
  has_jersey: boolean;
  has_tracksuit: boolean;
  has_medical_certificate: boolean;
  previous_debt: string;
}

export default function MemberAdditonalOptionsForm({ member }: { member: Member }) {
  const { organisation } = useAccountStore();
  const { mutateAsync: editMemberAdditionalInfo, isPending } = useEditMemberAdditionalOptions();

  const { control, register, handleSubmit } = useForm<MemberAdditonalOptionsInputs>({
    defaultValues: {
      has_jersey: member.has_jersey,
      has_tracksuit: member.has_tracksuit,
      has_medical_certificate: member.has_medical_certificate,
      previous_debt: member.previous_debt,
    },
  });

  const handleAdditonalOptionsSubmit: SubmitHandler<MemberAdditonalOptionsInputs> = async (
    data
  ) => {
    try {
      await editMemberAdditionalInfo({
        member_id: member.id,
        has_jersey: data.has_jersey,
        has_tracksuit: data.has_tracksuit,
        has_medical_certificate: data.has_medical_certificate,
        previous_debt: data.previous_debt,
      });

      modals.closeAll();
      notifications.showSuccessNotification({
        title: 'Dodatne opcije uspešno izmenjene!',
        message: 'Čestitke, uspešno ste izmenili dodatne opcije',
      });
    } catch (err) {
      console.error('Error while editing member additonal info', err);
    }
  };

  return (
    <Stack pos="relative">
      <Container w="100%">
        <LoadingOverlay visible={isPending} />

        <Divider />

        <Box component="form" mt={20} onSubmit={handleSubmit(handleAdditonalOptionsSubmit)}>
          <SimpleGrid w="100%" cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'lg' }}>
            <Controller
              name="has_jersey"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch label="Dres" checked={value} onChange={(e) => onChange(e.target.checked)} />
              )}
            />
            <Controller
              name="has_tracksuit"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  label="Trenerka"
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
            <Controller
              name="has_medical_certificate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  label="Lekarsko uverenje"
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          </SimpleGrid>
          <TextInput
            mt={20}
            type="number"
            placeholder="3000"
            label="Prethodno dugovanje"
            leftSection={organisation?.currency}
            leftSectionWidth={52}
            {...register('previous_debt')}
          />
          <Flex gap={10} justify="flex-end" mt={25}>
            <Button variant="default" onClick={() => modals.closeAll()}>
              Odustani
            </Button>
            <Button type="submit">Sacuvaj</Button>
          </Flex>
        </Box>
      </Container>
    </Stack>
  );
}
