import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { addCoachesToGroupRequest } from '@/api/requests/groups/addCoachesToGroup.request';

export const useAddCoachesToGroup = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCoachesToGroupRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
