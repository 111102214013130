import API from '@/api/base';

interface AddTrainingAttendanceRequestBody {
  trainingId: string;
  members: { member_id: string; is_present: boolean }[];
  note?: string;
}

export const addTrainingAttendance = async ({
  trainingId,
  members,
}: AddTrainingAttendanceRequestBody): Promise<void> => {
  const { data } = await API.post<void>(`/trainings/${trainingId}/attendance/`, {
    attendances: members,
  });

  return data;
};
