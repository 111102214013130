import { Coach } from '@/models/Coach';
import API from '@/api/base';

export const fetchCoachByUser = async (userId: string): Promise<Coach> => {
  const url = `/coaches/user/${userId}/`;

  const { data } = await API.get<Coach>(url);

  return data;
};
