import { Button, Stack, TextInput, Title } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAccountStore } from '@/stores/accountStore';
import { useUpdateMe } from '@/api/hooks/auth/useUpdateMe';
import notifications from '@/utils/notifications';

interface UserSettingsInputs {
  first_name: string;
  last_name: string;
  phone_number: string;
}

export default function UserUpdateForm() {
  const { user } = useAccountStore();
  const { mutateAsync: updateUser } = useUpdateMe();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UserSettingsInputs>();

  const userUpdateSettings: SubmitHandler<UserSettingsInputs> = async (data) => {
    try {
      await updateUser(data);
      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili podatke',
        message: 'Čestitamo, uspešno ste izmenili lične podatke',
      });
      reset(data);
    } catch (error) {
      console.error('Oops, something went wrong', error);
    }
  };

  return (
    <Stack
      mt={40}
      p="xl"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
      }}
      gap="md"
      component="form"
      onSubmit={handleSubmit(userUpdateSettings)}
    >
      <Title size={20}>Lični podaci</Title>
      <TextInput
        label="Ime"
        placeholder="Ime"
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        error={errors.first_name?.message}
        {...register('first_name', {
          value: user?.first_name,
          required: 'Ne možete izbrisati ime',
        })}
      />
      <TextInput
        label="Prezime"
        placeholder="Prezime"
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        error={errors.last_name?.message}
        {...register('last_name', {
          value: user?.last_name,
          required: 'Ne možete izbrisati prezime',
        })}
      />
      <TextInput
        label="Broj telefona"
        placeholder="Broj telefona"
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        error={errors.phone_number?.message}
        {...register('phone_number', {
          value: user?.phone_number,
          required: 'Ne možete izbrisati broj telefona',
        })}
      />

      <Button
        w={{ base: '100%', xs: '40%', sm: '15%' }}
        my={10}
        type="submit"
        name="userSettings"
        disabled={!isDirty}
      >
        Sačuvaj izmene
      </Button>
    </Stack>
  );
}
