import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  PasswordInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCreateCoach } from '@/api/hooks/coaches/useCreateCoach';
import notifications from '@/utils/notifications';

type Inputs = {
  firstName: string;
  lastName: string;
  phoneNumber: number;
  email: string;
  password: string;
};

export const CreateCoach = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const { mutateAsync: createCoach, isPending } = useCreateCoach();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await createCoach({
        user: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          password: data.password,
          phone_number: data.phoneNumber.toString(),
        },
      });

      if (response) {
        notifications.showSuccessNotification({
          title: 'Trener uspesno kreiran',
          message: 'Cestitamo, uspesno ste kreirali trenera',
        });

        modals.closeAll();
      }
    } catch (err) {
      console.error('Login failed', err);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack px="md">
        <LoadingOverlay visible={isPending} />

        <Divider />
        <Flex w="100%" gap={20}>
          <TextInput
            label="Ime"
            placeholder="Ime"
            type="text"
            error={errors.firstName?.message}
            w="100%"
            {...register('firstName', { required: 'Ime je obavezno' })}
          />
          <TextInput
            label="Prezime"
            placeholder="Prezime"
            type="text"
            error={errors.lastName?.message}
            w="100%"
            {...register('lastName', { required: 'Prezime je obavezno' })}
          />
        </Flex>
        <TextInput
          label="Telefon"
          placeholder="+381"
          type="number"
          error={errors.phoneNumber?.message}
          {...register('phoneNumber', {
            required: 'Broj telefona je obavezan',
            minLength: 6,
            valueAsNumber: true,
          })}
        />
        <TextInput
          label="Email"
          placeholder="email@mail.com"
          type="email"
          error={errors.email?.message}
          {...register('email', { required: 'Email je obavezan' })}
        />
        <PasswordInput
          label="Sifra"
          placeholder="******"
          error={errors.password?.message}
          {...register('password', { required: 'Sifra je obavezna' })}
        />

        <Flex gap={10} justify="flex-end" mt={15}>
          <Button variant="default" onClick={() => modals.closeAll()}>
            Odustani
          </Button>
          <Button leftSection={<IconPlus size={18} />} type="submit">
            Dodaj
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};
