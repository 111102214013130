import { Title, Text, Button, Container, Group } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import classes from './NotFound.module.css';

export function NotFoundPage() {
  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>Našli ste tajno mesto.</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Nažalost, ovo je samo 404 stranica. Možda ste pogrešno upisali adresu ili je stranica
        premeštena na drugi URL.
      </Text>
      <Group justify="center">
        <NavLink to="/">
          <Button variant="subtle" size="md">
            Vrati me na početnu stranicu
          </Button>
        </NavLink>
      </Group>
    </Container>
  );
}
