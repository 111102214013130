import { fetchMembersRequest } from '@/api/requests/members/getMembers.request';
import { useAutoFetchPaginatedData } from '@/hooks/useAutoFetchPaginatedData';
import { Member } from '@/models/Member';

export const useAutoFetchMembers = (organisationId: string, options?: { enabled?: boolean }) => {
  const { enabled = true } = options || {};

  return useAutoFetchPaginatedData<Member>({
    queryKey: ['members', { organisationId }],
    fetchFn: ({ page, pageSize }) => fetchMembersRequest({ organisationId, page, pageSize }),
    enabled,
  });
};
