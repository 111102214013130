import API from '@/api/base';
import { Group } from '@/models/Group';

interface RemoveCoachFromGroupRequestBody {
  groupId: string;
  coachId: string;
}

export const removeCoachFromGroupRequest = async ({
  groupId,
  coachId,
}: RemoveCoachFromGroupRequestBody): Promise<Group> => {
  const requestBody = { coach_id: coachId };

  const { data } = await API.post<Group>(`/groups/${groupId}/remove-coach/`, requestBody);

  return data;
};
