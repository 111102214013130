import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Flex, Popover, Select, rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconCheck, IconFilter } from '@tabler/icons-react';
import { TrainingFilters } from '@/containers/Trainings/TrainingsContainer';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import { useAccountStore } from '@/stores/accountStore';
import { getUserFullName } from '@/models/User';

interface TrainingsFilterPopoverProps {
  filterTrainings: TrainingFilters;
  setFilterTrainings: React.Dispatch<React.SetStateAction<TrainingFilters>>;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
}

const TrainingsFilterPopover = ({
  filterTrainings,
  setFilterTrainings,
  setActivePage,
}: TrainingsFilterPopoverProps) => {
  const { organisation } = useAccountStore();
  const [localFilter, setLocalFilter] = useState({ ...filterTrainings });
  const [opened, setOpened] = useState(false);

  const { data: coaches } = useCoaches(organisation?.id as string);

  const applyFilters = () => {
    setFilterTrainings(localFilter);
    setActivePage(1);
    setOpened(false);
  };
  const clearFilters = () => {
    setFilterTrainings({ date: '', coachId: '' });
    setLocalFilter({ date: '', coachId: '' });
    setActivePage(1);
    setOpened(false);
  };

  const calendarIcon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

  const selectCoachLabel = coaches?.results.map((coach) => ({
    value: coach.id,
    label: getUserFullName(coach.user),
  }));

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((prev) => !prev)}
          size="sm"
          variant="outline"
          color="gray"
          bg="white"
          style={{ borderColor: '#CED4DA' }}
        >
          <IconFilter style={{ width: rem(18), height: rem(18) }} />

          {filterTrainings.coachId || filterTrainings.date ? (
            <IconCheck color="lightgreen" style={{ width: rem(20), height: rem(20) }} />
          ) : null}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Select
          label="Trener"
          placeholder="Trener"
          comboboxProps={{ withinPortal: false }}
          data={selectCoachLabel || []}
          value={localFilter.coachId}
          onChange={(value) => setLocalFilter({ ...localFilter, coachId: value as string })}
          clearable
        />
        <DatePickerInput
          maxDate={new Date()}
          leftSection={calendarIcon}
          leftSectionPointerEvents="none"
          label="Datum"
          placeholder="Datum treninga"
          mt="md"
          popoverProps={{ withinPortal: false }}
          value={localFilter.date ? new Date(localFilter.date) : null}
          onChange={(value) =>
            setLocalFilter({ ...localFilter, date: dayjs(value).format('YYYY-MM-DD') })
          }
        />
        <Flex justify="space-between">
          <Button w="49%" mt={20} px={0} onClick={clearFilters} variant="outline">
            Poništi filtere
          </Button>
          <Button
            w="49%"
            mt={20}
            px={0}
            onClick={applyFilters}
            disabled={!localFilter.coachId && !localFilter.date}
          >
            Primeni filtere
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default TrainingsFilterPopover;
