import { Flex, Title } from '@mantine/core';
import { useAccountStore } from '@/stores/accountStore';

export default function Greeting() {
  const { user } = useAccountStore();
  return (
    <div>
      <Flex align="center" gap={10}>
        <Title size={30}>👋🏼</Title>
        <Title size={24} fw={500} ff="heading">
          Dobrodosao nazad {user?.first_name}
        </Title>
      </Flex>
    </div>
  );
}
