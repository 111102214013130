import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOrganisationLocationRequest } from '@/api/requests/organisations/deleteOrganisationLocation.request';

export const useDeleteOrganisationLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteOrganisationLocationRequest,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['organisation'],
      }),
    onError: (error) => {
      console.error('There is error while deleting location', error);
    },
    retry: 1,
  });
};
