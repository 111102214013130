import { Anchor, Badge, Table, Text, ThemeIcon } from '@mantine/core';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';
import { Attendance } from '@/models/Training';

export default function MemberTrainingsAttendanceTableItem({
  attendance,
}: {
  attendance: Attendance;
}) {
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();

  const isToday = (date: Date) => dayjs(date).isSame(dayjs(), 'day');
  const canNavigate = isGranted(Permission.TRAINING_DETAILS_VIEW);

  return (
    <Table.Tr key={attendance.id}>
      <Table.Td>
        <Anchor
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            cursor: canNavigate ? 'pointer' : 'default',
          }}
          c="gray.9"
          fw={500}
          size="md"
          underline={canNavigate ? 'hover' : 'never'}
          ff="heading"
          onClick={() => canNavigate && navigate(`/trainings/${attendance.training_session.id}`)}
        >
          {dayjs(attendance.training_session.date).format('dddd - DD/MM/YYYY - HH:mm')}

          {isToday(attendance.training_session.date) ? (
            <Badge variant="light" color="indigo" size="sm">
              Danas
            </Badge>
          ) : null}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <PresenceStatus isPresent={attendance.is_present} />
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {attendance.training_session.group?.name}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {attendance.training_session.coaches.map((el) => el.full_name).join(', ')}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {attendance.training_session.location?.name || '-'}
        </Text>
      </Table.Td>

      <Table.Td />
    </Table.Tr>
  );
}

export const PresenceStatus = ({ isPresent }: { isPresent: boolean }) =>
  isPresent ? (
    <ThemeIcon ml={10} radius="xl" size="sm" color="green">
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  ) : (
    <ThemeIcon ml={10} radius="xl" size="sm" color="red">
      <IconX style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  );
