import { Center } from '@mantine/core';
import LoginForm from '@/components/Login/LoginForm';
import styles from './login.module.css';

export function LoginPage() {
  return (
    <Center h="100vh" className={styles.container}>
      <LoginForm />
    </Center>
  );
}
