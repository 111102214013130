import { Button, Flex, Popover, Select, rem } from '@mantine/core';
import { IconCheck, IconFilter } from '@tabler/icons-react';
import React, { SetStateAction, useState } from 'react';

import { useAccountStore } from '@/stores/accountStore';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import { getUserFullName } from '@/models/User';

interface GroupsPopoverDataFilterProps {
  coachFilter: string;
  setCoachFilter: React.Dispatch<SetStateAction<string>>;
}

export default function GroupsPopoverDataFilter({
  coachFilter,
  setCoachFilter,
}: GroupsPopoverDataFilterProps) {
  const { organisation } = useAccountStore();
  const { data: coaches } = useCoaches(organisation?.id as string);
  const [localFilter, setLocalFilter] = useState(coachFilter);
  const [opened, setOpened] = useState(false);

  const applyFilters = () => {
    setCoachFilter(localFilter);
    setOpened(false);
  };
  const clearFilters = () => {
    setCoachFilter('');
    setLocalFilter('');
    setOpened(false);
  };

  const selectCoachLabel = coaches?.results.map((coach) => ({
    value: coach.id,
    label: getUserFullName(coach.user),
  }));

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((prev) => !prev)}
          size="sm"
          variant="outline"
          color="gray"
          bg="white"
          style={{ borderColor: '#CED4DA' }}
        >
          <IconFilter style={{ width: rem(18), height: rem(18) }} />

          {coachFilter ? (
            <IconCheck color="lightgreen" style={{ width: rem(20), height: rem(20) }} />
          ) : null}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Select
          label="Trener"
          placeholder="Trener"
          comboboxProps={{ withinPortal: false }}
          data={selectCoachLabel || []}
          value={localFilter}
          onChange={(value) => setLocalFilter(value as string)}
          clearable
        />
        <Flex justify="space-between">
          <Button w="49%" mt={20} px={0} onClick={clearFilters} variant="outline">
            Poništi filtere
          </Button>
          <Button w="49%" mt={20} px={0} onClick={applyFilters} disabled={!localFilter}>
            Primeni filtere
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
}
