import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addTrainingAttendance } from '@/api/requests/trainings/addTrainingAttendance.request';

export const useAddTrainingAttendance = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addTrainingAttendance,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['trainings'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
