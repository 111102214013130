import API from '@/api/base';
import { Group } from '@/models/Group';

export const fetchGroupDetails = async (groupId: string): Promise<Group> => {
  const url = `/groups/${groupId}/`;

  const { data } = await API.get<Group>(url);

  return data;
};
