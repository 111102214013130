import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Stack, TextInput, Title } from '@mantine/core';
import { useUpdateOrganisation } from '@/api/hooks/organisations/useUpdateOrganisation';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';

interface OrganisationConfigurationInputs {
  min_trainings_required_for_membership_payment: string;
}

export default function OrganisationConfigurationForm() {
  const { organisation } = useAccountStore();
  const { mutateAsync: updateOrganisation, isPending: loading } = useUpdateOrganisation();

  const configuration = organisation && organisation.configuration;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<OrganisationConfigurationInputs>();

  const handleUpdateOrganisationConfiguration: SubmitHandler<
    OrganisationConfigurationInputs
  > = async (data) => {
    try {
      await updateOrganisation({
        organisationId: organisation?.id as string,
        configuration: {
          min_trainings_required_for_membership_payment: Number(
            data.min_trainings_required_for_membership_payment
          ),
        },
      });

      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili podatke',
        message: 'Čestitamo, uspešno ste izmenili postavke naloga',
      });

      reset(data);
    } catch (error) {
      console.error('Something went wrong with organisation configuration update..', error);
    }
  };
  return (
    <Stack
      mt={40}
      p="xl"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
      }}
      gap="md"
      component="form"
      onSubmit={handleSubmit(handleUpdateOrganisationConfiguration)}
    >
      <Title size={20}>Postavke naloga</Title>
      <TextInput
        type="number"
        label="Minimum Broj Treninga za Plaćanje Članarine"
        placeholder="Unesite minimalni broj treninga"
        w={{ base: '100%', xs: '50%', sm: '35%' }}
        description="Unesite minimalni broj treninga koji članovi moraju prisustvovati svakog meseca kako bi bili obavezni da plate članarinu.
        Ovo polje vam omogućava da podesite koliko treninga član mora prisustvovati kako bi mu bila naplaćena članarina."
        error={errors.min_trainings_required_for_membership_payment?.message}
        {...register('min_trainings_required_for_membership_payment', {
          value: configuration?.min_trainings_required_for_membership_payment.toString(),
          required: 'Ovo polje je obavezno',
          min: {
            value: 1,
            message: 'Minimalni broj treninga mora biti najmanje 1',
          },
        })}
      />

      <Button
        w={{ base: '100%', xs: '40%', sm: '15%' }}
        my={10}
        type="submit"
        name="userSettings"
        disabled={!isDirty || loading}
      >
        Sačuvaj izmene
      </Button>
    </Stack>
  );
}
