import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { removeMemberFromGroupRequest } from '@/api/requests/groups/removeMemberFromGroup.request';

export const useRemoveMemberFromGroup = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeMemberFromGroupRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', { organisationId: organisation?.id }],
      });

      queryClient.invalidateQueries({
        queryKey: ['members'],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
