import { ActionIcon, Anchor, Badge, Menu, NumberFormatter, rem, Table, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import IsGranted from '../Common/IsGranted';
import { Membership } from '@/models/Membership';
import { Permission } from '@/models/Permission';
import DeletePaidMembership from '@/containers/Memberships/DeletePaidMembership';
import useShowModal from '../Modals/showModal';

export default function PaidMembershipsTableItem({
  membership,
  currency,
}: {
  membership: Membership;
  currency: string;
}) {
  const navigate = useNavigate();
  const showModal = useShowModal();

  const deleteMembership = () =>
    showModal({
      id: 'delete-membership-payment',
      title: 'Izbrisi clanarinu',
      children: <DeletePaidMembership membership={membership} />,
      size: 'md',
    });

  return (
    <Table.Tr key={membership.id}>
      <Table.Td
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/members/${membership.member.id}`)}
      >
        <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
          {membership.member.full_name}
        </Anchor>
      </Table.Td>

      <Table.Td>
        <Badge size="lg">
          <NumberFormatter
            suffix={` ${currency}`}
            value={membership.amount_paid}
            thousandSeparator
          />
        </Badge>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {dayjs(membership.month).format('YYYY/MMMM')}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {membership.collected_by.full_name}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {dayjs(membership.created_at).format('dddd, MMMM D, YYYY')}
        </Text>
      </Table.Td>

      <IsGranted permissions={Permission.MEMBERSHIP_MODIFY_VIEW}>
        <Table.Td style={{ cursor: 'pointer' }}>
          <Menu position="top" offset={10}>
            <Menu.Target>
              <ActionIcon radius="xl" variant="subtle" color="teal">
                <IconDotsVertical size={20} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown px={10} pr={20} py={10}>
              <Menu.Label>Opcije</Menu.Label>

              <Menu.Divider />

              <Menu.Item
                onClick={deleteMembership}
                color="red"
                leftSection={<IconTrash style={{ width: rem(20), height: rem(20) }} />}
              >
                Izbriši članarinu
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </IsGranted>
    </Table.Tr>
  );
}
