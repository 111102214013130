import { TextInput, TextInputProps } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import React from 'react';

interface SearchTextFieldProps extends TextInputProps {
  placeholder: string;
  value: string | number | readonly string[] | undefined;
  onClear: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export default function SearchTextField({
  placeholder,
  value,
  onChange,
  onClear,
  ...others
}: SearchTextFieldProps) {
  return (
    <TextInput
      w={{ base: '100%', sm: '22%' }}
      mb={20}
      size="sm"
      placeholder={placeholder}
      leftSection={<IconSearch stroke={1} size={20} />}
      value={value}
      onChange={onChange}
      rightSection={
        value ? (
          <IconX
            size={18}
            style={{
              display: 'block',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={onClear}
          />
        ) : null
      }
      {...others}
    />
  );
}
