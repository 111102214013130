import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import HeaderUserButton from './HeaderNavbar/HeaderUserButton';
import SubscriptionBanner from '../Common/SubscriptionBanner';
import IsGranted from '../Common/IsGranted';
import { Permission } from '@/models/Permission';
import PaymentRequiredModal from '../Common/PaymentRequiredModal';
import { useAccountStore } from '@/stores/accountStore';

export function Layout() {
  const { organisation } = useAccountStore();
  const [opened, { toggle }] = useDisclosure();
  const isMobile = useMediaQuery('(max-width: 750px)');

  const headerHeight = isMobile ? 60 : 0;

  return (
    <AppShell
      transitionDuration={400}
      transitionTimingFunction="ease"
      layout="alt"
      header={{ height: headerHeight }}
      navbar={{ width: 280, breakpoint: 'sm', collapsed: { mobile: !opened } }}
    >
      <AppShell.Header>
        <Group h="100%" px="md" justify="space-between" hiddenFrom="sm">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" />

          <HeaderUserButton />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar withBorder={false} px="md">
        <Group>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" pt={30} />
        </Group>

        <Sidebar toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main>
        <IsGranted permissions={Permission.SUBSCRIPTION_BANNER_INFO_VIEW}>
          <SubscriptionBanner />
        </IsGranted>

        {organisation && !organisation.license.is_paid ? (
          <IsGranted permissions={Permission.BLOCK_ACCESS_VIEW}>
            <PaymentRequiredModal />
          </IsGranted>
        ) : null}

        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
