import dayjs from 'dayjs';
import { Anchor, Table, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { displayMemberGender, displayMemberGroups, Member } from '@/models/Member';
import useShowModal from '../Modals/showModal';
import DeleteMember from '@/containers/Members/DeleteMember';
import TableActionsMenu from '../Common/Table/TableActionMenu';

export const ParentAccount = ({ hasParentAccount }: { hasParentAccount: boolean }) =>
  hasParentAccount ? (
    <ThemeIcon ml={10} radius="xl" size="sm" color="green">
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  ) : (
    <ThemeIcon ml={10} radius="xl" size="sm" color="red">
      <IconX style={{ width: '70%', height: '70%' }} />
    </ThemeIcon>
  );

export default function MembersTableItem({ member }: { member: Member }) {
  const showModal = useShowModal();
  const navigate = useNavigate();

  const openDeleteModal = (memberId: string) => {
    showModal({
      id: 'delete-member',
      title: 'Izbriši polaznika',
      children: <DeleteMember memberId={memberId} />,
      size: 'md',
    });
  };

  return (
    <Table.Tr key={member.id}>
      <Table.Td style={{ cursor: 'pointer' }} onClick={() => navigate(`/members/${member.id}`)}>
        <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
          {member.full_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {dayjs(member.birth_date).format('YYYY')}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {displayMemberGender(member.gender)}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {member.groups.length ? displayMemberGroups(member.groups) : '-'}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          <ParentAccount hasParentAccount={member.parents.length > 0} />
        </Text>
      </Table.Td>
      <Table.Td style={{ cursor: 'pointer' }}>
        <TableActionsMenu optionsHandler={() => openDeleteModal(member.id)} />
      </Table.Td>
    </Table.Tr>
  );
}
