import API from '@/api/base';
import { Training } from '@/models/Training';

export const getGroupOngoingTraining = async (groupId: string): Promise<Training> => {
  const url = `/groups/${groupId}/ongoing-training/`;

  const { data } = await API.get<Training>(url);

  return data;
};
