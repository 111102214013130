import { Button, Container, Group, Title, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Illustration } from './Illustration';
import classes from './ResourceNotFound.module.css';

export default function ResourceNotFound() {
  const navigate = useNavigate();
  return (
    <div>
      <Container className={classes.root}>
        <div className={classes.inner}>
          <Illustration className={classes.image} />
          <div className={classes.content}>
            <Title className={classes.title}>Došlo je do greške</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
              Resurs koji pokušavate da nađete ne postoji
            </Text>
            <Group justify="center">
              <Button onClick={() => navigate('/', { replace: true })} size="md">
                Vrati me nazad na početnu stranu
              </Button>
            </Group>
          </div>
        </div>
      </Container>
    </div>
  );
}
