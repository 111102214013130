import { Table, Text } from '@mantine/core';

const NoDataTable = ({ text = 'Nema podataka...' }: { text?: string }) => (
  <Table.Tr style={{ height: '10vh', pointerEvents: 'none' }}>
    <Table.Td style={{ textAlign: 'center' }} colSpan={5}>
      <Text fz="md" ff="heading">
        {text}
      </Text>
    </Table.Td>
  </Table.Tr>
);

export default NoDataTable;
