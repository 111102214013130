import { useQuery } from '@tanstack/react-query';
import { fetchUnpaidMembershipsRequest } from '@/api/requests/memberships/getUnpaidMemberships.request';
import { QueryOptionsParams } from '@/api/queryClient';
import { Member } from '@/models/Member';
import { Constants } from '@/constants';

interface UseUnpaidMembershipsQueryParams extends PaginationQueryParams {
  organisationId: string;
  year: string;
  month: string;
  search?: string;
  options?: QueryOptionsParams<PaginatedResponse<Partial<Member>>>;
}

export const useUnpaidMemberships = ({
  organisationId,
  year,
  month,
  search,
  page,
  pageSize = Constants.PAGE_SIZE,
  options,
}: UseUnpaidMembershipsQueryParams) =>
  useQuery({
    queryKey: ['unpaidMemberships', { organisationId, year, month, search, page, pageSize }],
    queryFn: () =>
      fetchUnpaidMembershipsRequest({
        organisationId,
        year,
        month,
        search,
        page,
        pageSize,
      }),

    ...options,
  });
