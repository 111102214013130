import API from '@/api/base';
import { Group } from '@/models/Group';

interface AddCoachesToGroupRequestBody {
  groupId: string;
  coacheIds: string[];
}

export const addCoachesToGroupRequest = async ({
  groupId,
  coacheIds,
}: AddCoachesToGroupRequestBody): Promise<Group> => {
  const requestBody = { coach_ids: coacheIds };

  const { data } = await API.post<Group>(`/groups/${groupId}/add-coaches/`, requestBody);

  return data;
};
