import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { markMembershipPaymentRequest } from '@/api/requests/memberships/markMembershipPayment.request';
import { useAccountStore } from '@/stores/accountStore';
import notifications from '@/utils/notifications';

export const useMarkMembershipPayment = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markMembershipPaymentRequest,
    onSuccess: async () => {
      // All paid memberships
      queryClient.invalidateQueries({
        queryKey: ['memberships', { organisationId: organisation?.id }],
      });

      //Member Memberships
      queryClient.invalidateQueries({
        queryKey: ['memberships', 'member'],
      });

      // Unpaid memberships
      queryClient.invalidateQueries({
        queryKey: ['unpaidMemberships', { organisationId: organisation?.id }],
      });
    },

    // Case where server returns 409 for paid membership for selected member and month
    onError: (error) => {
      if ((error as AxiosError)?.response?.status === 409) {
        notifications.showErrorNotification({
          title: 'Članarina nije uspešno evidentirana',
          message: 'Polaznik je već platio mesec koji ste odabrali',
        });
      }

      if ((error as AxiosError)?.response?.status === 400) {
        notifications.showErrorNotification({
          title: 'Članarina nije uspešno evidentirana',
          message: 'Član nema pravo na plaćanje za odabrani mesec zbog nedovoljnog broja treninga.',
        });
      }
    },
  });
};
