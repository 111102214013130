import { notifications as mantineNotifications } from '@mantine/notifications';
import { IconCheck, IconX, IconAlertCircle } from '@tabler/icons-react';
import { rem } from '@mantine/core';
import { ReactNode } from 'react';

const AUTO_CLOSE_DEFAULT_VALUE = 3500; // 3 seconds

type NotificationOptions = {
  id?: string;
  title: string;
  message?: string;
  autoClose?: number;
  icon?: ReactNode;
};

const showSuccessNotification = ({
  id,
  title,
  message,
  icon,
  autoClose = AUTO_CLOSE_DEFAULT_VALUE,
}: NotificationOptions) => {
  mantineNotifications.show({
    id,
    color: 'teal',
    title,
    message,
    icon: icon || <IconCheck style={{ width: rem(20), height: rem(20) }} />,
    autoClose,
  });
};

const showErrorNotification = ({
  id,
  title,
  message,
  icon,
  autoClose = AUTO_CLOSE_DEFAULT_VALUE,
}: NotificationOptions) => {
  mantineNotifications.show({
    id,
    color: 'red',
    title,
    message,
    icon: icon || <IconX style={{ width: rem(20), height: rem(20) }} />,
    autoClose,
  });
};

const showWarningNotification = ({
  id,
  title,
  message,
  icon,
  autoClose = AUTO_CLOSE_DEFAULT_VALUE,
}: NotificationOptions) => {
  mantineNotifications.show({
    id,
    color: 'yellow',
    title,
    message,
    icon: icon || <IconAlertCircle style={{ width: rem(20), height: rem(20) }} />,
    autoClose,
  });
};

const notifications = { showSuccessNotification, showErrorNotification, showWarningNotification };

export default notifications;
