import { modals } from '@mantine/modals';
import React from 'react';
import { ModalProps, Stack, Text, Title } from '@mantine/core';
import useIsMobile from '@/hooks/useIsMobile';

interface CustomModalProps extends Omit<ModalProps, 'opened' | 'onClose'> {
  id: string;
  title: string;
  description?: string;
  children: React.ReactNode;
}

const useShowModal = () => {
  const isMobile = useIsMobile();

  const showModal = ({
    id,
    title,
    description,
    size = 'xl',
    children,
    ...rest
  }: CustomModalProps) => {
    modals.openConfirmModal({
      id,
      centered: true,
      title: (
        <Stack py="sm" px="md" gap={10}>
          <Title size={18}>{title}</Title>
          {description && <Text c="dimmed">{description}</Text>}
        </Stack>
      ),
      closeButtonProps: { style: { alignSelf: 'flex-start' } },
      size,
      fullScreen: isMobile,
      overlayProps: {
        backgroundOpacity: 0.35,
        blur: 1,
      },
      transitionProps: { transition: 'fade-down' },
      radius: 'md',
      children,
      cancelProps: { display: 'none' },
      confirmProps: { display: 'none' },
      ...rest,
    });
  };

  return showModal;
};

export default useShowModal;
