import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { isEqual } from 'lodash';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Attendance, Training } from '@/models/Training';
import notifications from '@/utils/notifications';
import { useAddTrainingAttendance } from '@/api/hooks/trainings/useTrainingAttendance';
import { useEditTraining } from '@/api/hooks/trainings/useEditTraining';
import { useGroupDetails } from '@/api/hooks/groups/useGroupDetails';
import { useAccountStore } from '@/stores/accountStore';

type Inputs = {
  topic: string;
  groupId: string;
  locationId: string;
  members: string[];
};

const EditTraining = ({ training }: { training: Training }) => {
  const { organisation } = useAccountStore();
  const { data: trainedGroup } = useGroupDetails(training.group.id);
  const { mutateAsync: addTrainingAttendeces } = useAddTrainingAttendance();
  const { mutateAsync: editTraining } = useEditTraining();

  const [selectedMembers, setSelectedMembers] = useState([...training.attendances]);
  const [selectedCoaches, setSelectedCoaches] = useState(training.coaches.map((coach) => coach.id));

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async ({ locationId }) => {
    try {
      const equalArrays = isEqual(
        selectedCoaches,
        training.coaches.map((el) => el.id)
      );
      if (!equalArrays || locationId !== training.location.id) {
        await editTraining({
          coachIds: selectedCoaches as string[],
          trainingId: training.id,
          locationId,
        });
      }

      await addTrainingAttendeces({
        trainingId: training.id,
        members: selectedMembers.map((el) => ({
          member_id: el.member.id,
          is_present: el.is_present,
        })),
      });

      modals.closeAll();
      notifications.showSuccessNotification({
        title: 'Uspešno ste izmenili podatke o treningu',
        message: 'Trening je uspešno izmenjen',
      });
    } catch (err) {
      console.error('Error while creating new group', err);
    }
  };

  const handleMemberSelect = (event: React.ChangeEvent<HTMLInputElement>, member: Attendance) => {
    const updatedMembers = selectedMembers.map((selectedMember) => {
      if (selectedMember.id === member.id) {
        return { ...selectedMember, is_present: event.currentTarget.checked };
      }
      return selectedMember;
    });
    setSelectedMembers(updatedMembers);
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay visible={false} zIndex={200} overlayProps={{ radius: 'sm', blur: 2 }} />
      <Divider />
      <TextInput
        label="Tema treninga"
        placeholder="Tema treninga"
        error={errors.topic?.message}
        type="text"
        w={{ base: '100%', sm: '45%' }}
        disabled
        {...register('topic', { value: training.topic })}
      />
      <Title style={{ fontSize: 18, color: 'gray' }}>
        Odaberi grupu i članove koji će biti deo treninga:
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Flex
            w="100%"
            justify="space-between"
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 20, sm: 10 }}
            wrap="wrap"
          >
            <Stack w={{ base: '100%', sm: '49%' }}>
              <Controller
                name="groupId"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Grupa"
                    placeholder="Izaberite grupu"
                    data={[
                      {
                        value: training.group.id,
                        label: training.group.name,
                      },
                    ]}
                    value={field.value}
                    defaultValue={training.group.id}
                    onBlur={field.onBlur}
                    required
                    disabled
                    allowDeselect={false}
                  />
                )}
              />
            </Stack>

            <Stack w={{ base: '100%', sm: '49%' }}>
              <Controller
                name="locationId"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Lokacija"
                    placeholder="Odaberite lokaciju"
                    data={organisation?.locations?.map((location) => ({
                      value: location.id,
                      label: location.name,
                    }))}
                    value={field.value}
                    onChange={field.onChange}
                    defaultValue={training.location.id}
                    onBlur={field.onBlur}
                    required
                    allowDeselect={false}
                  />
                )}
              />
              {/* <TextInput
                label="Lokacija"
                placeholder="Lokacija treninga"
                disabled
                {...register('location', { value: training.location })}
              /> */}
            </Stack>
            <Stack w={{ base: '100%', sm: '49%' }}>
              {trainedGroup && (
                <MultiSelect
                  searchable
                  label="Treneri"
                  placeholder={training.coaches.length > 0 ? '' : 'Pretraži trenere'}
                  data={trainedGroup.coaches.map((coach) => ({
                    value: coach.id,
                    label: coach.full_name,
                  }))}
                  value={selectedCoaches}
                  onChange={(value) => setSelectedCoaches(value)}
                  nothingFoundMessage="Nema trenera za vašu pretragu..."
                  hidePickedOptions
                />
              )}
            </Stack>
          </Flex>
          <Paper mt="xl">
            <Stack>
              <Title style={{ fontSize: 18, color: 'gray' }}>
                Izaberite članove koji počinju trening
              </Title>
              <SimpleGrid cols={{ base: 1, sm: 2 }} my="sm">
                {selectedMembers.map((attendanceMember) => (
                  <Switch
                    key={attendanceMember.id}
                    label={attendanceMember.member.full_name}
                    color="teal"
                    checked={attendanceMember.is_present}
                    onChange={(event) => handleMemberSelect(event, attendanceMember)}
                  />
                ))}
              </SimpleGrid>
            </Stack>
          </Paper>
        </Paper>

        <Flex gap={10} justify="flex-end" mt={15}>
          <Button variant="default" onClick={() => modals.closeAll()}>
            Odustani
          </Button>
          <Button type="submit" leftSection={<IconEdit size={18} />}>
            Izmeni
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export default EditTraining;
