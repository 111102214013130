import { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import { getUserFullName } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';
import { useCreateGroup } from '@/api/hooks/groups/useCreateGroup';
import notifications from '@/utils/notifications';
import { useAddCoachesToGroup } from '@/api/hooks/groups/useAddCoachesToGroup';
import { useAddMembersToGroup } from '@/api/hooks/groups/useAddMembersToGroup';
import { useAutoFetchMembers } from '@/api/hooks/members/useAutoFetchMembers';

type Inputs = {
  name: string;
};

const CreateGroup = () => {
  const { organisation } = useAccountStore();

  const { data: coaches, isLoading: isCoachesLoading } = useCoaches(organisation?.id as string);
  const { data: members, isLoading: isMembersLoading } = useAutoFetchMembers(
    organisation?.id as string
  );

  const [selectedCoaches, setSelectecCoaches] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const { mutateAsync: createGroup, isPending: loadingNewGroup } = useCreateGroup();
  const { mutateAsync: addCoachesToGroup, isPending: loadingCoaches } = useAddCoachesToGroup();
  const { mutateAsync: addMembersToGroup, isPending: loadingMembers } = useAddMembersToGroup();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const createdGroup = await createGroup({
        name: data.name,
      });

      if (createdGroup) {
        if (selectedCoaches.length > 0) {
          await addCoachesToGroup({ groupId: createdGroup.id, coacheIds: selectedCoaches });
        }
        if (selectedMembers.length > 0) {
          await addMembersToGroup({ groupId: createdGroup.id, memberIds: selectedMembers });
        }
        modals.closeAll();
        notifications.showSuccessNotification({
          title: 'Uspesno',
          message: 'Uspesno ste dodali novu grupu',
        });
      }
    } catch (err) {
      console.error('Error while creating new group', err);
    }
  };

  return (
    <Stack px="md" style={{ position: 'relative' }}>
      <LoadingOverlay
        visible={loadingCoaches || loadingMembers || loadingNewGroup}
        zIndex={200}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Divider />
      <TextInput
        label="Ime Grupe"
        placeholder="Ime Grupe"
        error={errors.name?.message}
        type="text"
        w={{ base: '100%', sm: '45%' }}
        {...register('name', { required: 'Ime grupe je obavezno' })}
      />
      <Title style={{ fontSize: 18, color: 'gray' }}>
        Odaberite trenere i članove za vašu grupu:
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Flex
            w="100%"
            justify="space-between"
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 20, sm: 0 }}
          >
            <Stack w={{ base: '100%', sm: '49%' }}>
              <MultiSelect
                searchable
                label="Treneri"
                placeholder={selectedCoaches.length > 0 ? '' : 'Pretraži trenere'}
                data={coaches?.results?.map((coach) => ({
                  value: coach.id,
                  label: getUserFullName(coach.user),
                }))}
                value={selectedCoaches}
                onChange={(value) => setSelectecCoaches(value)}
                nothingFoundMessage="Nema trenera za vasu pretragu..."
                disabled={isCoachesLoading}
                hidePickedOptions
              />
            </Stack>
          </Flex>
          <Stack w={{ base: '100%', sm: '49%' }} mt={20}>
            <MultiSelect
              searchable
              label="Clanovi"
              placeholder={selectedMembers.length > 0 ? '' : 'Pretraži članove'}
              data={members?.map((member) => ({
                value: member.id,
                label: member.full_name,
              }))}
              value={selectedMembers}
              onChange={(value) => setSelectedMembers(value)}
              nothingFoundMessage="Nema clanova za vasu pretragu..."
              disabled={isMembersLoading}
              hidePickedOptions
            />
          </Stack>
        </Paper>

        <Flex gap={10} justify="flex-end" mt={15}>
          <Button variant="default" onClick={() => modals.closeAll()}>
            Odustani
          </Button>
          <Button type="submit" leftSection={<IconPlus size={18} />}>
            Dodaj
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export default CreateGroup;
