import { Button, Flex, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import notifications from '@/utils/notifications';
import { useDeletePaidMembership } from '@/api/hooks/members/useDeletePaidMembership';
import { Membership } from '@/models/Membership';

const DeletePaidMembership = ({ membership }: { membership: Membership }) => {
  const { mutateAsync: deletePaidMembership } = useDeletePaidMembership();
  const location = useLocation();
  const navigate = useNavigate();

  const deletingFromProfile = location.pathname.includes(membership.id);

  const handleDeleteMember = async () => {
    try {
      const response = await deletePaidMembership(membership.id);

      if (response.status === 204) {
        notifications.showSuccessNotification({
          title: 'Uspešno',
          message: 'Uspešno ste izbrisali članarinu',
        });
        modals.closeAll();

        if (deletingFromProfile) navigate('/members', { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack px="md">
      <Text>Da li ste sigurni da želite da izbrišete članarinu ?</Text>
      <Flex gap={10} justify="flex-end" mt={15}>
        <Button variant="default" onClick={() => modals.closeAll()}>
          Odustani
        </Button>
        <Button leftSection={<IconTrash size={18} />} color="red" onClick={handleDeleteMember}>
          Izbriši
        </Button>
      </Flex>
    </Stack>
  );
};

export default DeletePaidMembership;
