import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Flex, Popover, Select, rem } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';
import { IconCalendar, IconCheck, IconFilter } from '@tabler/icons-react';
import { MembersFilter } from '@/containers/Members/MembersContainer';

const GENDER_OPTIONS = [
  { value: 'MALE', label: 'Musko' },
  { value: 'FEMALE', label: 'Zensko' },
];

interface MembersFilterPopoverProps {
  filterMembers: MembersFilter;
  setFilterMembers: React.Dispatch<React.SetStateAction<MembersFilter>>;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
}

const MembersFilterPopover = ({
  filterMembers,
  setFilterMembers,
  setActivePage,
}: MembersFilterPopoverProps) => {
  const [localFilter, setLocalFilter] = useState({ ...filterMembers });
  const [opened, setOpened] = useState(false);

  const applyFilters = () => {
    setFilterMembers(localFilter);
    setActivePage(1);
    setOpened(false);
  };
  const clearFilters = () => {
    setFilterMembers({ gender: '', year: '' });
    setLocalFilter({ gender: '', year: '' });
    setActivePage(1);
    setOpened(false);
  };

  const calendarIcon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((prev) => !prev)}
          size="sm"
          variant="outline"
          color="gray"
          bg="white"
          style={{ borderColor: '#CED4DA' }}
        >
          <IconFilter style={{ width: rem(18), height: rem(18) }} />

          {filterMembers.gender || filterMembers.year ? (
            <IconCheck color="lightgreen" style={{ width: rem(20), height: rem(20) }} />
          ) : null}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Select
          label="Pol"
          placeholder="Pol"
          comboboxProps={{ withinPortal: false }}
          data={GENDER_OPTIONS}
          value={localFilter.gender}
          onChange={(value) => setLocalFilter({ ...localFilter, gender: value as string })}
          clearable
        />
        <YearPickerInput
          maxDate={new Date()}
          leftSection={calendarIcon}
          leftSectionPointerEvents="none"
          label="Godište"
          placeholder="Godina rođenja polaznika"
          mt="md"
          popoverProps={{ withinPortal: false }}
          value={localFilter.year ? new Date(localFilter.year) : null}
          onChange={(value) =>
            setLocalFilter({ ...localFilter, year: dayjs(value).format('YYYY') })
          }
        />
        <Flex justify="space-between">
          <Button w="49%" mt={20} px={0} onClick={clearFilters} variant="outline">
            Poništi filtere
          </Button>
          <Button
            w="49%"
            mt={20}
            px={0}
            onClick={applyFilters}
            disabled={!localFilter.gender && !localFilter.year}
          >
            Primeni filtere
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default MembersFilterPopover;
