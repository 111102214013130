import { SimpleGrid, Title } from '@mantine/core';
import TrainingsMoMAnalytics from '@/components/Dashboard/OwnerView/TrainingsMoMAnalytics';
import { useTrainingsMoMStats } from '@/api/hooks/trainings/useTrainingsMoMStats';
import MonthlyMembershipsHistoryChart from '@/components/Dashboard/OwnerView/Charts/MonthlyMembershipsHistoryChart';
import { useAccountStore } from '@/stores/accountStore';
import { useMembershipSummaryHistory } from '@/api/hooks/memberships/useMembershipSummaryHistory';
import TrainingsCountAnalytics from '@/components/Dashboard/OwnerView/TrainingsCountAnalytics';
import TrainingsContainer from '@/containers/Trainings/TrainingsContainer';
import DashboardLoading from '@/components/Dashboard/OwnerView/DashboardLoading';

export default function OrganisationOwnerDashboardView() {
  const { organisation } = useAccountStore();
  const { data: membershipsSummary, isLoading: isMembershipsChartLoading } =
    useMembershipSummaryHistory(organisation?.id as string);

  const { data: trainingsMoMStats, isLoading: isTrainingsMoMStatsLoading } = useTrainingsMoMStats();

  if (isMembershipsChartLoading || isTrainingsMoMStatsLoading) return <DashboardLoading />;

  return (
    <div>
      <SimpleGrid
        mt={20}
        cols={{ base: 1, sm: 1, md: 1, lg: 2 }}
        spacing={{ base: 10, sm: 'xl' }}
        verticalSpacing={{ base: 'md', sm: 'xl' }}
      >
        {!isTrainingsMoMStatsLoading && trainingsMoMStats && (
          <TrainingsMoMAnalytics trainingsMoMStats={trainingsMoMStats} />
        )}

        <TrainingsCountAnalytics />
      </SimpleGrid>

      {!isMembershipsChartLoading && membershipsSummary && (
        <MonthlyMembershipsHistoryChart
          data={membershipsSummary}
          currency={organisation?.currency as string}
        />
      )}

      <Title size={18} fw={500} mt={50} ff="heading">
        Istorija treninga
      </Title>

      <TrainingsContainer />
    </div>
  );
}
