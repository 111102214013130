import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getParentChild,
  GetParentChildRequestParams,
} from '@/api/requests/parents/getParentChild.request';
import { Member } from '@/models/Member';

interface UseParentChildDetailsQueryParams extends GetParentChildRequestParams {
  options?: Omit<UseQueryOptions<Member, Error>, 'queryKey' | 'queryFn'>;
}

export const useParentChildDetails = ({
  parent,
  child,
  options,
}: UseParentChildDetailsQueryParams) =>
  useQuery({
    queryKey: ['parentChild', { parent, child }],
    queryFn: () => getParentChild({ parent, child }),
    ...options,
  });
