import dayjs from 'dayjs';
import { Anchor, Badge, Indicator, Table, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Training } from '@/models/Training';
import { isWithin15Minutes } from '@/pages/Trainings/TrainingDetails.page';
import TrainingAttendanceProgressBar from './TrainingAttendanceProgressBar';

export default function TrainingsTableItem({ training }: { training: Training }) {
  const navigate = useNavigate();

  const isToday = (date: Date) => dayjs(date).isSame(dayjs(), 'day');
  return (
    <Table.Tr key={training.id}>
      <Table.Td style={{ cursor: 'pointer' }}>
        <Anchor
          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
          c="gray.9"
          fw={500}
          size="sm"
          underline="hover"
          ff="heading"
          onClick={() => navigate(`/trainings/${training.id}`)}
        >
          {isWithin15Minutes(training.date) ? (
            <Indicator inline processing color="teal" mr={5} />
          ) : null}

          {dayjs(training.date).format('dddd - DD/MM/YYYY - HH:mm')}

          {isToday(training.date) ? (
            <Badge variant="light" color="indigo" size="sm">
              Danas
            </Badge>
          ) : null}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {training.group.name}
        </Text>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {training.coaches.map((el) => el.full_name).join(', ')}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {training.location?.name || '-'}
        </Text>
      </Table.Td>
      <Table.Td style={{ textAlign: 'center' }}>
        <TrainingAttendanceProgressBar trainingAttendances={training.attendances} />
      </Table.Td>
      <Table.Td />
    </Table.Tr>
  );
}
