import { Card, Title, Text, Box, Button, SimpleGrid, Paper, Stack, Center } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Member } from '@/models/Member';
import CreateMemberParent from './CreateMemberParent';
import useShowModal from '@/components/Modals/showModal';
import ParentInfoItem from '@/components/Members/Details/Parents/ParentInfoItem';
import RemoveParentFromMember from './RemoveParentFromMember';

export default function MemberParents({ member }: { member: Member }) {
  const showModal = useShowModal();
  const hasParentsAccounts = member.parents.length > 0;
  type MemberParent = (typeof member.parents)[0]; // Extract the type from member parents response

  function openCreateParentModal() {
    showModal({
      id: 'create-parent-account',
      title: 'Kreiraj korisnički račun za roditelja/staratelja.',
      description: 'Unesite informacije o roditelju/startelju.',
      children: <CreateMemberParent member={member} />,
    });
  }

  function openRemoveParentModal(parent: MemberParent) {
    showModal({
      id: 'remove-parent-account',
      title: 'Kreiraj korisnički račun za roditelja/staratelja.',
      size: 'lg',
      children: (
        <RemoveParentFromMember
          member={member}
          parent={{ id: parent.id as string, children: parent.children }}
        />
      ),
    });
  }

  function formatLastActivityDate(date: Date) {
    if (date) {
      return dayjs(date).format('DD/MM/YYYY - HH:mm');
    }

    return 'Nema aktivnosti';
  }

  return (
    <Card mt={30} px={{ base: '0px', sm: 'md' }} bg={{ base: 'transparent' }}>
      <Title ml={5} fw={600} size={18} c="gray.9">
        Roditeljski/Starateljski korisnički računi
      </Title>

      {!hasParentsAccounts ? (
        <NoParentsAccount openCreateParentModal={openCreateParentModal} />
      ) : (
        <SimpleGrid mt={20} cols={{ base: 1, sm: 2 }}>
          {member.parents.map((parent) => (
            <Paper key={parent.id} fz={15} radius={12} p={20}>
              <Stack key={parent.id} gap={14}>
                <ParentInfoItem label="Ime i prezime" value={parent.full_name} />
                <ParentInfoItem label="Email" value={parent.email} />
                <ParentInfoItem label="Broj telefona" value={parent.phone_number} />
                <ParentInfoItem
                  label="Poslednji put aktivan"
                  value={formatLastActivityDate(parent.last_activity as Date)}
                />
              </Stack>

              <Button
                onClick={() => openRemoveParentModal(parent)}
                variant="light"
                color="red"
                mt={20}
                leftSection={<IconTrash size={20} />}
              >
                Ukloni roditeljski račun
              </Button>
            </Paper>
          ))}

          {member.parents.length === 1 && (
            <Paper fz={15} radius={12} p={20}>
              <Center h="100%">
                <Button
                  leftSection={<IconPlus size={18} color="white" />}
                  onClick={openCreateParentModal}
                >
                  Dodaj još jednog roditelja/staratelja
                </Button>
              </Center>
            </Paper>
          )}
        </SimpleGrid>
      )}
    </Card>
  );
}

function NoParentsAccount({ openCreateParentModal }: { openCreateParentModal: () => void }) {
  return (
    <Box mt={20} ml={5}>
      <Text fw={500} fz="sm" c="dimmed">
        Ovaj polaznik nema kreiranih korisničkih računa za roditelje/staratelje.
      </Text>

      <Button
        leftSection={<IconPlus size={18} color="white" />}
        onClick={openCreateParentModal}
        mt={20}
      >
        Kreiraj korisnički račun za roditelje/staratelje
      </Button>
    </Box>
  );
}
