import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountStore } from '@/stores/accountStore';
import { removeCoachFromGroupRequest } from '@/api/requests/groups/removeCoachFromGroup.request';

export const useRemoveCoachFromGroup = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeCoachFromGroupRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['groups', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Something went wrong...', error);
    },
  });
};
