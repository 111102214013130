import { Container, Text, Title } from '@mantine/core';

export default function SuccessLogin() {
  return (
    <Container
      style={{
        width: 550,
        height: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Title order={1} ta="center" size={60} mb={15} ml={-20}>
        👋
      </Title>
      <Title
        order={1}
        ta="center"
        style={{ fontSize: 24, fontWeight: 500, color: 'white', marginBottom: 10 }}
      >
        Prijavljujem vas...
      </Title>
      <Text ta="center" fz="md">
        Molimo pričekajte dok vas preusmerimo na <br /> kontrolnu tablu..
      </Text>
    </Container>
  );
}
