import API from '@/api/base';
import { Organisation } from '@/models/Organisation';

interface UpdateOrganisationLocationRequestBody {
  organisationId: string;
  name: string;
  id: string;
}

export const updateOrganisationLocation = async ({
  id,
  name,
  organisationId,
}: UpdateOrganisationLocationRequestBody): Promise<Organisation> => {
  const response = await API.patch(`organisations/${organisationId}/locations/${id}/`, {
    name,
  });

  return response.data;
};
