import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Radio,
  Select,
  SimpleGrid,
  Stack,
  TextInput,
  Text,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { modals } from '@mantine/modals';
import { IconPlus } from '@tabler/icons-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useGroups } from '@/api/hooks/groups/useGroups';
import { useAccountStore } from '@/stores/accountStore';
import { useCreateMember } from '@/api/hooks/members/useCreateMember';
import { useAddMembersToGroup } from '@/api/hooks/groups/useAddMembersToGroup';
import notifications from '@/utils/notifications';
import { useCoachByUser } from '@/api/hooks/coaches/useCoachByUser';
import { UserRole } from '@/models/User';
import { useAddTrainingAttendance } from '@/api/hooks/trainings/useTrainingAttendance';
import { getGroupOngoingTraining } from '@/api/requests/groups/getGroupOngoingTraining.request';

type MemberFormInputs = {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: Date;
  phoneNumber: string;
  address: string;
  selectedGroup: string;
  membershipPrice: string;
};

export default function CreateMember() {
  const { organisation, user } = useAccountStore();
  const { data: currentCoach } = useCoachByUser(user?.id as string, {
    enabled: user?.role === UserRole.COACH,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MemberFormInputs>();

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGroups({
    organisationId: organisation?.id as string,
    coachId: currentCoach?.id as string,
  });

  const { mutateAsync: createMember, isPending: isCreatingMember } = useCreateMember();
  const { mutateAsync: addMembersToGroup, isPending: isAddingMembersToGroup } =
    useAddMembersToGroup();
  const { mutateAsync: addTrainingAttendeces, isPending: isAddingTrainingAttendances } =
    useAddTrainingAttendance();

  async function handleAddMemberToOngoingTraining(trainingId: string, memberId: string) {
    try {
      await addTrainingAttendeces({
        trainingId,
        members: [
          {
            member_id: memberId,
            is_present: true,
          },
        ],
      });

      modals.closeAll();
      notifications.showSuccessNotification({
        title: 'Polaznik dodat na trening!',
        message: 'Uspešno ste dodali novog polaznika na aktivni trening.',
      });
    } catch (e) {
      console.error('Error while adding newly created member to ongoing group training', e);
    }
  }

  const onCreateMemberSubmit: SubmitHandler<MemberFormInputs> = async (data) => {
    try {
      const member = await createMember({
        ...data,
        birthDate: dayjs(data.birthDate).format('YYYY-MM-DD'), // Format date
      });

      if (member && data.selectedGroup) {
        await addMembersToGroup({ groupId: data.selectedGroup, memberIds: [member.id] });

        const ongoingTraining = await getGroupOngoingTraining(data.selectedGroup);

        if (ongoingTraining.id) {
          modals.openConfirmModal({
            title: 'Dodaj polaznika na trening',
            centered: true,
            withCloseButton: false,
            closeOnClickOutside: false,
            children: (
              <Text size="sm">
                Izabrana grupa trenutno ima aktivan trening. Da li želite da dodate ovog polaznika
                na tom treningu?
              </Text>
            ),
            labels: { confirm: 'Da, dodaj polaznika', cancel: 'Ne, odustani' },
            confirmProps: { color: 'teal' },
            onConfirm: () => handleAddMemberToOngoingTraining(ongoingTraining.id, member.id),
            onCancel: () => modals.closeAll(),
          });
        } else {
          modals.closeAll();
        }
      }

      notifications.showSuccessNotification({
        title: 'Novi polaznik je uspešno dodat!',
        message: 'Čestitke, uspešno ste dodali novog polaznika',
      });
      modals.closeAll();
    } catch (e) {
      console.error('Error while creating new member', e);
    }
  };

  return (
    <Stack pos="relative">
      <Container w="100%">
        <LoadingOverlay
          visible={isCreatingMember || isAddingMembersToGroup || isAddingTrainingAttendances}
        />
        <Divider />

        <Box component="form" mt={20} onSubmit={handleSubmit(onCreateMemberSubmit)}>
          <SimpleGrid w="100%" cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'lg' }}>
            <TextInput
              required
              label="Ime"
              placeholder="Ime"
              {...register('firstName', { required: '* Ime polaznika je obavezno' })}
              error={errors.firstName?.message}
            />

            <TextInput
              required
              label="Prezime"
              placeholder="Prezime"
              {...register('lastName', { required: '* Prezime polaznika je obavezno' })}
              error={errors.lastName?.message}
            />

            <Controller
              name="birthDate"
              control={control}
              rules={{ required: '* Datum rodjenja polaznika je obavezan' }}
              render={({ field }) => (
                <DatePickerInput
                  required
                  maxDate={new Date()}
                  label="Datum rodjenja"
                  placeholder="Datum rodjenja"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={errors.birthDate?.message}
                />
              )}
            />

            <Controller
              name="gender"
              control={control}
              rules={{ required: '* Pol polaznika je obavezan' }}
              render={({ field }) => (
                <Radio.Group
                  required
                  label="Pol"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={errors.gender?.message}
                >
                  <Group mt="xs">
                    <Radio value="MALE" label="Muško" />
                    <Radio value="FEMALE" label="Žensko" />
                  </Group>
                </Radio.Group>
              )}
            />

            <TextInput
              label="Broj telefona"
              placeholder="+381"
              type="number"
              {...register('phoneNumber')}
            />

            <TextInput
              label="Adresa stanovanja"
              placeholder="Adresa stanovanja"
              {...register('address')}
            />

            <TextInput
              required
              type="number"
              placeholder="3000"
              description="Iznos mesečne članarine koju ovaj polaznik plaća"
              label="Cena članarine"
              leftSection={organisation?.currency}
              leftSectionWidth={52}
              {...register('membershipPrice', { required: '* Cena članarine je obavezna' })}
            />

            <Controller
              name="selectedGroup"
              control={control}
              render={({ field }) => (
                <Select
                  label="Grupa"
                  description="Grupa sa kojom ce ovaj polaznik trenirati"
                  placeholder="Izaberite grupu za ovog polaznika"
                  data={groups?.results.map((group) => ({
                    label: group.name,
                    value: group.id,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  clearable
                  disabled={isGroupsLoading || !!groupsError}
                />
              )}
            />
          </SimpleGrid>

          <Flex gap={10} justify="flex-end" mt={25}>
            <Button variant="default" onClick={() => modals.closeAll()}>
              Odustani
            </Button>
            <Button type="submit" leftSection={<IconPlus size={18} />}>
              Dodaj
            </Button>
          </Flex>
        </Box>
      </Container>
    </Stack>
  );
}
