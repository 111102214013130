import API from '@/api/base';
import { Group } from '@/models/Group';

interface RemoveMemberFromGroupRequestBody {
  groupId: string;
  memberId: string;
}

export const removeMemberFromGroupRequest = async ({
  groupId,
  memberId,
}: RemoveMemberFromGroupRequestBody): Promise<Group> => {
  const requestBody = { member_id: memberId };

  const { data } = await API.post<Group>(`/groups/${groupId}/remove-member/`, requestBody);

  return data;
};
