import {
  Button,
  Container,
  Paper,
  PasswordInput,
  TextInput,
  Text,
  Alert,
  Image,
  Stack,
  Center,
} from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLock, IconMail } from '@tabler/icons-react';
import { useLogin } from '@/api/hooks/auth/useLogin';
import { useMe } from '@/api/hooks/auth/useMe';
import { useAccountStore } from '@/stores/accountStore';
import logo from '@/assets/images/full-logo-dark.png';
import useIsMobile from '@/hooks/useIsMobile';
import { getMyOrganisationRequest } from '@/api/requests/organisations/getMyOrganisation.request';
import SuccessLogin from './SuccessLogin';
import { hasRole, UserRole } from '@/models/User';

type Inputs = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const isMobile = useIsMobile();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<Inputs>();
  const { setUser, setOrganisation } = useAccountStore();
  const { mutateAsync: doLogin, isPending, error } = useLogin();
  const { data: me, isSuccess: isMeSuccess } = useMe(isLoggedIn);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await doLogin({
        email: data.email,
        password: data.password,
      });

      setSuccess(true);
      setIsLoggedIn(true); // Set the state to true after successful login
    } catch (err) {
      console.error('Login failed', err);
    }
  };

  useEffect(() => {
    if (isLoggedIn && isMeSuccess && me && success) {
      !hasRole(me, UserRole.PARENT, true) &&
        getMyOrganisationRequest(me.organisation).then((data) => {
          setOrganisation(data);
        });

      setUser(me);

      setTimeout(() => {
        setSuccess(false);

        navigate('/', { replace: true });
      }, 1000);
    }
  }, [isLoggedIn, isMeSuccess, me, setUser, navigate]);

  const errorResponse = (error as AxiosError)?.response?.status;

  return (
    <>
      {!success ? (
        <Container fluid mt={-100}>
          {errorResponse && (
            <Alert variant="filled" mb={20} color="red" title="Došlo je do greške.." radius="lg">
              Molimo Vas unesite ispravne kredencijale
            </Alert>
          )}

          <Paper w={!isMobile ? 450 : 320} shadow="md" p={30} radius="lg">
            <Center mt={20} ml={-15}>
              <Image src={logo} w={!isMobile ? 300 : 180} alt="logo" />
            </Center>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack w="100%" mt={50} gap={20}>
                <TextInput
                  required
                  placeholder="Email"
                  autoCapitalize="none"
                  type="email"
                  size="lg"
                  radius="md"
                  leftSection={<IconMail size={25} />}
                  {...register('email')}
                />

                <PasswordInput
                  required
                  placeholder="Loznika"
                  size="lg"
                  radius="md"
                  leftSection={<IconLock size={25} />}
                  {...register('password')}
                />
              </Stack>

              <Button
                type="submit"
                fullWidth
                size="lg"
                radius="lg"
                loading={isPending}
                loaderProps={{ type: 'bars' }}
                mt={50}
              >
                Uloguj se
              </Button>

              <Text c="black" size="sm" ta="center" mt={50}>
                Sva prava zadržana © {new Date().getFullYear()}
              </Text>
            </form>
          </Paper>
        </Container>
      ) : (
        <SuccessLogin />
      )}
    </>
  );
}
