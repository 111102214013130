import { Anchor, Table, Text } from '@mantine/core';
import React from 'react';
import TableActionsMenu from '../Common/Table/TableActionMenu';
import useShowModal from '../Modals/showModal';
import { Group } from '@/models/Group';
import DeleteGroup from '@/containers/Groups/DeleteGroup';

export default function GroupsTableItem({
  group,
  onNameClick,
}: {
  group: Group;
  onNameClick: () => void;
}) {
  const showModal = useShowModal();

  const openDeleteModal = (groupId: string) => {
    showModal({
      id: 'delete-group',
      title: 'Izbriši grupu',
      children: <DeleteGroup groupId={groupId} />,
      size: 'md',
    });
  };

  return (
    <Table.Tr key={group.id}>
      <Table.Td style={{ cursor: 'pointer' }} onClick={onNameClick}>
        <Anchor c="gray.9" fw={500} size="md" underline="hover" ff="heading">
          {group.name}
        </Anchor>
      </Table.Td>

      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {group.members.length}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text c="gray.7" fw={400} size="sm">
          {group.coaches.map((el) => el.full_name).join(', ')}
        </Text>
      </Table.Td>
      <Table.Td style={{ cursor: 'pointer' }}>
        <TableActionsMenu optionsHandler={() => openDeleteModal(group.id)} />
      </Table.Td>
    </Table.Tr>
  );
}
