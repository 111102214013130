import { Stack } from '@mantine/core';
import UserUpdatePasswordForm from '@/components/Settings/UserUpdatePasswordForm';
import UserUpdateForm from '@/components/Settings/UserUpdateForm';

export default function UserSettings() {
  return (
    <Stack>
      <UserUpdateForm />
      <UserUpdatePasswordForm />
    </Stack>
  );
}
