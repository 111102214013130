import { Button, Menu, Tooltip, rem } from '@mantine/core';
import { IconBolt, IconEdit, IconTrash } from '@tabler/icons-react';

interface TrainingActionButtonMenuProps {
  editable: boolean;
  onTrainingEdit: () => void;
  onTrainingDelete: () => void;
}

export default function TrainingActionButtonMenu({
  editable,
  onTrainingEdit,
  onTrainingDelete,
}: TrainingActionButtonMenuProps) {
  return (
    <Menu width={200}>
      <Menu.Target>
        <Tooltip
          disabled={editable}
          label="Trening možete izmeniti prvih 15 minuta od njegovog početka"
        >
          <Button
            disabled={!editable}
            mr={50}
            leftSection={<IconBolt style={{ width: rem(14), height: rem(14) }} />}
          >
            Akcije
          </Button>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Opcije</Menu.Label>
        <Menu.Item
          onClick={onTrainingEdit}
          leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
        >
          Izmeni polaznike treninga
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={onTrainingDelete}
          color="red"
          leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
        >
          Izbriši trening
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
