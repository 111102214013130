import { SimpleGrid } from '@mantine/core';
import { useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { CoachCard } from '@/components/Coaches/CoachCard';
import { useAccountStore } from '@/stores/accountStore';
import { useCoaches } from '@/api/hooks/coaches/useCoaches';
import SearchTextField from '@/components/Common/SearchTextField';
import TableLoading from '@/components/Common/Table/TableLoading';

const CoachesContainer = () => {
  const { organisation } = useAccountStore();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const {
    data: coaches,
    isLoading,
    error,
  } = useCoaches(organisation?.id as string, debouncedSearch);

  if (isLoading && search === '') {
    return <TableLoading />;
  }

  if (error) return <div>Something went wrong..</div>;

  return (
    <>
      <SearchTextField
        placeholder="Pretraži trenere po imenu..."
        value={search}
        onClear={() => setSearch('')}
        onChange={(event) => setSearch(event.currentTarget.value)}
      />

      {search.length > 0 && isLoading ? (
        <TableLoading />
      ) : (
        <SimpleGrid
          cols={{ base: 1, sm: 1, lg: 3, md: 2 }}
          spacing={{ base: 10, sm: 'xl' }}
          verticalSpacing={{ base: 'md', sm: 'xl' }}
        >
          {coaches && coaches.results.map((coach) => <CoachCard key={coach.id} coach={coach} />)}
        </SimpleGrid>
      )}
    </>
  );
};

export default CoachesContainer;
