import { useState } from 'react';
import { Box, Card, Flex, Pagination, Text, TextInput, rem } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useDebouncedValue } from '@mantine/hooks';
import { useAccountStore } from '@/stores/accountStore';
import { getMonthFromDate, getYearFromDate } from '@/utils/dateUtils';
import { useUnpaidMemberships } from '@/api/hooks/memberships/useUnpaidMemberships';
import { UnpaidMembershipsTableItem } from '@/components/Memberships/UnpaidMembershipsTableItem';
import UnpaidMembershipsPopoverFilters from '@/components/Memberships/UnpaidMembershipsPopoverFilters';
import { Constants } from '@/constants';
import TableSkeletonLoading from '@/components/Common/Table/TableSkeletonLoading';
import ResourceNotFound from '@/components/Common/ResourceNotFound/ResourceNotFound';
import CustomTable from '@/components/Common/Table/CustomTable';

export interface MembershipFilters {
  date: string;
}

export default function UnpaidMemberships() {
  const { organisation } = useAccountStore();
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const [membershipFilters, setMembershipFilters] = useState<MembershipFilters>({
    date: dayjs().toString(),
  });

  const {
    data: memberships,
    isLoading,
    error,
  } = useUnpaidMemberships({
    organisationId: organisation!.id,
    search: debouncedSearch as string,
    month: getMonthFromDate(membershipFilters.date),
    year: getYearFromDate(membershipFilters.date),
    page: activePage,
    options: {
      enabled: !!organisation,
    },
  });

  const lastPage = Math.ceil(Number(memberships?.count) / Constants.PAGE_SIZE);

  if (error) return <ResourceNotFound />;

  return (
    <Box mt="xl">
      <Flex gap={10} align="center">
        <TextInput
          leftSection={<IconUser style={{ width: rem(16), height: rem(16) }} />}
          placeholder="Pretraži polaznike po imenu.."
          w={350}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />

        <UnpaidMembershipsPopoverFilters
          membershipFilters={membershipFilters}
          setMembershipFilters={setMembershipFilters}
        />

        <Text>
          Period:{' '}
          {membershipFilters.date
            ? dayjs(membershipFilters.date).format('YYYY / MMMM')
            : dayjs().format('YYYY / MMMM')}
        </Text>
      </Flex>

      <Card mt={20} p="lg">
        {isLoading ? (
          <TableSkeletonLoading />
        ) : (
          memberships && (
            <CustomTable
              data={memberships.results}
              columns={['Polaznik']}
              renderRow={(el) => <UnpaidMembershipsTableItem member={el} />}
              noDataText="🥳 Nema neplaćenih članarina za izabrani mesec."
            />
          )
        )}
      </Card>

      {lastPage > 1 ? (
        <Pagination mt={20} mb={20} value={activePage} onChange={setActivePage} total={lastPage} />
      ) : null}
    </Box>
  );
}
