import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAccountStore } from '@/stores/accountStore';
import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';
import notifications from '@/utils/notifications';

export default function ProtectedRoute({
  children,
  permission,
}: {
  children?: JSX.Element;
  permission?: Permission;
}): JSX.Element {
  const { isLoggedIn } = useAccountStore();
  const location = useLocation();
  const { isGranted } = useAuthorization();
  const isGrantedPermission = isGranted(permission as Permission);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isGrantedPermission) {
    notifications.showWarningNotification({
      title: 'Niste ovlašćeni da pristupite ovoj stranici.',
    });

    return <Navigate to="/" replace />;
  }

  return children || <Outlet />;
}
