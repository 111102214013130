export interface Member {
  id: string;
  organisation: string;
  first_name: string;
  last_name: string;
  full_name: string;
  birth_date: Date;
  address?: string;
  phone_number?: string;
  groups: {
    id: string;
    name: string;
  }[];
  join_date: string;
  gender: string;
  has_tracksuit: boolean;
  has_jersey: boolean;
  has_medical_certificate: boolean;
  previous_debt: string;
  membership_price: string;
  parents: MemberParent[];
  created_at: Date;
  modified_at: Date;
}

interface MemberParent {
  id: string;
  email: string;
  full_name: string;
  phone_number: string;
  children: string[];
  last_activity: Date | null;
}

export const displayMemberGender = (gender: string) => (gender === 'MALE' ? 'Muško' : 'Žensko');
export const displayMemberGroups = (
  groups: {
    id: string;
    name: string;
  }[]
) => groups.map((group) => group.name).join(', ');
