import { useAuthorization } from '@/hooks/useAuthorization';
import { Permission } from '@/models/Permission';

interface IsGrantedProps {
  permissions: Permission | Permission[];
  isAny?: boolean;
  children: any;
}

const IsGranted = ({ permissions: permission, isAny = false, children }: IsGrantedProps) => {
  const { isGranted, isGrantedAny } = useAuthorization();
  const permissions = Array.isArray(permission) ? permission : [permission];
  const result = isAny ? isGrantedAny(...permissions) : isGranted(...permissions);
  return result ? children : null;
};

export default IsGranted;
