import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCoachRequest } from '@/api/requests/coaches/createCoach.request';
import { useAccountStore } from '@/stores/accountStore';

export const useCreateCoach = () => {
  const { organisation } = useAccountStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCoachRequest,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['coaches', { organisationId: organisation?.id }],
      });
    },
    onError: (error) => {
      console.error('Error while creating a coach account', error);
    },
  });
};
