import { Coach } from '@/models/Coach';
import { User } from '@/models/User';
import API from '@/api/base';

interface CreateCoachRequestBody {
  user: Partial<User> & { password: string };
  specialisation?: string;
}

export const createCoachRequest = async ({
  user,
  specialisation,
}: CreateCoachRequestBody): Promise<Coach> => {
  const { data } = await API.post<Coach>('/coaches/', {
    user,
    specialisation,
  });

  return data;
};
