import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getParentByUserEmail } from '@/api/requests/parents/getParentByUserEmail.request';
import { Parent } from '@/models/Parent';

export const useParentByUserEmail = (
  email: string,
  options?: Omit<UseQueryOptions<Parent, Error>, 'queryKey' | 'queryFn'>
) =>
  useQuery({
    queryKey: ['parentByUserEmail', { email }],
    queryFn: () => getParentByUserEmail({ email }),
    ...options,
  });
