import { AxiosResponse } from 'axios';
import API from '@/api/base';

interface DeleteGroupRequest {
  groupId: string;
}

export const deleteGroupRequest = async ({
  groupId,
}: DeleteGroupRequest): Promise<AxiosResponse> => {
  const data = await API.delete<AxiosResponse>(`/groups/${groupId}/`);

  return data;
};
