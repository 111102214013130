import { useMemo } from 'react';
import { Permission } from '@/models/Permission';
import { hasAnyRole, hasRole, User, UserRole } from '@/models/User';
import { useAccountStore } from '@/stores/accountStore';

export const useAuthorization = () => {
  const { user } = useAccountStore();

  const permissionMap = useMemo(() => {
    if (!user) return {}; // Guard against null user
    return getPermissionMap(user);
  }, [user]); // Dependency array includes user to refresh when user changes

  const isGranted = (permission: Permission) => {
    if (permissionMap[permission] === undefined) {
      console.error(`Rules for permission "${permission}" are not defined`);
    }

    return permissionMap[permission] ?? false;
  };

  const isGrantedAll = (...permissions: Permission[]) =>
    permissions.every((permission) => isGranted(permission));

  const isGrantedAny = (...permissions: Permission[]) =>
    permissions.some((permission) => isGranted(permission));

  // No need to export both `isGranted` and `isGrantedAll`.
  return { isGranted: isGrantedAll, isGrantedAny };
};

type PermissionMap = {
  [permissionType: string]: boolean;
};

const getPermissionMap = (user: User): PermissionMap => ({
  [Permission.ALLOW_ALL_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.COACH,
    UserRole.PARENT,
    UserRole.STAFF,
  ]),

  [Permission.OWNERS_VIEW]: hasRole(user, UserRole.OWNER),

  [Permission.HOMEPAGE_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.COACH,
    UserRole.PARENT,
    UserRole.STAFF,
  ]),

  [Permission.COACHES_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),
  [Permission.GROUPS_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),
  [Permission.GROUP_MODIFY_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),

  [Permission.MEMBERS_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),
  [Permission.MEMBER_DETAILS_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),

  [Permission.MEMBERSHIPS_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),
  [Permission.MEMBERSHIP_MODIFY_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),

  [Permission.TRAININGS_VIEW]: hasAnyRole(user, [UserRole.OWNER, UserRole.STAFF]),
  [Permission.TRAINING_DETAILS_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.STAFF,
    UserRole.COACH,
  ]),

  [Permission.SETTINGS_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.COACH,
    UserRole.PARENT,
    UserRole.STAFF,
  ]),

  [Permission.USER_SETTINGS_TAB_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.COACH,
    UserRole.PARENT,
    UserRole.STAFF,
  ]),

  [Permission.ORGANISATION_SETTINGS_TAB_VIEW]: hasRole(user, UserRole.OWNER),

  [Permission.SUBSCRIPTION_BANNER_INFO_VIEW]: hasRole(user, UserRole.OWNER),
  [Permission.BLOCK_ACCESS_VIEW]: hasAnyRole(user, [
    UserRole.OWNER,
    UserRole.STAFF,
    UserRole.COACH,
  ]),
});
