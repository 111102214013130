import { useQuery } from '@tanstack/react-query';
import {
  MemberTrainingsAttendanceHistory,
  fetchMemberTrainingsAttendance,
} from '@/api/requests/members/getMemberTrainingsAttendance.request';

export const useMemberTrainingsAttendance = ({
  memberId,
  period,
  page,
  pageSize = 100,
}: MemberTrainingsAttendanceHistory) =>
  useQuery({
    queryKey: ['trainings', 'attendance', { memberId, period, page, pageSize }],
    queryFn: () => fetchMemberTrainingsAttendance({ memberId, period, page, pageSize }),
  });
