import { Box, rem, Select, Tabs } from '@mantine/core';
import { IconChecklist, IconRun, IconUser } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useParentByUserEmail } from '@/api/hooks/parents/useParentByUserEmail';
import { useAccountStore } from '@/stores/accountStore';
import DashboardLoading from '@/components/Dashboard/OwnerView/DashboardLoading';
import ChildBaseInformation from './ChildBaseInformation';
import MemberMemberships from '@/containers/Members/Details/MemberMemberships';
import MemberTrainingsAttendance from '@/containers/Members/Details/MemberTrainingsAttendance';

enum TabsList {
  BASIC_INFO = 'basic_info',
  MEMBERSHIPS = 'memberships',
  TRAININGS = 'trainings',
}

const iconStyle = { width: rem(15), height: rem(15) };

export default function ParentDashboardView() {
  const { user } = useAccountStore();
  const { data: parentAccount, isLoading: isParentAccountLoading } = useParentByUserEmail(
    user?.email as string
  );
  const [activeTab, setActiveTab] = useState(TabsList.BASIC_INFO);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);

  const findChild = (id: string) => parentAccount?.children.find((el) => el.id === id);

  // Update the selected member when parentAccount data is loaded
  useEffect(() => {
    if (parentAccount && parentAccount?.children?.length > 0 && !selectedMember) {
      setSelectedMember(parentAccount.children[0].id as string);
    }
  }, [parentAccount, selectedMember]);

  if (isParentAccountLoading) return <DashboardLoading />;

  return (
    <Box>
      <Select
        w={{ base: '100%', sm: '350px' }}
        my="lg"
        label="Dete"
        data={parentAccount?.children.map((member) => ({
          label: member.full_name,
          value: member.id,
        }))}
        value={selectedMember}
        onChange={setSelectedMember}
        allowDeselect={false}
        disabled={isParentAccountLoading}
      />

      <Tabs
        keepMounted={false}
        value={activeTab}
        onChange={(val) => setActiveTab(val as TabsList)}
        my="xl"
        variant="outline"
      >
        <Tabs.List>
          <Tabs.Tab value={TabsList.BASIC_INFO} leftSection={<IconUser style={iconStyle} />} />

          <Tabs.Tab value={TabsList.MEMBERSHIPS} leftSection={<IconChecklist style={iconStyle} />}>
            Članarine
          </Tabs.Tab>
          <Tabs.Tab value={TabsList.TRAININGS} leftSection={<IconRun style={iconStyle} />}>
            Treninzi
          </Tabs.Tab>
        </Tabs.List>

        {parentAccount && selectedMember ? (
          <>
            <Tabs.Panel value={TabsList.BASIC_INFO}>
              <ChildBaseInformation parent={parentAccount.id} child={findChild(selectedMember)} />
            </Tabs.Panel>

            <Tabs.Panel value={TabsList.MEMBERSHIPS}>
              <MemberMemberships
                memberId={findChild(selectedMember)?.id}
                currency={findChild(selectedMember)?.currency as string}
              />
            </Tabs.Panel>

            <Tabs.Panel value={TabsList.TRAININGS}>
              <MemberTrainingsAttendance
                memberId={findChild(selectedMember)?.id}
                memberJoinDate={findChild(selectedMember)?.join_date as string}
              />
            </Tabs.Panel>
          </>
        ) : null}
      </Tabs>
    </Box>
  );
}
